<template lang="">
  <div>
    <div class="absolute inset-0 bg-[#EBF9FC] opacity-80"></div>

    <!-- Main modal -->
    <div tabindex="-1" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen">
      <div class="relative p-4 w-full max-w-3xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-2 border-b rounded-t">
            <h3 class="text-lg font-medium text-[#F72585] head">Créer un nouveau Compte Fournisseur</h3>
            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" @click="$emit('close')">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <form class="p-4 md:p-5" @submit.prevent="createSupplier">
            <div>
              <label for="supplierType" class="block mb-2 text-base font-medium text-[#7209B7] head">Type de fournisseur</label>
              <select required v-model="supplierType" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5">
                <option disabled value="">Sélectionnez une catégorie</option>
                <option value="Professionnel">Professionnel / Administration publique</option>
                <option value="Particulier">Particulier</option>
                <option value="Compte de Regroupement">Compte de regroupement</option>
              </select>
              <div v-if="supplierType === 'Compte de Regroupement'" class="text-red-500 mt-2">
                <p>Cette option permet de regrouper plusieurs fournisseurs différents au sein d'un seul compte (ex. : Stations services diverses). Cependant, cette pratique n'est pas recommandée : il est préférable que chaque fournisseur dispose de son propre compte comptable pour assurer une meilleure précision et traçabilité des opérations.</p>
              </div>
            </div>
            <br>

            <div v-if="supplierType === 'Professionnel'">
              <label for="siren" class="head block mb-2 text-sm font-medium text-[#7209B7]">SIREN</label>
              <input required v-model="siren" type="text" name="siren" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="SIREN">
            </div>

            <div v-if="supplierType === 'Particulier'">
              <label for="name" class="head block mb-2 text-sm font-medium text-[#7209B7]">Nom Prénom</label>
              <input required v-model="name" type="text" name="name" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Nom Prénom">
              <br>
              <label for="address" class="head block mb-2 text-sm font-medium text-[#7209B7]">Adresse</label>
              <input required v-model="address" type="text" name="address" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Adresse">
              <br>
              <label for="postCode" class="head block mb-2 text-sm font-medium text-[#7209B7]">Code postal</label>
              <input required v-model="postCode" type="text" name="postCode" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Code postal">
              <br>
              <label for="town" class="head block mb-2 text-sm font-medium text-[#7209B7]">Ville</label>
              <input required v-model="town" type="text" name="town" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Ville">
            </div>

            <div v-if="supplierType === 'Compte de Regroupement'">
              <label for="accountName" class="head block mb-2 text-sm font-medium text-[#7209B7]">Nom du compte</label>
              <input required v-model="accountName" type="text" name="accountName" class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Nom du compte (ex. : Stations services diverses)">
            </div>
            <br>
            

            <div class="text-center w-full">
            <button type="submit" class="head text-white inline-flex items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" >
              <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>Ajouter le Fournisseur
            </button>
          </div>

            <div v-if="isError">
              <p class="text-[#ff220c] font-medium mt-4 text-base mb-4">
                {{ message }}
              </p>
            </div>
            <div v-if="isSuccess">
              <p class="text-[#31cb00] font-medium mt-4 text-base mb-4">
                {{ message }}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import { useStore } from "vuex";

const store = useStore();
const supplierType = ref("");
const siren = ref("");
const name = ref("");
const address = ref("");
const postCode = ref("");
const town = ref("");
const accountName = ref("");
const isError = ref(false);
const isSuccess = ref(false);
const message = ref("");

const emit = defineEmits(['refresh', 'close']);

const createSupplier = async () => {
  try {
    var response; 
    var postData = {};
    if(supplierType.value === "Professionnel"){
      postData = {
        evolizCompanyId: store.state.companySelected.evoliz_id,
        companyId: store.state.companySelected.id,
        siren: siren.value,
      }
        response = await axios.post(`${process.env.VUE_APP_BASE_URL}/stakeholder`, postData);
    }
    if(supplierType.value === "Particulier"){
      postData = {
        evolizCompanyId: store.state.companySelected.evoliz_id,
        companyId: store.state.companySelected.id,
        name: name.value,
        address: address.value,
        postCode: postCode.value,
        town: town.value,
      }
      response = await axios.post(`${process.env.VUE_APP_BASE_URL}/stakeholder-individual`, postData);
    }
    if(supplierType.value === "Compte de Regroupement"){
      postData = {
        evolizCompanyId: store.state.companySelected.evoliz_id,
        companyId: store.state.companySelected.id,
        name: accountName.value,
      }
      response = await axios.post(`${process.env.VUE_APP_BASE_URL}/stakeholder-group`, postData);
    }
    
    isSuccess.value = true;
    message.value = response.data.message;
    // Emmettre un évènement pour rafraichir la liste des fournisseurs
    emit('refresh');
    setTimeout(() => {
      isSuccess.value = false;
      message.value = "";
    }, 8000);
  } catch (error) {
    isError.value = true;
    // Utiliser le message d'erreur de l'API
    message.value = error.response?.data?.message || "Erreur lors de l'ajout du fournisseur";
    setTimeout(() => {
      isError.value = false;
      message.value = "";
    }, 8000);
  }
};
</script>


<style scoped>
/* Ajoutez ici vos styles personnalisés */

.head{
  font-family: Righteous;
}
</style>
