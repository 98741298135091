<template>
  <div>
    <h3 style="text-align: center; font-size: 25px"><b>GESTION DES COMPTES MAILS</b></h3>
    <div style="text-align: center">
      <q-btn-group >
        <q-btn color="orange-14" icon="add" label="Ajouter un compte mail" @click="{addMail = true; mailsList = false}"/>
       <!-- <q-btn color="primary" icon="visibility" label="Liste" @click="mailsList = true; addMail = false"/> -->
      </q-btn-group>
    </div>
    <div>
      <AddMail v-if="addMail"/>
      <MailsList v-if="mailsList" />
    </div>
  </div>
</template>

<script>
import AddMail from "../components/AddMail.vue";
import MailsList from "../components/MailsList.vue";
import {ref} from "vue";
export default {
  components: { AddMail, MailsList },
  setup() {
    let addMail = ref(false);
    let mailsList = ref(true);
    return{
        addMail,
        mailsList
    }
  },
};
</script>

<style>
</style>