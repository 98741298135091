<template>
  <div>
    <div>
      <q-btn
        color="orange-1"
        class="text-black"
        label="Ajouter un fournisseur"
        @click="addSupplier=true"
      ></q-btn>
    </div>
    <br>
    <div>
      <q-dialog v-model="addSupplier">
        <q-card>
         <AddSupplierForm />
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import AddSupplierForm from "./AddSupplierForm.vue";
export default {
    components:{
        AddSupplierForm
    },
    setup(){
        let addSupplier = ref(false)
        return{
            addSupplier
        }
    }
};
</script>

<style>
</style>