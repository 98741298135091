<template>
  <div>
    <div class="flex justify-between items-center p-4 bg-[#7869D3] shadow-lg">
      
      <div class="flex items-center">
        <!-- Menu component -->
        <Menu />
      </div>
      
      <div class="text-center">
        <span class="text-xl font-bold text-white">FacturEase</span>
      </div>
      
      <div>
        <select class="w-72 bg-pink-600 text-white p-2 rounded-md">
          <option value="" disabled selected>Accédez aux Autres Applications</option>
          <option value="app1">Application 1</option>
          <option value="app2">Application 2</option>
          <option value="app3">Application 3</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import axios from "axios";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { getCurrentInstance } from "vue";
import Menu from "../components/MenuTabs.vue";
import router from "@/router";

export default {
  components:{Menu},
  setup() {
    const internalInstance = getCurrentInstance();
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const store = useStore();
    let options = ref(null);
    let selected = ref("Selectionner un client");
    let clients = ref(null);
    let notifications = ref([]);
    let clientName = computed(()=>{if(store.state.currentClientInfos){ return store.state.currentClientInfos.name} else return ""});
    let role = ref();
    let right = ref();
    let fixed = ref(false);

      const moveToAdd = () => {
      router.push({ path: "/addCompany/" });
      emitter.emit("moveToOther");
    };

    const handleClientChange = () => {
    if(selected.value == "Ajouter une nouvelle entreprise"){
      moveToAdd()
      return
    }
      let selectedClient = clients.value.filter(
        (client) => client.name == selected.value
      );
      store.commit("setCurrentClient", selectedClient[0].id);
      emitter.emit("clientChanged");
    };
    const getClient = async () => {
      let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/companies`);
      var result = [];
      clients.value = response.data;
      response.data.forEach((element) => {
        result.push(element.name);
      });
      options.value = result;
      role.value = store.state.role;
      right.value = store.state.right;
      //getNotifications();
    };
    const getNotifications = async () => {
      let response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/notifications`
      );
      notifications.value = response.data;
    };
    const logout = ()=>{
      
    }
    onMounted(getClient);
    return {
      options,
      getClient,
      handleClientChange,
      selected,
      clients,
      getNotifications,
      notifications,
      fixed,
      moveToAdd,
      role,
      right,
      clientName,
      logout
    };
  },
};
</script>

<style>

.header{
  display: flex;
  flex-direction: row;
  padding: 1%;
  background-color: #7869D3;
  font-size: 2.5em;
  color: white
}

.header-left{
  display: flex;
  flex-direction: row;
  flex-basis: 10%;
  align-items: center;
}
.header-center{
  display: flex;
  flex-direction: row;
  flex-basis: 80%;
  align-items: center;
  margin: auto;
  justify-content: center;

}

.header-right{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-basis: 10%;
  align-items: center;
}




</style>


