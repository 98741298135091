<template>
    <br>
    <img class="h-auto w-[200px] ml-4" src="../assets/TaaC-ArchiSocial_Large.png"/>
    <div class="w-full h-full p-4 bg-[#EBF9FC] flex flex-col items-center">

        <div class="w-full h-full" v-if="companySelected != ''">
            <!-- Sélection du dossier -->
            <div class="flex flex-row items-center justify-between">
                
                <!-- Boutons d'actions -->
                <div class="flex space-x-4 mt-4">
                    <button @click="openUploadModal"
                        class="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 transition">
                        Télécharger un fichier
                    </button>
                </div>
            </div>
            
            <!-- Modals -->
            <UploadFileModal v-if="showUploadModal" :show="showUploadModal" :folderSelected= null
                @close="closeUploadModal" @fileParsed = "handleFileParsed" />
        </div>
        <div v-else>
            <p class="text-gray-500">Aucune entreprise sélectionnée ou pas de dossier disponible.</p>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import UploadFileModal from '@/components/UploadFileModal.vue';

export default {
    components: {
        UploadFileModal,
    },
    setup() {
        const store = useStore();
        const selectedFolderUrl = ref(null);  // Stocke l'URL du dossier sélectionné
        const showUploadModal = ref(false);
        const parsedData = ref();

        // Récupère l'entreprise sélectionnée depuis le store
        const companySelected = computed(() => store.state.companySelected);


        const openUploadModal = () => {
            showUploadModal.value = true;
        };

        const closeUploadModal = () => {
            showUploadModal.value = false;
        };

        const openCreateFolderModal = () => {
            showCreateFolderModal.value = true;
        };

        const handleFileParsed = (file) => {
            const reader = new FileReader ()
            reader.onload = (event) => {
                const fileContent = event.target.result
                parsedData.value = parseFecFile (fileContent)
            }
            reader.readAsText (file)
        }

        const parseFecFile = (content) => {
            const lines = content.split("\r\n")
            //console.log ("lines", lines)
            const jsonResult = []
            const headers = lines[0].split("\t").map (header => header.trim())
            console.log ("headers", headers)
            console.log("linr 1", lines[1])
            for (var i = 1; i < lines.length; i++) {
                const row = lines [i].split ("\t").map (cell => cell.trim())
                console.log("row " , row)
                console.log("row",row.length)
                console.log('headers', headers.length)
                if (row.length === headers.length) {
                    var rowData = {}
                    headers.forEach ((header, index) => {
                        rowData [header] = row [index]
                    })
                    jsonResult.push (rowData)
                }
            }
            console.log("jsonResult", jsonResult)
            return jsonResult
        }


        return {
            companySelected,
            showUploadModal,
            openUploadModal,
            closeUploadModal,
            handleFileParsed,
        };
    },
};
</script>