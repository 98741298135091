<template>
  <div>
    <h4 style="text-align: center; font-size: 25px">Ajouter un mail</h4>
    <div class="form">
      <q-input color="orange-1-12" v-model="mail" label="Adresse mail">
        <template v-slot:prepend>
          <q-icon name="mail" />
        </template>
      </q-input>
      <q-input color="orange-1-12" v-model="userName" label="Nom d'utilisateur">
        <template v-slot:prepend>
          <q-icon name="person" />
        </template>
      </q-input>
      <q-input color="orange-1-12" v-model="password" label="Mot de passe">
        <template v-slot:prepend>
          <q-icon name="key" />
        </template>
      </q-input>
      <q-input color="orange-1-12" v-model="host" label="Hôte">
        <template v-slot:prepend>
          <q-icon name="gite" />
        </template>
      </q-input>
      <q-input color="orange-1-12" v-model="port" label="Port">
        <template v-slot:prepend>
          <q-icon name="sailing" />
        </template>
      </q-input>
      <br />
      <q-btn color="primary" label="Envoyer" @click="send" />
    </div>
    <div class="banners">
      <q-banner v-if="saved" class="bg-secondary text-white">
        Données enregistrées
      </q-banner>
      <q-banner v-if="errorSaved" class="text-white bg-red">
        Erreur pendant l'enregistrement
      </q-banner>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    let mail = ref();
    let userName = ref();
    let password = ref();
    let host = ref();
    let port = ref();
    let saved = ref(false);
    let errorSaved = ref(false);
    const send = async () => {
      try {
        let json = {
          mail: mail.value,
          userName: userName.value,
          password: password.value,
          host: host.value,
          port: port.value,
          companyId: store.state.currentClient,
        };
        let url = `${process.env.VUE_APP_BASE_URL}/mail`;
        await axios.post(url, json);
        saved.value=true;
        setTimeout(()=>{
          saved.value=false;
        }, 1000)
      } catch (error) {
        console.log(error);
      }
    };
    return {
      mail,
      userName,
      password,
      host,
      port,
      send,
      saved,
      errorSaved,
    };
  },
};
</script>

<style>
</style>