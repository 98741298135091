<template>
    <br>
    <img class="h-auto w-[200px] ml-4" src="../assets/TaaC-ArchiFEC_Large.png"/>
    <div class="w-full h-full p-4 bg-[#ebf9fd] flex flex-col items-center">

        <!-- Vérifie si une entreprise est sélectionnée -->
        <div class="w-full h-full" v-if="companySelected != ''">
            <div
                class="w-[60%] m-auto border-solid border-4 rounded border-[#7209B7] p-2 bg-[#FFFFFF] text-[#3A0CA3] text-[0.9rem] font-sans mb-16">
                <p class="font-bold mb-2"> <q-icon name="info" /> INFORMATIONS</p>
                <p> Bienvenu dans le module dédié au téléchargement de votre Fichier des Écritures
                    Comptables (FEC) ! Ce fichier est une obligation légale : il regroupe toutes les écritures
                    comptables de votre entreprise pour une période donnée, dans un format structuré et standardisé, à
                    fournir en cas de contrôle fiscal ou pour communiquer avec votre expert-comptable.</p>
                    <br>
                    <p><strong class="text-[#F7287B]">Important : </strong>Le journal d’Achats, généré à partir des données de l’application FacturEase, intègre
                    toutes les modifications apportées aux saisies, comme les contrepassations d’écritures et les
                    corrections des documents déjà enregistrés. Cette approche garantit l’intégrité et l’exactitude des
                    données transmises.</p>
                    <br>
                    <div class="text-right">
                    <button @click="infoFec =!infoFec" type="button" class="text-white inline-flex m-auto items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none  font-normal rounded-lg text-sm px-5 py-2.5 text-center head">Voir plus d'informations</button>
                    </div>
                    <br>
                    <div v-if="infoFec">
                    
                <p> Pour chaque type de journal comptable, nous vous proposons différentes options de téléchargement
                    pour faciliter la gestion et le suivi de vos données comptables.</p>
                <br>
                <p class="font-bold text-[#F7287B]"> Journaux d’Achats et de Ventes : Ces journaux sont disponibles en deux versions
                    de téléchargement :</p>

                <ul style="list-style: square; margin-left: 40px">
                    <li>
                        Depuis le Dernier Téléchargement : Téléchargez uniquement les nouvelles écritures comptables
                        saisies depuis votre dernier téléchargement du FEC. Cette option est idéale pour un suivi plus régulier
                        de vos écritures.
                    </li>
                    <li>
                        Depuis le Début de l’Exercice Comptable : Obtenez toutes les écritures enregistrées depuis le
                        début de l’exercice en cours pour une vision complète des transactions.
                    </li>
                </ul>
                <br>

                <p class="font-bold text-[#F7287B]">Journaux de Banque(s), Caisse(s), Opérations Diverses et À-Nouveaux :</p>
                <ul style="list-style: square; margin-left: 40px">
                    <li>
                        Ces journaux sont disponibles uniquement en version complète depuis le début de l’exercice
                        comptable. Cette restriction est due aux spécificités des opérations qu’ils contiennent (comme les rapprochements bancaires et
                        dérapprochements).
                    </li>
                    <li>
                        De ce fait et afin de garantir la sécurité et la fiabilité des fichiers FEC que nous mettons à votre
                        disposition, nous avons choisi de ne pas générer ces journaux dans une version "Depuis le
                        Dernier Téléchargement".
                    </li>
                </ul>
            </div>

            </div>
            <!-- Sélection de l'exercice -->
            <div class="mb-4 w-full max-w-lg text-[#f72585]">
                <label for="exercise-select" class="block font-medium mb-2">Sélectionner un exercice comptable</label>
                <select v-model="selectedExercise" id="exercise-select"
                    class="w-8/12 border border-[#7209B7] rounded-md p-2 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] transition duration-150 ease-in-out">
                    <option value="" disabled selected>Sélectionner un exercice ...</option>
                    <option class="font-sans text-[#3a0ca3]" v-for="exercise in exercises" :key="exercise.id"
                        :value="exercise">
                        {{ exercise.name }}
                    </option>
                </select>
            </div>

            <!-- Choix du type de FEC avec boutons radio -->
            <div class="flex justify-around mb-12 mt-12">
                <label class="inline-flex items-center cursor-pointer">
                    <input type="radio" class="form-radio h-5 w-5 text-[#7209B7]" value="achats" v-model="fecType" />
                    <span class="ml-2 text-lg text-[#7209B7] font-semibold">FEC - Journal d'achat</span>
                </label>
                <label class="inline-flex items-center cursor-pointer">
                    <input type="radio" class="form-radio h-5 w-5 text-[#7209B7]" value="complet" v-model="fecType" />
                    <span class="ml-2 text-lg text-[#7209B7] font-semibold">FEC - Journaux de vente, achat, banque(s),
                        caisse(s)</span>
                </label>
            </div>

            <!-- Bouton pour générer le FEC -->
            <div class="flex justify-center mb-6">
                <button @click="generateFEC" :disabled="!selectedExercise || !fecType"
                    class="text-white inline-flex m-auto items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none  font-normal rounded-lg text-sm px-5 py-2.5 text-center head">
                    Générer le FEC
                </button>
            </div>

            <!-- Affichage du tableau FEC Achats -->
            <div v-if="fecType === 'achats' && fecGenerated" class="w-full font-sans">
                <h3 class="text-lg font-semibold text-[#7209B7] mb-4 text-center">FEC Achats Généré</h3>
                <div class="overflow-x-auto max-h-96 overflow-y-auto shadow-lg rounded-lg border border-gray-300">
                    <table class="min-w-full table-auto bg-white">
                        <thead class="bg-gray-100 sticky top-0">
                            <tr>
                                <th v-for="header in tableHeaders" :key="header"
                                    class="px-4 py-2 border text-left text-sm font-semibold text-[#7209B7] bg-gray-200 sticky top-0">
                                    {{ header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="entry in fecData" :key="entry.EcritureNum" class="hover:bg-gray-100">
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.JournalCode }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.JournalLib }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.EcritureNum }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.EcritureDate }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.CompteNum }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.CompteLib }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.CompteAuxNum }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.CompteAuxLib }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.PieceRef }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.PieceDate }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.EcritureLib }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.Debit }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.Credit }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.EcritureLet }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.DateLet }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.ValidDate }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.MontantDevise }}</td>
                                <td class="px-4 py-2 border text-sm text-gray-700">{{ entry.Idevise }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Bouton pour télécharger le fichier FEC Achats -->
                <div class="mt-4">
                    <button @click="downloadFEC"
                        class="text-white inline-flex m-auto items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none  font-normal rounded-lg text-sm px-5 py-2.5 text-center head">
                        Télécharger le FEC Achats
                    </button>
                </div>
            </div>

            <!-- Affichage des résultats ou messages -->
            <div v-if="resultMessage" class="mt-4 p-4 bg-blue-100 text-blue-700 rounded-lg">
                {{ resultMessage }}
            </div>
        </div>

        <!-- Message si aucune entreprise n'est sélectionnée -->
        <div v-else>
            <p class="text-[#ff220c]">Aucune entreprise sélectionnée.</p>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

export default {
    setup() {
        const store = useStore();
        const selectedExercise = ref(null);
        const exercises = ref([]);
        const fecType = ref('achats'); // Défaut sur Achats
        const fecGenerated = ref(false);  // Indique si le FEC est généré
        const fecData = ref([]);  // Données du FEC généré
        const resultMessage = ref('');
        const infoFec = ref(false);

        // En-têtes du tableau
        const tableHeaders = ref([
            'JournalCode', 'JournalLib', 'EcritureNum', 'EcritureDate', 'CompteNum', 'CompteLib',
            'CompteAuxNum', 'CompteAuxLib', 'PieceRef', 'PieceDate', 'EcritureLib', 'Debit',
            'Credit', 'EcritureLet', 'DateLet', 'ValidDate', 'MontantDevise', 'Idevise'
        ]);

        // Récupère l'entreprise sélectionnée depuis le store
        const companySelected = computed(() => store.state.companySelected);

        // Fetch les exercices comptables de l'entreprise
        const fetchExercises = async () => {
            if (companySelected.value) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/accounting_exercice/${companySelected.value.id}`);
                    exercises.value = response.data.map(exercise => {
                        const startDate = new Date(exercise.exercice_start);
                        const endDate = new Date(exercise.exercice_end);
                        const formatDate = (date) => `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
                        exercise.name = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                        return exercise;
                    });
                } catch (error) {
                    console.error('Erreur lors de la récupération des exercices:', error);
                }
            } else {
                exercises.value = [];
            }
        };

        // Logique pour générer le FEC
        const generateFEC = async () => {
            try {
                if (fecType.value === 'complet') {
                    // Pour le FEC Complet, l'API renvoie directement le fichier à télécharger
                    const url = `${process.env.VUE_APP_BASE_URL}/fecExport/${companySelected.value.id}`;
                    const body = {
                        accounting_year: {
                            exercice_start: selectedExercise.value.exercice_start,
                            exercice_end: selectedExercise.value.exercice_end
                        }
                    };
                    const response = await axios.post(url, body, { responseType: 'blob' });

                    // Télécharger le fichier
                    const blob = new Blob([response.data], { type: 'text/plain' });
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = `export_FEC_complet.txt`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);

                    resultMessage.value = "Le FEC Complet a été téléchargé.";
                } else {
                    // Pour le FEC Achats, affichage des données dans le tableau
                    const url = `${process.env.VUE_APP_BASE_URL}/fecNotDownloaded/${companySelected.value.id}`;
                    const response = await axios.get(url);
                    //Réponse si le Fec est vide
                    if (response.data.length === 0) {
                        resultMessage.value = "Aucune nouvelle facture à télécharger.";
                        return;
                    }
                    // Traitement des données FEC
                    fecData.value = response.data.flatMap(item => {
                        return item.fec.split('\n').map(line => {
                            const [
                                JournalCode, JournalLib, EcritureNum, EcritureDate, CompteNum, CompteLib,
                                CompteAuxNum, CompteAuxLib, PieceRef, PieceDate, EcritureLib, Debit,
                                Credit, EcritureLet, DateLet, ValidDate, MontantDevise, Idevise
                            ] = line.split(';');

                            return {
                                JournalCode, JournalLib, EcritureNum, EcritureDate, CompteNum, CompteLib,
                                CompteAuxNum, CompteAuxLib, PieceRef, PieceDate, EcritureLib, Debit,
                                Credit, EcritureLet, DateLet, ValidDate, MontantDevise, Idevise
                            };
                        });
                    });
                    fecGenerated.value = true;
                    resultMessage.value = "Le FEC Achats a été généré avec succès.";
                }
            } catch (error) {
                console.error("Erreur lors de la génération du FEC:", error);
                resultMessage.value = "Une erreur est survenue lors de la génération du FEC.";
            }
        };

        // Fonction pour télécharger le FEC Achats
        const downloadFEC = () => {
            const fecText = fecData.value.map(entry => Object.values(entry).join(';')).join('\n');
            const blob = new Blob([fecText], { type: 'text/plain' });
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = `export_FEC_achats.txt`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };

        // Surveiller les changements d'entreprise sélectionnée
        watch(companySelected, () => {
            fetchExercises();
        }, { immediate: true });

        return {
            companySelected,
            selectedExercise,
            exercises,
            fecType,
            fecGenerated,
            fecData,
            resultMessage,
            generateFEC,
            downloadFEC,
            tableHeaders,
            infoFec
        };
    },
};
</script>

<style scoped>
.head {
    font-family: Righteous;
}
</style>
