<template>
  <div class="supplierContainer">
    <q-btn-dropdown
      icon="file_upload"
      class="q-mr-sm"
      color="orange-14"
      label="Importer"
    >
      <q-item-section>
        <input
          class="uploadFileButton"
          name="uploadFile"
          style="max-width: 300px; height: 40px; padding: 5px; margin-top: 7px"
          label="Fichier"
          @input="sendFile"
          ref="file"
          type="file"
        />
      </q-item-section>
    </q-btn-dropdown>
    <q-dialog v-model="supplierValidation">
      <q-card>
        <q-card-section>
          <div class="text-h6">Choisissez les fournisseurs à importer</div>
        </q-card-section>
        <q-card-section>
          <q-list>
            <q-item
              v-for="(supplier, index) in suppliers"
              :key="index"
              v-ripple
            >
              <q-item-section>
                <q-checkbox
                  v-model="suppliersSelected[index]"
                  :label="supplier.name"
                />
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup @click="validate"/>
          <q-btn flat label="Valider" color="secondary" v-close-popup @click="validate"/>

        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { useStore } from "vuex";
import { ref } from "vue";
export default {
  setup() {
    const store = useStore();
    let supplierValidation = ref(false);
    let suppliers = ref([]);
    let suppliersSelected = ref([]);
    const sendFile = async (e) => {
      supplierValidation.value = true;

      var file = e.target.files[0];
      try {

        let formData = new FormData();
        let companyId = store.state.currentClient;
        formData.append("file", file);
        formData.append("company_id", companyId);

        let url2 = `${process.env.VUE_APP_BASE_URL}/supplier`;
        await axios.post(url2, formData);

        let url3 = `${process.env.VUE_APP_BASE_URL}/suppliers/${companyId}`;
        let response3 = await axios.get(url3);
        suppliers.value = response3.data;
        suppliers.value.map(() => {
          suppliersSelected.value.push(true);
        });
      } catch (err) {
        console.log(err);
      }
    };

    const validate = async () =>{
      try {
        let arrayDifference = [];
        suppliersSelected.value.map((supplier, index) =>{
          if(!supplier){
            arrayDifference.push(suppliers.value[index]);
          }
        })
        
        let url = `${process.env.VUE_APP_BASE_URL}/suppliers`;
        await axios.delete(url, {data: arrayDifference});
      } catch (error) {
        console.log(error);
      }
    }
    return {
      sendFile,
      supplierValidation,
      suppliers,
      suppliersSelected,
      validate
    };
  },
};
</script>

<style>
.supplierContainer {
  width: 50%;
  margin: auto;
}
</style>