<template>
    <div class="page">
        <div>
            <h3 style="text-align:center; font-size:25px"><b>GESTION DES STOCKS</b></h3>
        </div>
        <div class="q-pa-md tab-container">
            <div>
                <q-card class="q-mb-md" style="width: 400px;">
                    <q-card-section>
                        <h2 class="text-h6">Données d'entrée</h2>
                        <div class="q-gutter-md">
                            <div class='date-selector'>
                                <q-input filled v-model="startDate" mask="date" :rules="['date']" label="Date de début">
                                    <template v-slot:append>
                                        <q-icon name="event" class="cursor-pointer">
                                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                                <q-date v-model="startDate">
                                                    <div class="row items-center justify-end">
                                                        <q-btn v-close-popup label="Close" color="primary" flat />
                                                    </div>
                                                </q-date>
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                                <q-input filled v-model="endDate" mask="date" :rules="['date']" label="Date de fin">
                                    <template v-slot:append>
                                        <q-icon name="event" class="cursor-pointer">
                                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                                <q-date v-model="endDate">
                                                    <div class="row items-center justify-end">
                                                        <q-btn v-close-popup label="Close" color="primary" flat />
                                                    </div>
                                                </q-date>
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <q-input v-model="ventes" label="Ventes" type="number" />
                            <q-input v-model="achats" label="Achats" type="number" />
                            <q-input v-model="margeInf" label="Marge Inf" type="number" />
                            <q-input v-model="margeSup" label="Marge Sup" type="number" />
                            <q-input v-model="stockInitial" label="Stock Initial" type="number" />
                            <q-input v-model="stockFinal" label="Stock Final" type="number" />
                            <q-input v-model="resultatAvantStockFinal"
                                label="Résultat avant prise en compte du Stock Final" type="number" />
                        </div>
                        <br>
                        <div>
                            <q-btn label="Valider" color='secondary' @click="getStocks" />
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="tab">
                <table class="table zui-table zui-table-rounded">
                    <thead>
                        <tr>
                            <th>Compte comptable</th>
                            <th>Désignation</th>
                            <th>Stock initial</th>
                            <th></th>
                            <th></th>
                            <th>Achats</th>
                            <th></th>
                            <th></th>
                            <th>Stock final</th>
                            <th></th>
                            <th></th>
                            <th>Commentaires</th>

                        </tr>
                        <tr>
                            <th style="border-radius: 0px;"></th>
                            <th></th>
                            <th>Q</th>
                            <th>PU</th>
                            <th>T</th>
                            <th>Q</th>
                            <th>PU</th>
                            <th>T</th>
                            <th>Q</th>
                            <th>PU</th>
                            <th>T</th>
                            <th style="border-radius: 0px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(row, index) in stocks" :key="index">
                            <tr>
                                <td class="account-cell">{{ row['Compte comptable'] }}</td>
                                <td>{{ row['Stock Name'] }}</td>
                                <td>{{ row['Quantité initiale'] }}</td>
                                <td>{{ row['Prix unitaire initial'] }}</td>
                                <td>{{ row['Total initial'] }}</td>
                                <td>{{ row['Quantité'] }}</td>
                                <td>{{ row['Prix unitaire'] }}</td>
                                <td>{{ row['Total'] }}</td>
                                <td><q-input label="Quantité finale" v-model="finalQuantity[index]" dense /></td>
                                <td>{{ formulaOne(row, index) }}</td>
                                <td class="total-cell">{{ formulaTwo(formulaOne(row), finalQuantity[index], index,
                                    row['Compte comptable'])}}</td>
                            </tr>
                            <tr v-if="shouldShowTotal(index, row['Compte comptable'])" style="height: 3em;">
                                <td colspan="2">Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="max-width: 200px;">{{ totalsByAccount.find(item => item.account ===
                                    row['Compte comptable']) ? totalsByAccount.find(item => item.account === row['Compte comptable']).total : 0 }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

        </div>
        <div>
            <p><b>Valorisation du stock final : </b>{{ finalQuantitiesTotal }}</p>
            <p><b>Borne Inf Stock Final : </b>{{ formulaThreeComputed }}</p>
            <p><b>Borne Sup Stock Final : </b>{{ formulaFourComputed }}</p>
            <p><b>Résultat Avant IS : </b>{{ formulaFiveComputed }}</p>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from "vuex";
import axios from "axios";

// Définition de la fonction computed en dehors de l'objet export default
const formulaOne = (row) => {
    // Calcul basé sur les valeurs de la ligne
    return ((row.Total + row['Total initial']) / (row['Quantité initiale'] + row['Quantité'])).toFixed(2);
};

const formulaTwo = (formulaOne, finalQ) => {
    return (formulaOne * finalQ).toFixed(2)
}

export default {
    name: 'GestionStocks',
    setup() {
        const store = useStore();
        const startDate = ref(null);
        const endDate = ref(null);
        const ventes = ref(null);
        const achats = ref(null);
        const margeInf = ref(null);
        const margeSup = ref(null);
        const stockInitial = ref(null);
        const stockFinal = ref(null);
        const resultatAvantStockFinal = ref(null);
        const companySelected = computed(() => store.state.companySelected);
        const stocks = ref([]);
        const finalQuantity = ref([]);
        const formulaOneResults = ref([]);
        const formulaTwoResults = ref([]);
        const accountCellsRef = ref([]);
        const totalCellsRef = ref([]);
        const totalsByAccount = ref([]);
        const finalQuantitiesTotal = computed(() => { return calculateTotalSum() })


        const formulaOneComputed = computed(() => {
            return (row, index) => {
                const result = formulaOne(row);
                formulaOneResults.value[index] = result;
                return result;
            };
        });

        const formulaTwoComputed = computed(() => {
            return (formulaOne, finalQ, index, account) => {
                const result = formulaTwo(formulaOne, finalQ);
                formulaTwoResults.value[index] = result;

                return result;
            };
        });

        //FML 03 = A + SI - V * (1 - m)
        const formulaThreeComputed = computed(() => {
            const achatsValue = parseFloat(achats.value);
            const stockFinalValue = parseFloat(stockFinal.value);
            const ventesValue = parseFloat(ventes.value);
            const margeInfValue = parseFloat(margeInf.value);
            return (achatsValue + stockFinalValue) - (ventesValue * (1 - margeInfValue));
        });

        const formulaFourComputed = computed(() => {
            const achatsValue = parseFloat(achats.value);
            const stockFinalValue = parseFloat(stockFinal.value);
            const ventesValue = parseFloat(ventes.value);
            const margeSupValue = parseFloat(margeSup.value);
            return (achatsValue + stockFinalValue) - (ventesValue * (1 - margeSupValue));
        });

        const formulaFiveComputed = computed(() => {
            const resultatAvantStockFinalValue = parseFloat(resultatAvantStockFinal.value);
            const stockFinalValue = parseFloat(stockFinal.value);
            return resultatAvantStockFinalValue - stockFinalValue;
        });



        // Fonction pour calculer les totaux par compte comptable
        const calculateTotalsByAccount = () => {
            const totalsByAccountObj = {};

            // Parcourir les lignes du tableau
            accountCellsRef.value.forEach((accountCell, index) => {
                const account = accountCell.textContent;
                const totalCell = totalCellsRef.value[index];
                const total = parseFloat(totalCell.textContent);
                if (!totalsByAccountObj.hasOwnProperty(account)) {
                    totalsByAccountObj[account] = 0;
                }
                if (!isNaN(total)) {
                    totalsByAccountObj[account] += total;

                } else {
                    totalsByAccountObj[account] += 0;

                }
            });
            // Convertir l'objet en tableau d'objets
            const totalsArray = Object.keys(totalsByAccountObj).map(account => ({ account, total: totalsByAccountObj[account] }));
            return totalsArray;
        };

        // Appeler la fonction de calcul lors de la mise à jour des stocks
        const recalculateTotals = () => {
            setTimeout(() => {
                totalsByAccount.value = calculateTotalsByAccount();
            }, 300);
        };

        // Fonction pour faire la somme de tous les éléments d'un tableau
        const calculateTotalSum = () => {
            let sum = 0;
            // Parcourir tous les éléments dans finalQuantity
            finalQuantity.value.forEach(quantity => {
                sum += Number(quantity);
            });
            return sum;
        };


        // Utiliser un watcher pour recalculer les totaux lorsque les stocks changent
        watch(formulaTwoResults.value, recalculateTotals);



        const getStocks = async () => {
            try {
                // Convertir la chaîne de caractères en objet Date
                const startDateValue = new Date(startDate.value);
                const endDateValue = new Date(endDate.value);
                // Vérifier si la conversion a réussi
                if (!isNaN(startDateValue.getTime()) && !isNaN(endDateValue.getTime())) {
                    // Formater les dates au format "YYYY-MM-DD"
                    const formattedStartDate = formatDate(startDateValue);
                    const formattedEndDate = formatDate(endDateValue);
                    const formattedFinalDate = formatDate(new Date(companySelected.value.accounting_year_end_date));

                    // Construire l'URL avec les dates formatées
                    let url = `${process.env.VUE_APP_BASE_URL}/stocks/${companySelected.value.id}/${formattedStartDate}/${formattedEndDate}/${formattedFinalDate}`;
                    // Faire la requête API
                    let response = await axios.get(url);

                    // Mettre à jour les stocks avec les données de la réponse
                    stocks.value = response.data;
                    setTimeout(() => {
                        accountCellsRef.value = document.querySelectorAll('.account-cell');
                        totalCellsRef.value = document.querySelectorAll('.total-cell');
                        recalculateTotals();
                    }, 1000)



                } else {
                    console.error('Une des dates n\'est pas une date valide.');
                }
            } catch (error) {
                console.log(error);
            }
        }

        const shouldShowTotal = (index) => {
            return (
                index + 1 < stocks.value.length &&
                stocks.value[index]['Compte comptable'] !==
                stocks.value[index + 1]['Compte comptable']
            );
        };



        // Fonction pour formater une date au format "YYYY-MM-DD"
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }

        return {
            startDate,
            endDate,
            ventes,
            achats,
            margeInf,
            margeSup,
            stockInitial,
            resultatAvantStockFinal,
            getStocks,
            companySelected,
            stocks,
            shouldShowTotal,
            finalQuantity,
            formulaOne: formulaOneComputed,
            formulaTwo: formulaTwoComputed,
            formulaOneResults,
            formulaTwoResults,
            calculateTotalsByAccount,
            totalsByAccount,
            finalQuantitiesTotal,
            stockFinal,
            formulaThreeComputed,
            formulaFourComputed,
            formulaFiveComputed
        };
    }
};
</script>

<style scoped>
table.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);

}

.table th {
    background-color: #fff;
    /* Couleur de fond pour les en-têtes */
    color: #304FFD;
    /* Couleur du texte pour les en-têtes */
    width: 200px;
}

.table tr:nth-child(even) {
    background-color: #E3F2FD;
    /* Couleur de fond pour les lignes impaires */
}

.table tr:hover {
    background-color: #BBDEFB;
    /* Couleur de fond pour les lignes au survol */
}

.tab-container {
    display: flex;
    gap: 10px;
}

.tab {
    flex-basis: 70%;
    overflow: scroll;
    max-height: 70vh;
}

table.table thead tr th:first-child {
    border-radius: 5px 0px 0px 0px;
}

table.table thead tr th:last-child {
    border-radius: 0px 5px 0px 0px;
}

td {
    text-align: right;
}
</style>