<template>
    <div>
        <br>
        <img class="h-auto w-[200px] ml-4" src="../assets/TaaC-FacturEase_Large.png" />
        <FilterAndSort @invoiceSelected='handleSelect' @isTemp="changeIsTemp" />
        <div class="container2">
            <div class="preview" v-if="!loading">
                <div class="sticky-wrapper">
                    <embed v-if="pdfUrl" :src="pdfUrl" width="90%" height="700px">
                    <br />
                </div>
            </div>
            <div v-else>
                <q-spinner style="color: #2d00f7; margin: auto" size="3em" :thickness="2" />
            </div>

            <div class="form" v-if="(invoiceDatas.document_type && !loading) || invoiceDatas.document_type == ''">
                <div class="flex flex-row">
                    <div class="basis-1/3">
                        <button
                            class="text-white inline-flex m-auto items-center bg-[#ff220c] hover:bg-[#444054] focus:ring-4 focus:outline-none focus:ring-black font-normal rounded-lg text-sm px-5 py-2.5 text-center head"
                            @click="confirmDelete()">
                            Supprimer le document
                        </button>
                    </div>
                    <div class="basis-2/3 p-2 font-semibold">
                        <p>
                            Notre application utilise des outils d'OCR et d'IA. Plus la qualité des factures envoyées à
                            l'interface est élevée, meilleurs seront les résultats obtenus.
                        </p>
                    </div>
                </div>


                <!--************ Affichage des données de la facture **********-->
                <div class="invoicesDatasForm ">
                    <h3 class="mt-8 mb-4 text-lg font-medium text-[#F7287B] head">Éléments généraux :</h3>
                    <form class="form-container">
                        <div class="form-row">
                            <label>Type de document</label>
                            <select v-model="invoiceDatas.document_type" class="form-input">
                                <option value="facture">Facture</option>
                                <option value="avoir">Avoir</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Date du document</label>
                            <input v-model="invoiceDatas.invoice_date" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Date d'échéance</label>
                            <input v-model="invoiceDatas.deadline" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Numéro du document</label>
                            <input v-model="invoiceDatas.invoice_number" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Dénomination sociale</label>
                            <input v-model="stakeholderInfos.name" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Nom commercial
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'Le nom commercial d\'une entreprise est le nom sous lequel elle exerce son activité et se présente au public. C\'est le nom visible pour les clients, utilisé pour la communication, la publicité et la marque. En revanche, la dénomination sociale est le nom officiel de l\'entreprise, enregistré lors de sa création. La dénomination sociale identifie l\'entreprise juridiquement, tandis que le nom commercial est sa « vitrine » auprès du public. Une entreprise peut avoir un nom commercial différent de sa dénomination sociale.' }" />
                            </label>
                            <input v-model="invoiceDatas.supplier_name" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Compte du fournisseur
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation d\'un compte comptable à chaque fournisseur est gérée automatiquement par l\'application. Nous utilisons une nomenclature de 10 chiffres, commençant par \'401\', suivi d\'un nombre entre 01 et 26, correspondant à la première lettre de la dénomination sociale du fournisseur, puis d\'une incrémentation séquentielle sur les 5 derniers chiffres.' }" />
                            </label>
                            <div class="supplier-account-wrapper">
                                <select v-model="invoiceDatas.stakeholder_account"
                                    class="form-input supplier-account-select focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block"
                                    @change="updateStakeholderAccount">
                                    <option v-for="stakeholder in companyStakeholder" :key="stakeholder.id"
                                        :value="stakeholder.account">
                                        {{ stakeholder.account }} - {{ stakeholder.description }}
                                    </option>
                                </select>

                                <button
                                    class="head text-white inline-flex items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    @click.prevent="openNewSupplierDialog = !openNewSupplierDialog">
                                    <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clip-rule="evenodd"></path>
                                    </svg>Créer un nouveau Compte Fournisseur
                                </button>
                            </div>
                            <AddSupplierForm v-if="openNewSupplierDialog" @close="openNewSupplierDialog = false" @refresh="fetchCompanyStakeholder" />
                        </div>

                        <div class="form-row">
                            <label>Montant total TTC</label>
                            <input v-model.number="invoiceDatas.total_ttc" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Enregistrement
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte fournisseur est automatiquement géré par l\'application : une facture génère un crédit, tandis qu\'un avoir génère un débit.' }" />
                            </label>
                            <select v-model="registrationValue['total_ttc']" class="form-input">
                                <option value="débit">Débit</option>
                                <option value="crédit">Crédit</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Option TVA sur les débits
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'Les prestations de services, sont, par défaut, soumises à la TVA sur les encaissements : pour le fournisseur, la TVA devient exigible au moment du paiement par le client ; réciproquement, pour le client, la TVA ne devient déductible que lors du règlement de la facture. Cependant, en optant pour la TVA sur les débits, une entreprise peut déclarer et payer la TVA dès l’émission de la facture, même si le paiement n’a pas encore été reçu. Dans ce cas, l\'entreprise cliente, celle qui reçoit la prestation de services, peut déduire la TVA dès l\'émission de la facture, même si le paiement n\'a pas encore été effectué.' }" />
                            </label>
                            <select v-model="invoiceDatas.tva_debit_option" class="form-input">
                                <option value="true">Vrai</option>
                                <option value="false">Faux</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Autoliquidation de la TVA
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'L’autoliquidation de la TVA pour les importations et acquisitions intracommunautaires signifie que l’entreprise qui importe ou achète des biens/services depuis l’étranger ou au sein de l’Union européenne est responsable de déclarer et de payer la TVA elle-même, aux lieu et place du fournisseur ou du prestataire étranger. Ce système s\'applique aussi dans certains cas de sous-traitance dans les travaux de construction, où le sous-traitant n’applique pas de TVA sur sa facture. C’est le donneur d’ordre (l\'entreprise principale) qui autoliquide la TVA pour simplifier et centraliser la gestion de la TVA sur l’ensemble du chantier.' }" />
                            </label>
                            <select v-model="invoiceDatas.invoice_mention" class="form-input">
                                <option value="true">Vrai</option>
                                <option value="false">Faux</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Mode de paiement</label>
                            <select v-model="invoicePaymentMethod" class="form-input">
                                <option value="">Sélectionner un moyen de paiement</option>
                                <option v-for="method in paymentMethods" :key="method.label" :value="method.label">
                                    {{ method.label }}
                                </option>
                            </select>
                        </div>
                        <!--<div class="form-row">
                            <label>Type de fournisseur</label>
                            <select v-model="supplierType" class="form-input">
                                <option value="Classique">Classique</option>
                                <option value="MarketPlace">MarketPlace</option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>Nom de la MarketPlace</label>
                            <select v-model="marketPlaceName" class="form-input">
                                <option value="">Sélectionnez une MarketPlace</option>
                                <option v-for="marketplace in marketplaces" :key="marketplace" :value="marketplace">
                                    {{ marketplace }}
                                </option>
                            </select>
                        </div>-->
                        <div class="form-row">
                            <label>Pays
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'Ce champ permet de gérer le régime d\'autoliquidation de la TVA et d\'affecter automatiquement le compte approprié, en fonction de la nature de l\'opération : importation, acquisition intracommunautaire ou sous-traitance dans les travaux de construction en France.' }" />
                            </label>
                            <select v-model="country" class="form-input">
                                <option value="">Sélectionner un pays</option>
                                <option v-for="country in countryList" :key="country.name" :value="country.name">
                                    {{ country.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-row">
                            <label>État</label>
                            <select v-model="invoiceState" class="form-input">
                                <option value="">Sélectionner l'état du document</option>
                                <option value="Réglée">
                                    Réglée
                                </option>
                                <option value="Non Réglée">Non Réglée</option>
                            </select>
                        </div> 
                    </form>
                </div>
                <br>


                <!--************ Affichage de la vérification de la facture **********-->
                <div :class="['verificationResult', isScrolledPast ? 'fixed-rectangle' : 'hidden-rectangle']">
                    <div v-if="verifyEntireInvoice().isBalanced" class="icon success">
                        <q-icon style="color: #31cb00" name="check_circle" />
                        L'écriture comptable est équilibrée
                        <ul>
                            <li>Total débit : {{ verifyEntireInvoice().totalDebit }}</li>
                            <li>Total crédit : {{ verifyEntireInvoice().totalCredit }}</li>
                            <li>Montant TTC du document : {{ verifyEntireInvoice().invoiceTotal }}</li>
                            <li>Écart constaté : {{ verifyEntireInvoice().discrepancy }}</li>
                        </ul>
                    </div>

                    <div v-else class="icon error">
                        <q-icon style="color: #ff220c" name="error" /> Incohérence dans l'écriture comptable
                        <ul>
                            <li>Total débit : {{ verifyEntireInvoice().totalDebit }}</li>
                            <li>Total crédit : {{ verifyEntireInvoice().totalCredit }}</li>
                            <li>Montant TTC du document : {{ verifyEntireInvoice().invoiceTotal }}</li>
                            <li>Écart constaté : {{ verifyEntireInvoice().discrepancy }}</li>
                        </ul>
                    </div>
                </div>
                <!--************ Affichage de la TVA **********-->
                <div v-if="tvaAccounts && companySelected.tva_regime !== 'franchise'">
                    <h3 class="mt-8 mb-4 text-lg font-medium text-[#F7287B] head">Taxe sur la Valeur Ajoutée (TVA) :
                    </h3>
                    <div class="tvaForm">
                        <!-- Section TVA sur immobilisations -->
                        <!-- TVA Immo 20% -->
                        <div v-if="isImmo && findTvaIndex('tva_immo_20') !== -1" class="flex flex-col">
                            <div class="form-row">
                                <label>TVA Immo 20% :</label>
                                <input type="number" v-model="tvaAccounts[findTvaIndex('tva_immo_20')]['tva_immo_20']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[findTvaIndex('tva_immo_20')]['account1']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[0]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[0]['account2']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[0]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        <div v-if="isImmo && findTvaIndex('tva_immo_13') !== -1" class="flex flex-col">
                            <div class="form-row">
                                <label>TVA Immo 13% :</label>
                                <input type="number" v-model="tvaAccounts[findTvaIndex('tva_immo_13')]['tva_immo_13']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[0]['account1']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[0]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[0]['account2']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[0]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <br>
                        <hr>
                        <!-- TVA Immo 13% -->
                        <div v-if="isImmo && findTvaIndex('tva_immo_13') !== -1" class="flex flex-col">
                            <div class="form-row">
                                <label>TVA Immo 13 % :</label>
                                <input v-model="tvaAccounts[findTvaIndex('tva_immo_13')]['tva_immo_13']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[findTvaIndex('tva_immo_13')]['account1']" type="number"
                                        class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[1]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[1]['account2']" type="number"
                                        class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[1]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <br>
                        <!-- TVA Immo 10% -->
                        <div v-if="isImmo && findTvaIndex('tva_immo_10') !== -1" class="flex flex-col">
                            <div class="form-row">
                                <label>TVA Immo 10 % :</label>
                                <input type="number" v-model="tvaAccounts[findTvaIndex('tva_immo_10')]['tva_immo_10']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[findTvaIndex('tva_immo_10')]['account1']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[2]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[2]['account2']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[2]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <br> 
                        <!-- TVA Immo 5.5% -->
                        <div v-if="isImmo && findTvaIndex('tva_immo_5_5') !== -1" class="flex flex-col">
                            <div class="form-row">
                                <label>TVA Immo 5.50 % :</label>
                                <input type="number" v-model="tvaAccounts[findTvaIndex('tva_immo_5_5')]['tva_immo_5_5']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[findTvaIndex('tva_immo_5_5')]['account1']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[3]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[3]['account2']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[3]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <br>
                        <!-- TVA Immo 2.10% -->
                        <div v-if="isImmo && findTvaIndex('tva_immo_2_1') !== -1" class="flex flex-col">
                            <div class="form-row">
                                <label>TVA Immo 2.10 % :</label>
                                <input type="number" v-model="tvaAccounts[findTvaIndex('tva_immo_2_1')]['tva_immo_2_1']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[findTvaIndex('tva_immo_2_1')]['account1']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[4]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[4]['account2']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[4]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <br>
                        <!-- Section TVA 20% -->
                        <div class="flex flex-col">
                            <div class="form-row">
                                <label>TVA ABS 20 % :</label>
                                <input type="number" v-model="tvaAccounts[0]['tva_20']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[0]['account1']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[0]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[0]['account2']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[0]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        <hr>
                        <br>
                        <!-- Section TVA 13% -->
                        <div class="flex flex-col">
                            <div class="form-row">
                                <label>TVA ABS 13 % :</label>
                                <input v-model="tvaAccounts[1]['tva_13']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[1]['account1']" type="number"
                                        class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[1]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[1]['account2']" type="number"
                                        class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[1]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <br>
                        <!-- Section TVA 10% -->
                        <div class="flex flex-col">
                            <div class="form-row">
                                <label>TVA ABS 10 % :</label>
                                <input type="number" v-model="tvaAccounts[2]['tva_10']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[2]['account1']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[2]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[2]['account2']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[2]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <br>
                        <!-- Section TVA 5.5% -->
                        <div class="flex flex-col">
                            <div class="form-row">
                                <label>TVA ABS 5.50 % :</label>
                                <input type="number" v-model="tvaAccounts[3]['tva_5_5']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[3]['account1']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[3]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[3]['account2']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[3]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <br>
                        <!-- Section TVA 2.1% -->
                        <div class="flex flex-col">
                            <div class="form-row">
                                <label>TVA ABS 2.10 % :</label>
                                <input type="number" v-model="tvaAccounts[4]['tva_2_10']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[4]['account1']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[4]['registration1']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[4]['account2']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Enregistrement
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'autoliquidation de TVA est automatiquement géré par l\'application : l\'achat d\'un article génère un crédit, tandis qu\'un article retourné ou remboursé génère un débit.' }" />
                                    </label>
                                    <select v-model="tvaAccounts[4]['registration2']" class="max-w-24 basis-1/3">
                                        <option value="débit">Débit</option>
                                        <option value="crédit">Crédit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <br>
                        <!-- Section TVA 0% -->
                        <div class="flex flex-col">
                            <div class="form-row">
                                <label>TVA ABS 0 % :</label>
                                <input type="number" v-model="tvaAccounts[5]['tva_0']" class="form-input max-w-24" />
                            </div>
                            <div class="form-row">
                                <div class="flex flex-nowrap basis-[45%] items-center">
                                    <label style="font-weight: normal;">Compte associé</label>
                                    <input v-model="tvaAccounts[5]['account1']" class="form-input max-w-24" />
                                </div>
                                <div class="flex flex-nowrap basis-[55%] justify-end items-center">
                                    <label style="flex-basis: 71%; font-weight: normal;">Compte
                                        d'autoliquidation
                                        <q-icon name="help_outline" style="color: #2d00f7"
                                            v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation du compte d\'autoliquidation de la TVA est gérée automatiquement par l\'application en fonction du champ \'Autoliquidation de TVA\' (si sa valeur est définie sur \'Vrai\') et du pays renseigné.' }" />
                                    </label>
                                    <input v-model="tvaAccounts[5]['account2']" class="form-input max-w-24" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>


                <!--************ Affichage des options de convertion **********-->
                <div>
                    <h3 class="mt-8 mb-4 text-lg font-medium text-[#F7287B] head">Options de conversion - HT & TTC :
                    </h3>
                    <span class="font-medium mr-12">Basculer la valeur du champs "Total HT" vers "Total TTC" et
                        recalculer le Total HT</span>
                    <button
                        class="text-white inline-flex m-auto items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none focus:ring-black font-normal rounded-lg text-sm px-5 py-2.5 text-center head"
                        @click="convertHtToTtc">
                        HT vers TTC
                    </button>
                </div>
                <div>
                    <span class="font-medium mr-10">Basculer la valeur du champs "Total TTC" vers "Total HT" et
                        recalculer le Total TTC</span>
                    <button
                        class="text-white inline-flex m-auto items-center bg-[#F7287B] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none focus:ring-black font-normal rounded-lg text-sm px-5 py-2.5 text-center head"
                        @click="convertTtcToHt">
                        TTC vers HT
                    </button>
                </div>
                <br><br>


                <!--************ Affichage des produits **********-->
                <h3 class="mt-8 mb-4 text-lg font-medium text-[#F7287B] head">Article(s) du document :</h3>
                <div class="productsForm">

                    <div v-if="products.length == 0">
                        <span>Aucun article n'a été trouvé sur ce document</span>
                        <div><q-btn flat style="color: #31cb00" icon="add_circle_outline" label="Ajouter un article"
                                @click="addProduct()" /></div>
                    </div>

                    <div class="productsList" v-for="(product, index) in products" :key="index">
                        <div class="flex items-center gap-2">
                            <q-toggle v-model="isOneImmo[index]" class="text-[#7209B7] font-medium mb-2"
                                label="Article à immobiliser"
                                @update:model-value="handleOneImmo(product, isOneImmo[index])" />
                            <q-icon name="help_outline" style="color: #2d00f7"
                                v-tippy="{ theme: 'light', placement: 'top', content: 'En activant cette option, vous pouvez enregistrer l\'article en tant qu\'immobilisation. Vous aurez le choix d\'utiliser un compte d\'immobilisation général dans la liste des comptes ou de créer un sous-compte spécifique dans le champ \'Compte Immobilisation\' (pour ce faire, utilisez le bouton \'Ajouter un nouveau Compte d\'Achat ou d\'Immobilisation\'). L\'application calcule automatiquement la TVA afférente à l\'ensemble des articles immobilisés présents sur la facture et l\'affecte au compte de TVA déductible sur immobilisations. La TVA correspondant aux articles non immobilisés reste affectée au compte de TVA déductible sur autres biens et services.' }" />
                        </div>
                        <div class="form-group">
                            <label>Désignation</label>
                            <input type="text" v-model="product.designation" />
                        </div>
                        <div class="form-group">
                            <label for="unit">Unité de l'article
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'Ce champ permet de spécifier le contenu de chaque article (par exemple, pour l\'achat de 4 couronnes de 50 mètres de cuivre, l\'application indiquera : Quantité = 4, Unité = mètre, Nombre d\'unités par article = 50). Bien que cette saisie soit facultative, elle est conçue pour calculer avec précision le coût unitaire moyen pondéré (CUMP) de chaque article, optimisant ainsi la gestion des stocks d\'approvisionnement et facilitant vos choix de gestion (dans notre exemple, nous connaitrons le CUMP d\'un mètre d\'une couronne de cuivre). A terme, cette saisie fournira des informations détaillées pour une comptabilité analytique permettant de déterminer le coût de revient de vos produits et services, ainsi que d’évaluer la valeur de vos stocks de produits et d\'en-cours de production (fonctionnalité à venir).' }" />
                            </label>
                            <select v-model="product.pack_unity" id="unit" class="form-control">
                                <option v-for="unit in units" :key="unit" :value="unit">
                                    {{ unit }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Nb d'unités par article</label>
                            <input type="number" v-model.number="product.pack_number" />
                        </div>

                        <div class="form-group">
                            <label>Quantité</label>
                            <input type="number" v-model.number="product.quantity" />
                        </div>

                        <div class="form-group" v-if="companySelected.tva_regime !== 'franchise'">
                            <label>Total HT</label>
                            <input type="number" v-model.number="product.total_amount_ht"
                                :placeholder="companySelected.tva_regime === 'franchise' ? 'Non pertinent' : ''" />

                        </div>
                        <div class="form-group">
                            <label>Taux de TVA</label>
                            <input type="number" v-model.number="product.tva_rate" />
                        </div>
                        <div class="form-group">
                            <label>Total TTC</label>
                            <input type="number" v-model.number="product.total_amount_ttc"
                                :placeholder="companySelected.tva_regime === 'franchise' ? '' : 'Non pertinent'" />
                        </div>
                        <div class="form-group">
                            <label>Enregistrement
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'achat est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                            </label>
                            <select v-model="product.registration">
                                <option value="crédit">Crédit</option>
                                <option value="débit">Débit</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Compte
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation d\'un compte d\'achat à chaque article est gérée automatiquement par l\'application, en fonction de votre activité, de celle de votre fournisseur, de vos précédents achats et des comptes d\'achats spécifiques que vous avez créés (d’où l\'importance de fournir une brève description lors de la création d\'un compte). Ce processus s’appuie sur l’intelligence artificielle pour identifier le compte comptable le plus adapté, bien qu\'une précision de 100 % ne puisse être garantie. Enfin, le taux de TVA applicable à l\'article est intégré dans les deux derniers chiffres de chaque compte.' }" />
                            </label>
                            <q-select popup-content-style="font-family: sans-serif" v-model="product.account"
                                :options="filteredAccounts" use-input input-debounce="0" option-label="label"
                                option-value="key" @filter="filterAccounts" label="Choisir un compte d'achat" outlined
                                filled dense emit-value class="custom-select" />
                            <q-btn flat style="color: #2d00f7" icon="playlist_add" @click="duplicateAccount(product)" />
                        </div>

                        <div v-if="isOneImmo[index]">
                            <label>Compte immobilisation</label>
                            <input type="text" v-model="product.account" />
                        </div>
                        <div>
                            <span style="display: block ; width: 100%; text-align: center;">
                                {{
                                    accounts.find(account => account.key && product.account && account.key.substring(0, 8)
                                        ==
                                        product.account.substring(0, 8))?.value
                                }}
                            </span>
                        </div>
                        <div>
                            <br>

                            <button
                                class="text-white inline-flex m-auto items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none  font-normal rounded-lg text-sm px-5 py-2.5 text-center head"
                                @click="toggleNewAccountForm(product)">
                                Ajouter un nouveau Compte d'Achat ou d'Immobilisation
                            </button>
                            <br>
                            <div v-if="isAddingNewAccount">
                                <div class="flex mb-2 mt-4 space-x-2">
                                    <input type="text" v-model="newPurchaseAccount.account"
                                        placeholder="Numéro de compte" />
                                    <input type="text" v-model="newPurchaseAccount.designation"
                                        placeholder="Libellé du compte" />
                                    <input type="text" v-model="newPurchaseAccount.tvaAccount"
                                        placeholder="Compte de TVA associé" />
                                </div>
                                <div class="flex">
                                    <textarea v-model="newPurchaseAccount.description"
                                        class="w-full border border-[#7209b7] rounded-lg"
                                        placeholder="Description du compte en quelques mots">
                                </textarea>
                                </div>
                                <div v-if="showTvaWarning" class="warning-message">
                                    Attention : Aucun compte de TVA actif détecté. Veuillez saisir un compte de
                                    TVA.
                                </div>
                                <div>
                                <div class="flex mb-2 mt-4">
                                    <q-select
                                        v-model="tvaRatesSelected"
                                        :options="tvaRates"
                                        dense
                                        bg-color="white"
                                        multiple
                                        borderless
                                        label="Sélectionner les taux de TVA"
                                        class="custom-select"
                                        style="font-family: sans-serif; margin-right: 0px;"
                                    />
                                </div>
                                </div>

                                <div class="w-full text-center">
                                    <div class="flex items-center justify-center">
                                        <q-btn flat style="color: #f72585; margin: auto" label="Enregistrer le compte"
                                            @click="saveNewPurchaseAccount(product)" />
                                        <q-spinner-facebook v-if="isSaving" class="ml-2" color="#f72585" size="1.5em" />
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 text-base">
                                <p v-if="productMessage"
                                    :class="{ 'success-message': productMessageColor === 'green', 'error-message': productMessageColor === 'red' }">
                                    {{ productMessage }}
                                </p>
                            </div>
                        </div>
                        <br>

                        <!-- Vérification des écritures comptables avec icônes -->
                        <div class="verificationResult">
                            <span v-if="verifyAccountingEntries(product)" class="icon success"><q-icon
                                    style="color: #31cb00" name="check_circle" /></span>
                            <!-- Icône verte pour succès -->
                            <span v-else class="icon error"><q-icon style="color: #ff220c" name="error" /></span>
                            <!-- Icône rouge pour erreur -->
                            <span>{{ verifyAccountingEntries(product) ? 'Écriture équilibrée entre Total HT, Taux de TVA et Total TTC' : `Incohérence dans l'écriture entre les valeurs Total HT, Taux de TVA et Total TTC` }}</span>
                        </div>
                        <hr style="color: #7209B7; height: 2px; background-color: #7209B7; width: 100%;">
                        <br>

                        <div>
                            <q-btn flat style="color: #31cb00" icon="add_circle_outline" label="Ajouter un article"
                                @click="addProduct()" />
                            <q-btn flat style="color: #ff220c" icon="remove_circle_outline" label="Supprimer l'article"
                                @click="removeProduct(product)" />
                        </div>
                    </div>
                </div>
                <br><br>

                <!--************ Affichage des frais annèxes **********-->
                <h3 class="mt-8 mb-4 text-lg font-medium text-[#F7287B] head">Frais accessoires :</h3>
                <div class="productsForm">

                    <div v-if="extraFees.length == 0">
                        <span>Aucun frais accessoire n'a été trouvé sur ce document</span>
                        <div><q-btn flat style="color: #31cb00" icon="add_circle_outline" label="Ajouter un élément"
                                @click="addProduct()" /></div>
                    </div>

                    <div class="productsList" v-for="(product, index) in extraFees" :key="index">
                        <div class="form-group">
                            <label>Désignation</label>
                            <input type="text" v-model="product.designation" />
                        </div>
                        <div class="form-group">
                            <label>Unité de l'article</label>
                            <input type="text" v-model="product.pack_unity" />
                        </div>
                        <div class="form-group">
                            <label>Nb d'unités par article</label>
                            <input type="number" v-model.number="product.pack_number" />
                        </div>

                        <div class="form-group">
                            <label>Quantité</label>
                            <input type="number" v-model.number="product.quantity" />
                        </div>

                        <div class="form-group">
                            <label>Total HT</label>
                            <input type="number" v-model.number="product.total_amount_ht" />
                        </div>
                        <div class="form-group">
                            <label>Taux de TVA</label>
                            <input type="number" v-model.number="product.tva_rate" />
                        </div>
                        <div class="form-group">
                            <label>Total TTC</label>
                            <input type="number" v-model.number="product.total_amount_ttc" />
                        </div>
                        <div class="form-group">
                            <label>Enregistrement
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'L\'enregistrement dans le compte d\'achat est automatiquement géré par l\'application : l\'achat d\'un article génère un débit, tandis qu\'un article retourné ou remboursé génère un crédit.' }" />
                            </label>
                            <select v-model="product.registration">
                                <option value="crédit">Crédit</option>
                                <option value="débit">Débit</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Compte
                                <q-icon name="help_outline" style="color: #2d00f7"
                                    v-tippy="{ theme: 'light', placement: 'top', content: 'L\'affectation d\'un compte d\'achat à chaque article est gérée automatiquement par l\'application, en fonction de votre activité, de celle de votre fournisseur, de vos précédents achats et des comptes d\'achats spécifiques que vous avez créés (d’où l\'importance de fournir une brève description lors de la création d\'un compte). Ce processus s’appuie sur l’intelligence artificielle pour identifier le compte comptable le plus adapté, bien qu\'une précision de 100 % ne puisse être garantie. Enfin, le taux de TVA applicable à l\'article est intégré dans les deux derniers chiffres de chaque compte.' }" />
                            </label>
                            <q-select v-model="product.account" :options="filteredAccounts" use-input input-debounce="0"
                                option-label="label" option-value="key" @filter="filterAccounts"
                                label="Choisir un compte" outlined filled dense emit-value class="custom-select" />
                            <q-btn flat style="color: #2d00f7" icon="playlist_add" @click="duplicateAccount(product)" />

                        </div>
                        <div>
                            <span style="display: block ; width: 100%; text-align: center;">
                                {{
                                    accounts.find(account => account.key && product.account && account.key.substring(0, 8)
                                        ==
                                        product.account.substring(0, 8))?.value
                                }}
                            </span>
                        </div>
                        <br>

                        <!-- Vérification des écritures comptables avec icônes -->
                        <div class="verificationResult">
                            <span v-if="verifyAccountingEntries(product)" class="icon success"><q-icon
                                    style="color: #31cb00" name="check_circle" /></span>
                            <!-- Icône verte pour succès -->
                            <span v-else class="icon error"><q-icon style="color: #ff220c" name="error" /></span>
                            <!-- Icône rouge pour erreur -->
                            <span>{{ verifyAccountingEntries(product) ? 'Écriture équilibrée entre Total HT, Taux de TVA et Total TTC' : `Incohérence dans l'écriture entre les valeurs Total HT, Taux de TVA et Total TTC` }}</span>
                        </div>
                        <hr style="color: #7209B7; height: 2px; background-color: #7209B7; width: 100%;">
                        <br>

                        <div>
                            <q-btn flat style="color: #31cb00" icon="add_circle_outline" label="Ajouter un élément"
                                @click="addExtraFees()" />
                            <q-btn flat style="color: #ff220c" icon="remove_circle_outline" label="Supprimer l'élément"
                                @click="removeExtraFees(product)" />
                        </div>
                    </div>
                </div>
                <br>
                <!--************ Ecritures comptables **********-->
                <div class="table-container">
                    <h3 class="mt-8 mb-4 text-lg font-medium text-[#F7287B] head">Écriture comptable :</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Code Journal</th>
                                <th>Libellé Journal</th>
                                <th>Numéro Écriture</th>
                                <th>Date Comptabilisation</th>
                                <th>Numéro Compte</th>
                                <th>Libellé Compte</th>
                                <th>Numéro Compte Auxiliaire</th>
                                <th>Libellé Compte Auxiliaire</th>
                                <th>Référence Pièce</th>
                                <th>Date Pièce</th>
                                <th>Libellé Écriture</th>
                                <th>Montant Débit</th>
                                <th>Montant Crédit</th>
                                <th>Lettrage</th>
                                <th>Date Lettrage</th>
                                <th>Date Validation</th>
                                <th>Montant en Devise</th>
                                <th>Identifiant Devise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="entry in accountingEntriesComputed" :key="entry.numeroEcriture">
                                <td>{{ entry.codeJournal }}</td>
                                <td>{{ entry.libelleJournal }}</td>
                                <td>{{ entry.numeroEcriture }}</td>
                                <td>{{ entry.dateCompta }}</td>
                                <td>{{ entry.numeroCompte }}</td>
                                <td>{{ entry.libelleCompte }}</td>
                                <td>{{ entry.numeroCompteAux }}</td>
                                <td>{{ entry.libelleCompteAux }}</td>
                                <td>{{ entry.referencePiece }}</td>
                                <td>{{ entry.datePiece }}</td>
                                <td>{{ entry.libelleEcriture }}</td>
                                <td>{{ entry.montantDebit }}</td>
                                <td>{{ entry.montantCredit }}</td>
                                <td>{{ entry.lettrage }}</td>
                                <td>{{ entry.dateLettrage }}</td>
                                <td>{{ entry.dateValidation }}</td>
                                <td>{{ entry.montantDevise }}</td>
                                <td>{{ entry.idDevise }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="action-buttons">
                    <div class="button-group">

                        <button
                        v-if="!isTemp"
                            class="text-white inline-flex m-auto items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none  font-normal rounded-lg text-sm px-5 py-2.5 text-center head"
                            @click="approveData">
                            Valider les Données
                        </button>
                        <button v-else
                            class="text-white inline-flex m-auto items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none  font-normal rounded-lg text-sm px-5 py-2.5 text-center head"
                            @click="approveData">
                            Modifier les Données
                        </button>
                        <button
                            class="text-white inline-flex m-auto items-center bg-[#F7287B] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none  font-normal rounded-lg text-sm px-5 py-2.5 text-center head"
                            @click="sendToEvoliz">
                            Envoyer dans ArchiPilot
                        </button>

                    </div>
                    <div v-if="isLoading" class="loading-spinner">
                        <q-spinner-box style="color: #2d00f7" size="2em" />
                        <q-tooltip :offset="[0, 8]">QSpinnerBox</q-tooltip>
                    </div>
                    <p v-if="showMessage" :style="{ color: messageColor }" class="confirmation-message">
                        {{ confirmationMessage }}
                    </p>
                    <br>
                    <span class="validated-invoices">
                        Vous avez validé {{ validatedInvoices.length }} document(s) aujourd'hui !
                    </span>
                    <br>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import FilterAndSort from '../components/FilterAndSort.vue'
import { ref, computed, watch, watchEffect, onMounted, onBeforeUnmount, nextTick } from 'vue';
import axios from 'axios';
import { getInvoiceDatas, getStakeholderAccount, getCountry, getStakeholderInfos, getProducts, registration, getTvaAccount, replaceLastTwoCharsWithZero, getAllTvaAccountsAndAmounts, getIsDeposit, formatFecEntry, getValidatedInvoices } from '../utils/utils.js';
import { useStore } from 'vuex';
import { createFecEntry } from '../utils/accounting.js';
import { downloadFecFile } from '../utils/utils.js';
import accountsData from '../assets/pcg_2024_JSON.json';
import accountsData2 from '../assets/pcg_compte_2.json';
import tvaAccountsData from '../assets/pcg_tva_2024_JSON.json';
import { asyncComputed, computedAsync } from '@vueuse/core'
import Header2 from '@/components/Header2.vue';
import { createContext } from 'chart.js/helpers';
import AddSupplierForm from '@/components/AddSupplierForm.vue';

export default {
    components: {
        FilterAndSort,
        Header2,
        AddSupplierForm
    },
    setup() {
        const store = useStore();
        const loading = ref(false);
        const pdfUrl = ref();
        const isTemp = ref(true);
        const companySelected = computed(() => store.state.companySelected);
        //Données de la facture
        const invoiceDatas = ref({});
        const stakeholderInfos = ref({});
        const registrationValue = ref({});
        const invoicePaymentMethod = ref('');
        const supplierType = ref('');
        const marketPlaceName = ref('');
        const country = ref('');
        const isImmo = ref(false);
        //Produits
        const products = ref([]);
        const isLoading = ref(false);
        //Validation des données
        const validatedInvoices = ref(0);
        const confirmationMessage = ref('');
        const messageColor = ref('');
        const showMessage = ref(false);
        const showTvaWarning = ref(false);
        const isSaving = ref(false);
        const fileName = ref('');
        const productMessage = ref('');
        const productMessageColor = ref('');
        // Liste des unités
        const units = ref([
            "Année",
            "Centaine",
            "Dizaine",
            "Forfait",
            "Gramme",
            "Groupe",
            "Hectare",
            "Hectolitre",
            "Heure",
            "Image",
            "Jour",
            "Kilogramme",
            "Kilomètre",
            "Kilomètre carré",
            "Litre",
            "Lot",
            "Mètre",
            "Mètre carré",
            "Mètre cube",
            "Mètre linéaire",
            "Millier",
            "Milligramme",
            "Millilitre",
            "Minute",
            "Mois",
            "Paire",
            "Personne",
            "Seconde",
            "Stère",
            "Tonne",
            "Unité"
        ]);

        //Liste des types de paiements
        const paymentMethods = ref([
            { label: 'autres' },
            { label: 'avoir' },
            { label: 'carte bancaire' },
            { label: 'chèque' },
            { label: 'chèque emploi service universel (CESU)' },
            { label: 'chèque vacances' },
            { label: 'contre-remboursement' },
            { label: 'espèces' },
            { label: 'lettre de change' },
            { label: 'payPal' },
            { label: 'prélèvement' },
            { label: 'traite' },
            { label: 'virement' }
        ]);

        //Liste des marketplaces
        const marketplaces = ref([
            "", "Amazon", "Ali Express", "Airbnb", "Alibaba", "Allegro", "Auchan", "Booking", "But", "BackMarket",
            "BHV", "Boulanger", "Bricoprive", "Bol", "C Discount", "Conforama", "Conrad", "Carrefour", "Camif",
            "Darty", "Delamaison", "Ebay", "Etsy", "Emag", "Eprice", "Fnac", "Flipkart", "Groupon", "GoSport",
            "Intermarche", "Leroy Merlin", "Leclerc", "LDLC", "Mano Mano", "MercadoLibre", "MaisonDuMonde", "MacWay",
            "Oui Sncf", "Ozon", "Pinduoduo", "La Poste", "ThePhoneHouse", "La Redoute", "RueDuCommerce", "Rakuten", "Retif",
            "Refurbed", "ShowroomPrive", "Shopee", "Sprinter", "Taobao", "Tmall", "Temu", "U balbi", "Vinted", "Veepee", "Wish",
            "Walmart", "WildBerries"
        ]);

        //Liste des pays en anglais
        const countryList = ref([
            { name: 'Afghanistan', code: 'AF' },
            { name: 'Åland Islands', code: 'AX' },
            { name: 'Albania', code: 'AL' },
            { name: 'Algeria', code: 'DZ' },
            { name: 'American Samoa', code: 'AS' },
            { name: 'AndorrA', code: 'AD' },
            { name: 'Angola', code: 'AO' },
            { name: 'Anguilla', code: 'AI' },
            { name: 'Antarctica', code: 'AQ' },
            { name: 'Antigua and Barbuda', code: 'AG' },
            { name: 'Argentina', code: 'AR' },
            { name: 'Armenia', code: 'AM' },
            { name: 'Aruba', code: 'AW' },
            { name: 'Australia', code: 'AU' },
            { name: 'Austria', code: 'AT' },
            { name: 'Azerbaijan', code: 'AZ' },
            { name: 'Bahamas', code: 'BS' },
            { name: 'Bahrain', code: 'BH' },
            { name: 'Bangladesh', code: 'BD' },
            { name: 'Barbados', code: 'BB' },
            { name: 'Belarus', code: 'BY' },
            { name: 'Belgium', code: 'BE' },
            { name: 'Belize', code: 'BZ' },
            { name: 'Benin', code: 'BJ' },
            { name: 'Bermuda', code: 'BM' },
            { name: 'Bhutan', code: 'BT' },
            { name: 'Bolivia', code: 'BO' },
            { name: 'Bosnia and Herzegovina', code: 'BA' },
            { name: 'Botswana', code: 'BW' },
            { name: 'Bouvet Island', code: 'BV' },
            { name: 'Brazil', code: 'BR' },
            { name: 'British Indian Ocean Territory', code: 'IO' },
            { name: 'Brunei Darussalam', code: 'BN' },
            { name: 'Bulgaria', code: 'BG' },
            { name: 'Burkina Faso', code: 'BF' },
            { name: 'Burundi', code: 'BI' },
            { name: 'Cambodia', code: 'KH' },
            { name: 'Cameroon', code: 'CM' },
            { name: 'Canada', code: 'CA' },
            { name: 'Cape Verde', code: 'CV' },
            { name: 'Cayman Islands', code: 'KY' },
            { name: 'Central African Republic', code: 'CF' },
            { name: 'Chad', code: 'TD' },
            { name: 'Chile', code: 'CL' },
            { name: 'China', code: 'CN' },
            { name: 'Christmas Island', code: 'CX' },
            { name: 'Cocos (Keeling) Islands', code: 'CC' },
            { name: 'Colombia', code: 'CO' },
            { name: 'Comoros', code: 'KM' },
            { name: 'Congo', code: 'CG' },
            { name: 'Congo, The Democratic Republic of the', code: 'CD' },
            { name: 'Cook Islands', code: 'CK' },
            { name: 'Costa Rica', code: 'CR' },
            { name: 'Cote D\'Ivoire', code: 'CI' },
            { name: 'Croatia', code: 'HR' },
            { name: 'Cuba', code: 'CU' },
            { name: 'Cyprus', code: 'CY' },
            { name: 'Czech Republic', code: 'CZ' },
            { name: 'Denmark', code: 'DK' },
            { name: 'Djibouti', code: 'DJ' },
            { name: 'Dominica', code: 'DM' },
            { name: 'Dominican Republic', code: 'DO' },
            { name: 'Ecuador', code: 'EC' },
            { name: 'Egypt', code: 'EG' },
            { name: 'El Salvador', code: 'SV' },
            { name: 'Equatorial Guinea', code: 'GQ' },
            { name: 'Eritrea', code: 'ER' },
            { name: 'Estonia', code: 'EE' },
            { name: 'Ethiopia', code: 'ET' },
            { name: 'Falkland Islands (Malvinas)', code: 'FK' },
            { name: 'Faroe Islands', code: 'FO' },
            { name: 'Fiji', code: 'FJ' },
            { name: 'Finland', code: 'FI' },
            { name: 'France', code: 'FR' },
            { name: 'French Guiana', code: 'GF' },
            { name: 'French Polynesia', code: 'PF' },
            { name: 'French Southern Territories', code: 'TF' },
            { name: 'Gabon', code: 'GA' },
            { name: 'Gambia', code: 'GM' },
            { name: 'Georgia', code: 'GE' },
            { name: 'Germany', code: 'DE' },
            { name: 'Ghana', code: 'GH' },
            { name: 'Gibraltar', code: 'GI' },
            { name: 'Greece', code: 'GR' },
            { name: 'Greenland', code: 'GL' },
            { name: 'Grenada', code: 'GD' },
            { name: 'Guadeloupe', code: 'GP' },
            { name: 'Guam', code: 'GU' },
            { name: 'Guatemala', code: 'GT' },
            { name: 'Guernsey', code: 'GG' },
            { name: 'Guinea', code: 'GN' },
            { name: 'Guinea-Bissau', code: 'GW' },
            { name: 'Guyana', code: 'GY' },
            { name: 'Haiti', code: 'HT' },
            { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
            { name: 'Holy See (Vatican City State)', code: 'VA' },
            { name: 'Honduras', code: 'HN' },
            { name: 'Hong Kong', code: 'HK' },
            { name: 'Hungary', code: 'HU' },
            { name: 'Iceland', code: 'IS' },
            { name: 'India', code: 'IN' },
            { name: 'Indonesia', code: 'ID' },
            { name: 'Iran, Islamic Republic Of', code: 'IR' },
            { name: 'Iraq', code: 'IQ' },
            { name: 'Ireland', code: 'IE' },
            { name: 'Isle of Man', code: 'IM' },
            { name: 'Israel', code: 'IL' },
            { name: 'Italy', code: 'IT' },
            { name: 'Jamaica', code: 'JM' },
            { name: 'Japan', code: 'JP' },
            { name: 'Jersey', code: 'JE' },
            { name: 'Jordan', code: 'JO' },
            { name: 'Kazakhstan', code: 'KZ' },
            { name: 'Kenya', code: 'KE' },
            { name: 'Kiribati', code: 'KI' },
            { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
            { name: 'Korea, Republic of', code: 'KR' },
            { name: 'Kuwait', code: 'KW' },
            { name: 'Kyrgyzstan', code: 'KG' },
            { name: 'Lao People\'S Democratic Republic', code: 'LA' },
            { name: 'Latvia', code: 'LV' },
            { name: 'Lebanon', code: 'LB' },
            { name: 'Lesotho', code: 'LS' },
            { name: 'Liberia', code: 'LR' },
            { name: 'Libyan Arab Jamahiriya', code: 'LY' },
            { name: 'Liechtenstein', code: 'LI' },
            { name: 'Lithuania', code: 'LT' },
            { name: 'Luxembourg', code: 'LU' },
            { name: 'Macao', code: 'MO' },
            { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
            { name: 'Madagascar', code: 'MG' },
            { name: 'Malawi', code: 'MW' },
            { name: 'Malaysia', code: 'MY' },
            { name: 'Maldives', code: 'MV' },
            { name: 'Mali', code: 'ML' },
            { name: 'Malta', code: 'MT' },
            { name: 'Marshall Islands', code: 'MH' },
            { name: 'Martinique', code: 'MQ' },
            { name: 'Mauritania', code: 'MR' },
            { name: 'Mauritius', code: 'MU' },
            { name: 'Mayotte', code: 'YT' },
            { name: 'Mexico', code: 'MX' },
            { name: 'Micronesia, Federated States of', code: 'FM' },
            { name: 'Moldova, Republic of', code: 'MD' },
            { name: 'Monaco', code: 'MC' },
            { name: 'Mongolia', code: 'MN' },
            { name: 'Montserrat', code: 'MS' },
            { name: 'Morocco', code: 'MA' },
            { name: 'Mozambique', code: 'MZ' },
            { name: 'Myanmar', code: 'MM' },
            { name: 'Namibia', code: 'NA' },
            { name: 'Nauru', code: 'NR' },
            { name: 'Nepal', code: 'NP' },
            { name: 'Netherlands', code: 'NL' },
            { name: 'Netherlands Antilles', code: 'AN' },
            { name: 'New Caledonia', code: 'NC' },
            { name: 'New Zealand', code: 'NZ' },
            { name: 'Nicaragua', code: 'NI' },
            { name: 'Niger', code: 'NE' },
            { name: 'Nigeria', code: 'NG' },
            { name: 'Niue', code: 'NU' },
            { name: 'Norfolk Island', code: 'NF' },
            { name: 'Northern Mariana Islands', code: 'MP' },
            { name: 'Norway', code: 'NO' },
            { name: 'Oman', code: 'OM' },
            { name: 'Pakistan', code: 'PK' },
            { name: 'Palau', code: 'PW' },
            { name: 'Palestinian Territory, Occupied', code: 'PS' },
            { name: 'Panama', code: 'PA' },
            { name: 'Papua New Guinea', code: 'PG' },
            { name: 'Paraguay', code: 'PY' },
            { name: 'Peru', code: 'PE' },
            { name: 'Philippines', code: 'PH' },
            { name: 'Pitcairn', code: 'PN' },
            { name: 'Poland', code: 'PL' },
            { name: 'Portugal', code: 'PT' },
            { name: 'Puerto Rico', code: 'PR' },
            { name: 'Qatar', code: 'QA' },
            { name: 'Reunion', code: 'RE' },
            { name: 'Romania', code: 'RO' },
            { name: 'Russian Federation', code: 'RU' },
            { name: 'RWANDA', code: 'RW' },
            { name: 'Saint Helena', code: 'SH' },
            { name: 'Saint Kitts and Nevis', code: 'KN' },
            { name: 'Saint Lucia', code: 'LC' },
            { name: 'Saint Pierre and Miquelon', code: 'PM' },
            { name: 'Saint Vincent and the Grenadines', code: 'VC' },
            { name: 'Samoa', code: 'WS' },
            { name: 'San Marino', code: 'SM' },
            { name: 'Sao Tome and Principe', code: 'ST' },
            { name: 'Saudi Arabia', code: 'SA' },
            { name: 'Senegal', code: 'SN' },
            { name: 'Serbia and Montenegro', code: 'CS' },
            { name: 'Seychelles', code: 'SC' },
            { name: 'Sierra Leone', code: 'SL' },
            { name: 'Singapore', code: 'SG' },
            { name: 'Slovakia', code: 'SK' },
            { name: 'Slovenia', code: 'SI' },
            { name: 'Solomon Islands', code: 'SB' },
            { name: 'Somalia', code: 'SO' },
            { name: 'South Africa', code: 'ZA' },
            { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
            { name: 'Spain', code: 'ES' },
            { name: 'Sri Lanka', code: 'LK' },
            { name: 'Sudan', code: 'SD' },
            { name: 'Suriname', code: 'SR' },
            { name: 'Svalbard and Jan Mayen', code: 'SJ' },
            { name: 'Swaziland', code: 'SZ' },
            { name: 'Sweden', code: 'SE' },
            { name: 'Switzerland', code: 'CH' },
            { name: 'Syrian Arab Republic', code: 'SY' },
            { name: 'Taiwan, Province of China', code: 'TW' },
            { name: 'Tajikistan', code: 'TJ' },
            { name: 'Tanzania, United Republic of', code: 'TZ' },
            { name: 'Thailand', code: 'TH' },
            { name: 'Timor-Leste', code: 'TL' },
            { name: 'Togo', code: 'TG' },
            { name: 'Tokelau', code: 'TK' },
            { name: 'Tonga', code: 'TO' },
            { name: 'Trinidad and Tobago', code: 'TT' },
            { name: 'Tunisia', code: 'TN' },
            { name: 'Turkey', code: 'TR' },
            { name: 'Turkmenistan', code: 'TM' },
            { name: 'Turks and Caicos Islands', code: 'TC' },
            { name: 'Tuvalu', code: 'TV' },
            { name: 'Uganda', code: 'UG' },
            { name: 'Ukraine', code: 'UA' },
            { name: 'United Arab Emirates', code: 'AE' },
            { name: 'United Kingdom', code: 'GB' },
            { name: 'United States', code: 'US' },
            { name: 'United States Minor Outlying Islands', code: 'UM' },
            { name: 'Uruguay', code: 'UY' },
            { name: 'Uzbekistan', code: 'UZ' },
            { name: 'Vanuatu', code: 'VU' },
            { name: 'Venezuela', code: 'VE' },
            { name: 'Viet Nam', code: 'VN' },
            { name: 'Virgin Islands, British', code: 'VG' },
            { name: 'Virgin Islands, U.S.', code: 'VI' },
            { name: 'Wallis and Futuna', code: 'WF' },
            { name: 'Western Sahara', code: 'EH' },
            { name: 'Yemen', code: 'YE' },
            { name: 'Zambia', code: 'ZM' },
            { name: 'Zimbabwe', code: 'ZW' }
        ]);

        //Liste des taux de TVA
        const tvaRates = ref([
            '0',
            '0.9',
            '1.05', 
            '2.1',
            '2.4',
            '2.5',
            '2.6',
            '3',
            '3.7',
            '3.8',
            '4',
            '4.5',
            '4.8',
            '5',
            '5.5',
            '6',
            '7',
            '7.6',
            '7.7',
            '8',
            '8.1',
            '8.5',
            '9',
            '9.5',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '19.25',
            '19.6',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '27'
        ])

        const tvaRatesSelected = ref([]);

        //Données liées à l'exercice
        const lastHA = ref();
        //Dialogue pour ajouter un nouveau fournisseur
        const openNewSupplierDialog = ref(false);

        //Etat de la facture
        const invoiceState = ref("Réglée");

        //********Fonctions**********


        //Fonction pour télécharger et afficher la facture
        const downloadPdf = async (fileName) => {
            try {
                console.log(`Début du téléchargement du PDF pour le fichier : ${fileName}`);
                let url = `${process.env.VUE_APP_BASE_URL}/imageFromScaleway/${fileName}`;
                let response = await axios.get(url, { responseType: 'blob' });
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                console.log('Téléchargement du PDF terminé');
                return pdfUrl;
            } catch (error) {
                console.error('Erreur lors du téléchargement du PDF :', error);
                throw error;  // Relancer l'erreur après l'avoir enregistrée
            }
        };

        //Fonction pour récupérer les données, lors de la seléction d'une facture
        const handleSelect = async (selected) => {
            try {
                console.log('Fichier sélectionné :');
                loading.value = true;
                fileName.value = selected.name;
                // Télécharger le PDF
                pdfUrl.value = await downloadPdf(selected.name);

                // Récupérer et traiter les données de la facture
                console.log('Récupération des données de la facture...');
                const invoiceData = await getInvoiceDatas(selected, isTemp.value);
                invoiceDatas.value = invoiceData;

                // Affecter les méthodes de paiement et autres informations
                invoicePaymentMethod.value = invoiceData.payment_method || '';
                supplierType.value = invoiceData.supplier_type || '';
                marketPlaceName.value = invoiceData.marketplace_name || '';

                // Récupérer le compte du tiers
                console.log('Récupération du compte du tiers...');
                invoiceDatas.value.stakeholder_account = await getStakeholderAccount(companySelected.value.id, invoiceDatas.value.stakeholder_id);

                // Récupérer les données du pays
                console.log('Récupération des données du pays...');
                country.value = await getCountry(invoiceDatas.value.stakeholder_id);

                // Récupérer les informations du tiers
                console.log('Récupération des informations du tiers...');
                stakeholderInfos.value = await getStakeholderInfos(invoiceDatas.value.stakeholder_id);


                // Récupérer et traiter les produits
                console.log('Récupération des produits...');
                products.value = await getProducts(selected, isTemp.value);

                //Gérer la fanchise de tva
                if (companySelected.value.tva_regime == 'franchise') {
                    products.value.map(product => {
                        product.tva_rate = 0;
                    })
                }
                // Mettre à jour le compte produit
                console.log('Mise à jour du compte produit...');
                updateProductAccount();

                //Récupérer les comptes fournisseurs
                console.log('Récupération des comptes fournisseurs...');
                await fetchCompanyStakeholder();

                // Mettre à jour les valeurs d'enregistrement
                console.log('Mise à jour des valeurs d\'enregistrement...');
                getRegistrationValues();
                addRegistrationToProducts();


                if (isTemp.value) {
                    // Récupérer les frais supplémentaires et les informations de dépôt si temporaire
                    console.log('Récupération des frais supplémentaires...');
                    await getExtraFees2();
                    console.log('Récupération des informations de dépôt...');
                    await getIsDeposit();
                }

                // Récupérer les factures validées
                console.log('Récupération des factures validées...');
                await getValidatedInvoices();

                //Ajouter les immobilisation
                addImmoToProducts();
                /*
                // Mettre à jour les comptes TVA
                console.log('Mise à jour des comptes TVA...');
                tvaAccounts.value = getTvaAccount(country.value, invoiceDatas.value, products.value);
                addRegistrationToTva();

                // Générer les écritures comptables
                console.log('Génération des écritures comptables...');
                getAccountingEntry2();

                // Générer les écritures de décaissement
                console.log('Génération des écritures de décaissement...');
                getDecaissementEntry();

                

                
                */
                //Gérer les immobilisation
                products.value.map(product => {
                    if (product.data && product.data.isImmo) {
                        handleOneImmo(product, true);
                    }
                })

                //Gestion des données de l'exercice
                await fetchAccountingExerciceData();

                //Gérer la TVA
                addRegistrationToTva();

                loading.value = false;
                console.log('Processus terminé avec succès');
            } catch (error) {
                console.error('Erreur dans handleSelect :', error);
                loading.value = false; // Assurer que le chargement est réinitialisé en cas d'erreur
            }
        };


        // Gestion des produits
        const addImmoToProducts = () => {
            for (let index = 0; index < products.value.length; index++) {
                let product = products.value[index];
                // Vérifier si product.data est null ou indéfini
                if (!product.data) {
                    product.data = {};
                }

                // Vérifier si isImmo est null ou indéfini et le définir à false si nécessaire
                if (!product.data.hasOwnProperty('isImmo') || product.data.isImmo === null) {
                    product.data.isImmo = false;
                }

                // Définir isOneImmo pour chaque produit
                isOneImmo.value[index] = product.data.isImmo;
            }
        };

        const removeProduct = (product) => {

            var indexToDelete = -1;
            for (var i = 0; i < products.value.length; i++) {
                if (products.value[i].designation === product.designation) {
                    indexToDelete = i;
                    break;
                }
            }
            if (indexToDelete !== -1) {
                products.value.splice(indexToDelete, 1);
            }
        }

        const duplicateAccount = (product) => {
            const newAccount = product.account;
            products.value.forEach(p => {
                p.account = newAccount;
            });
        }

        const addProduct = () => {
            products.value.push({
                designation: "",
                pack_unity: "",
                pack_number: 0,
                stock_name: "",
                quantity: 1,
                tva_rate: 20,
                account: "60110000020",
                accountBis: "60110000000",
                total_amount_ht: 0,
                total_amount_ttc: 0,
                registration: "",
                unity: "",
            })
        }

        const updateProductAccount = () => {

            products.value.forEach((product, index) => {

                if (isOneImmo.value[index] == true) {
                    return;
                }
                if (!product.account) {
                    return;
                }

                let newAccountSuffix;
                if (product.tva_rate === 20) {
                    newAccountSuffix = '20';
                } else if (product.tva_rate === 13) {
                    newAccountSuffix = '13';
                } else if (product.tva_rate === 10) {
                    newAccountSuffix = '10';
                } else if (product.tva_rate === 5.5) {
                    newAccountSuffix = '55';
                } else if (product.tva_rate === 2.1) {
                    newAccountSuffix = '21';
                } else if (product.tva_rate === 0) {
                    newAccountSuffix = '00';
                }

                if (newAccountSuffix) {
                    product.account = product.account.slice(0, -2) + newAccountSuffix;
                }
            })
        }

        // Watcher pour chaque produit dans la liste des produits
        watchEffect(() => {
            //Watcher pour le changement de compte
            products.value.forEach((product, index) => {
                watch(
                    () => product.account,
                    (newValue, oldValue) => {
                        if (newValue !== oldValue) {
                            updateProductAccount();
                        }
                    },
                    { deep: true }
                );
                 // Watcher pour le changement de taux de TVA
                 watch(
                    () => product.tva_rate,
                    (newValue, oldValue) => {
                        if (newValue !== oldValue) {
                            updateProductAccount();
                        }
                    },
                    { deep: true }
                );
            });
        });




        //Gestion des comptes pcg pour les produits
        const search = ref('');
        const accounts = ref(
            [
                ...Object.entries(accountsData[0]).map(([key, value]) => ({ label: `${key} - ${value}`, key: key, value: value })),
                ...Object.entries(accountsData2[0]).map(([key, value]) => ({ label: `${key} - ${value}`, key: key, value: value }))
            ]
        );
        const filteredAccounts = computed(() => {
            if (search.value) {
                return accounts.value.filter((account) =>
                    Object.values(account).some((value) =>
                        value && value.toString().toLowerCase().includes(search.value.toLowerCase())
                    )
                );
            } else {
                return accounts.value;
            }
        });


        const fetchAccounts = async () => {
            try {
                if (!companySelected.value || !companySelected.value.evoliz_id) {
                    console.error("Evoliz ID not found in companySelected:", companySelected.value);
                    return;
                }



                // Créer un Map pour stocker les comptes uniques
                const uniqueAccounts = new Map();

                // Ajouter d'abord les comptes existants
                accounts.value.forEach(account => {
                    const baseAccount = account.key.slice(0, -2);
                    const currentEntry = uniqueAccounts.get(baseAccount);
                    if (!currentEntry || account.key.endsWith('00')) {
                        uniqueAccounts.set(baseAccount, account);
                    }
                });



                accounts.value = Array.from(uniqueAccounts.values());


            } catch (error) {
                console.error('Erreur lors de la récupération des comptes:', error);
            }
        };

        const fetchSpecialAccounts = async () => {
            try {
                if (!companySelected.value || !companySelected.value.id) {
                    console.error("ID de l'entreprise non trouvé dans companySelected:", companySelected.value);
                    return;
                }

                const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/pcg-special/${companySelected.value.id}`);
                const specialAccountsData = response.data;
                console.log("Comptes spéciaux récupérés:", specialAccountsData);

                // Créer un Map avec les comptes existants
                const uniqueAccounts = new Map();
                accounts.value.forEach(account => {
                    const baseAccount = account.key.slice(0, -2);
                    uniqueAccounts.set(baseAccount, account);
                });

                // Ajouter les comptes spéciaux
                specialAccountsData.forEach(account => {
                    const baseAccount = account.accounting_account.slice(0, -2);
                    const newAccount = {
                        label: `${account.accounting_account} - ${account.label}`,
                        key: account.accounting_account,
                        value: account.label
                    };

                    if (!uniqueAccounts.has(baseAccount) || account.accounting_account.endsWith('00')) {
                        uniqueAccounts.set(baseAccount, newAccount);
                    }
                });

                const response2 = await axios.get(`${process.env.VUE_APP_BASE_URL}/comparePCG/${companySelected.value.evoliz_id}`);
                const accountsData = response2.data.missingInCSV;
                // Ajouter les nouveaux comptes
                accountsData.forEach(account => {
                    const baseAccount = account.code.slice(0, -2);
                    const currentEntry = uniqueAccounts.get(baseAccount);
                    const newAccount = {
                        label: `${account.code} - ${account.labels}`,
                        key: account.code,
                        value: account.labels
                    };

                    if (!currentEntry || account.code.endsWith('00')) {
                        uniqueAccounts.set(baseAccount, newAccount);
                    }
                });

                accounts.value = Array.from(uniqueAccounts.values());
            } catch (error) {
                console.error('Erreur lors de la récupération des comptes spéciaux:', error);
            }
        };


        // Appel de la fonction fetchSpecialAccounts après fetchAccounts
        const fetchAllAccounts = async () => {
            await fetchAccounts();
            await fetchSpecialAccounts();
        };

        // Remplacer l'appel à fetchAccounts par fetchAllAccounts dans le watch
        watch(companySelected, async (newVal) => {
            if (newVal && newVal.evoliz_id) {
                console.log("L'entreprise sélectionnée a été mise à jour:", newVal);
                await fetchAllAccounts();
            }
        }, { immediate: true });


        const filterAccounts = (val, update) => {
            update(() => {
                search.value = val;
            });
        };

        const changeIsTemp = (temp) => {
            console.log(temp)
            isTemp.value = temp
        }

        //Gestion des enregistrements
        const getRegistrationValues = async () => {
            registrationValue.value['total_ttc'] = registration(invoiceDatas.value.total_ttc, invoiceDatas.value.stakeholder_account, invoiceDatas)

        }

        const addRegistrationToProducts = () => {
            products.value.forEach(product => {
                if (stakeholderInfos.value.tva_regime == 'franchise') {
                    product.registration = registration(product.total_amount_ttc, product.account, invoiceDatas)
                } else {
                    product.registration = registration(product.total_amount_ht, product.account, invoiceDatas)
                }
            });
        }

        //Gestion de la TVA
        const tvaAccountsRaw = ref([]);

        const tvaAccounts = computed({
            get: () => tvaAccountsRaw.value,
            set: (newVal) => {
                tvaAccountsRaw.value = newVal;
            }
        });

        const updateTvaAccounts = async () => {
            console.log("updateTvaAccounts")
            const computedTvaAccounts = getTvaAccount(country.value, invoiceDatas.value, products.value, invoiceState.value);
            console.log("computedTvaAccounts", computedTvaAccounts)
            tvaAccounts.value = computedTvaAccounts.map(account => ({
                ...account,
                editable: true // Ajout d'une propriété pour gérer l'édition
            }));
            if(isOneImmo.value.some(item=> item == true)){
                products.value.forEach((product, index) => {
                    handleOneImmo(product, isOneImmo.value[index]);
                })
            }
        };

        const addRegistrationToTva = () => {
            tvaAccounts.value.forEach(account => {
                // Vérifier et traiter chaque taux de TVA
                    account.registration1 = invoiceDatas.value.document_type == "facture" ? "débit" : "crédit";
            })  
            }

            watch(
            [
                country, 
                invoiceDatas, 
                products, 
                () => invoiceDatas.value.invoice_mention, 
                () => invoiceDatas.value.tva_debit_option, 
                () => invoiceState.value
            ],
            (newValues, oldValues) => {
                console.log('Changements détectés :', { newValues, oldValues });
                updateTvaAccounts();
                addRegistrationToTva();
                
            },
            { immediate: true, deep: true }
        );
        //Gestion des vérifications
        const verifyEntireInvoice = () => {
            let globalTotalDebit = 0;
            let globalTotalCredit = 0;

            accountingEntriesList.value.forEach(entry => {
                globalTotalDebit += parseFloat(entry.montantDebit);
                globalTotalCredit += parseFloat(entry.montantCredit);
            });

            const totalInvoiceAmount = invoiceDatas.value.total_ttc.toFixed(2);
            const isBalanced = Math.abs(globalTotalDebit.toFixed(2) - globalTotalCredit.toFixed(2)) < Number.EPSILON;

            return {
                isBalanced,
                totalDebit: globalTotalDebit.toFixed(2),
                totalCredit: globalTotalCredit.toFixed(2),
                invoiceTotal: totalInvoiceAmount,
                discrepancy: isBalanced ? 0 : Math.abs(globalTotalDebit - globalTotalCredit).toFixed(2)
            };
        };

        const verifyAccountingEntries = (product) => {
            if (!product) {
                console.error('Product is undefined');
                return false;
            }

            const totalAmountHT = Number(product.total_amount_ht) || 0;
            const tvaRate = Number(product.tva_rate) || 0;
            const totalTTC = Number(product.total_amount_ttc) || 0;

            const tvaAmount = (totalAmountHT * (tvaRate / 100));

            if (totalTTC.toFixed(2) == (totalAmountHT + tvaAmount).toFixed(2)) {
                return true;
            } else {
                return false;
            }
        };

        //Gestion des écritures
        const accountingEntriesList = ref([]);
        const accountingEntriesComputed = computedAsync(async () => {
            try {
                console.log('accountingEntriesComputed is running');

                if (!stakeholderInfos.value) {
                    console.warn('Missing stakeholder information');
                    return [];
                }

                if (!invoiceDatas.value) {
                    console.warn('Missing invoice data');
                    return [];
                }

                console.log('document_type:', invoiceDatas.value.document_type);

                if (!invoiceDatas.value.document_type) {
                    console.warn('Le type de document est manquant ou vide');
                }
            } catch (error) {
                console.log(error)
            }
            var accountingEntries = [];
            let date = new Date();
            const formattedDate = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`;
            try {

                if (stakeholderInfos.value && companySelected.value.tva_regime !== "franchise") {
                    if (invoiceDatas.value.document_type === "facture") {
                        products.value.map(product => {
                            // Écriture pour le débit du compte de charge (compte 6...)
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            var accountName = accounts.value.find(account => account.key === product.accountBis);
                            if (product.registration == "débit") {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            } else {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            }
                        })

                        extraFees.value.map(product => {
                            // Écriture pour le débit du compte de charge (compte 6...)
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            if (product.registration == "débit") {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            } else {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            }
                        })

                        var tvaAccountAmounts = getAllTvaAccountsAndAmounts(tvaAccounts);
                        tvaAccountAmounts.map(tvaAccountAmount => {
                            if (tvaAccountAmount.tvaAmount != 0) {
                                if(tvaAccountAmount.registration == "débit"){
                                // Écriture pour le débit du compte de TVA
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                if (tvaAccountAmount.account_2) {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    }
                                }else{
                                    // Écriture pour le crédit du compte de TVA
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    if (tvaAccountAmount.account_2) {
                                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    }
                                }
                            }
                        })
                        // Écriture pour le crédit du compte fournisseur            
                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.data.oldCurrencyData.original_amount, invoiceDatas.value.data.oldCurrencyData.original_currency));
                    } else if (invoiceDatas.value.document_type === "avoir") {
                        // Traiter les autres types de documents si nécessaire
                        products.value.map(product => {
                            var accountName = accounts.value.find(account => account.key === product.accountBis);

                            // Écriture pour le débit du compte de charge (compte 6...)
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            if (product.registration == "débit") {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            } else {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            }
                        })

                        extraFees.value.map(product => {
                            // Écriture pour le débit du compte de charge (compte 6...)
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            if (product.registration == "débit") {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            } else {
                                accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                            }
                        })

                        var tvaAccountAmounts = getAllTvaAccountsAndAmounts(tvaAccounts);
                        tvaAccountAmounts.map(tvaAccountAmount => {
                            if (tvaAccountAmount.tvaAmount != 0) {
                                if (tvaAccountAmount.registration === "débit") {
                                    // Écriture pour le débit du compte de TVA
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    if (tvaAccountAmount.account_2) {
                                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    }
                                } else {
                                    // Écriture pour le crédit du compte de TVA
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    if (tvaAccountAmount.account_2) {
                                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    }
                                }
                            }
                        })
                        // Écriture pour le crédit du compte fournisseur            
                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

                    } else if (invoiceDatas.value.document_type === "acompte") {
                        console.log("c'est un acompte")
                        //Si c'est une facture finale
                        let isDepositFinal = await getIsDepositFinal();
                        if (isDepositFinal) {
                            console.log("L'acompte est final")
                            //Ecriture au debit des produits
                            products.value.map(product => {
                                // Écriture pour le débit du compte de charge (compte 6...)
                                product.accountBis = replaceLastTwoCharsWithZero(product.account);
                                if (product.registration == "débit") {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                } else {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                }
                            })
                            extraFees.value.map(product => {
                                // Écriture pour le débit du compte de charge (compte 6...)
                                product.accountBis = replaceLastTwoCharsWithZero(product.account);
                                if (product.registration == "débit") {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                } else {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                }
                            })
                            //Ecriture au débit de la tva de la facture
                            var tvaAccountAmounts = getAllTvaAccountsAndAmounts(tvaAccounts);
                            tvaAccountAmounts.map(tvaAccountAmount => {
                                if (tvaAccountAmount.tvaAmount != 0) {
                                    // Écriture pour le débit du compte de TVA
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    if (tvaAccountAmount.account_2) {
                                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    }
                                }
                            })
                            //Ecriture au credit du compte de décaissement
                            accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, invoiceDatas.value, "Fournisseurs débiteurs - Avances et acomptes versés sur commandes", '4091000000', 0, invoiceDatas.value.depositsAmount, stakeholderInfos.value.name + " - " + invoiceDatas.value.invoice_number, invoiceDatas.value.data.oldCurrencyData.original_currency, invoiceDatas.value.data.oldCurrencyData.original_amount));

                            //Ecriture au crédit du compte fournisseur
                            accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

                            //Ecriture de la tva
                        }
                        //Sinon c'est une facture normale
                        else {
                            console.log("L'acompte n'est pas final")
                            var tvaAccountAmounts = getAllTvaAccountsAndAmounts(tvaAccounts);

                            products.value.map(product => {
                                //Détermination quel tva est utilisé
                                var tvaEnd;
                                tvaAccountAmounts.map(tvaAccount => {
                                    console.log(tvaAccount)
                                    if (tvaAccount.tvaRate == "20%") tvaEnd = "20";
                                    if (tvaAccount.tvaRate == "13%") tvaEnd = "13";
                                    if (tvaAccount.tvaRate == "10%") tvaEnd = "10";
                                    if (tvaAccount.tvaRate == "5.5%") tvaEnd = "55";
                                    if (tvaAccount.tvaRate == "2.1%") tvaEnd = "21";
                                    if (tvaAccount.tvaRate == "0%") tvaEnd = "00";
                                })
                                // Écriture pour le débit du compte d'attente
                                product.accountBis = replaceLastTwoCharsWithZero(product.account);
                                if (product.registration == "débit") {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, "40910000" + tvaEnd, "Fournisseurs débiteurs - Avances et acomptes versés sur commandes", "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, invoiceDatas.value.supplier_name + " - " + invoiceDatas.value.invoice_number, Math.abs(product.total_amount_ht), 0, "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                } else {
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, "40910000" + tvaEnd, "Fournisseurs débiteurs - Avances et acomptes versés sur commandes", "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, invoiceDatas.value.supplier_name + " - " + invoiceDatas.value.invoice_number, 0, Math.abs(product.total_amount_ht), "", "", "", product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data && product.data.oldCurrencyData ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                                }
                            })

                            //Ecriture au débit du compte de TVA
                            tvaAccountAmounts.map(tvaAccountAmount => {
                                if (tvaAccountAmount.tvaAmount != 0) {
                                    // Écriture pour le débit du compte de TVA
                                    accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    if (tvaAccountAmount.account_2) {
                                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                                    }
                                }
                            })
                            // Écriture pour le crédit du compte fournisseur            
                            accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.total_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

                        }
                    }
                } else {
                    // Traitement spécifique pour les entreprises en franchise de TVA
                    if (invoiceDatas.value.document_type === "facture") {
                        products.value.forEach(product => {

                            // Écriture pour le débit du compte de charge (compte 6...) sans TVA
                            var accountName = accounts.value.find(account => account.key === product.accountBis);
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, product.total_amount_ttc, 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                        });

                        extraFees.value.forEach(product => {
                            // Écriture pour le débit du compte de charge (compte 6...) pour les frais supplémentaires sans TVA
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, product.total_amount_ttc, 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                        });

                        // Écriture pour le crédit du compte fournisseur sans TVA
                        accountingEntries.push(createFecEntry("AC", "Journal d'achat", lastHA.value, formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

                    } else if (invoiceDatas.value.document_type === "avoir") {
                        // Gestion des avoirs en franchise de TVA
                        products.value.forEach(product => {
                            // Écriture pour le crédit du compte de charge (compte 6...) sans TVA
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            accountingEntries.push(createFecEntry("AC", "AVOIR", "", formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, product.total_amount_ttc, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                        });

                        extraFees.value.forEach(product => {
                            // Écriture pour le crédit du compte de charge (compte 6...) pour les frais supplémentaires sans TVA
                            product.accountBis = replaceLastTwoCharsWithZero(product.account);
                            accountingEntries.push(createFecEntry("AC", "AVOIR", "", formattedDate, product.account, accountName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, product.total_amount_ttc, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                        });

                        // Écriture pour le débit du compte fournisseur sans TVA
                        accountingEntries.push(createFecEntry("AC", "AVOIR", "", formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));
                    }
                }

                accountingEntriesList.value = accountingEntries
                return accountingEntries
            } catch (error) {
                console.log("Erreur lors de la génération des écritures comptables:", error);
            }
        },
        );

   
        const isOneImmo = ref([]);
        const handleOneImmo = async (product, immo) => {
            try {
                if (isOneImmo.value.some(item => item === true)) {
                    isImmo.value = true;
                }else{
                    isImmo.value = false;
                }
                // Ajout de comptes
                accounts.value = accounts.value.concat(
                    Object.entries(accountsData2[0]).map(([key, value]) => ({ label: `${key} - ${value}`, key: key, value: value }))
                );
                console.log("Comptes ajoutés:", accounts.value);

                // Récupérer le montant HT et le taux de TVA du produit
                let totalAmountHT = parseFloat(product.total_amount_ht.toFixed(2));
                let tvaRate = parseFloat(product.tva_rate.toFixed(2));

                console.log("Montant HT:", totalAmountHT);
                console.log("Taux de TVA:", tvaRate);

                if (isNaN(totalAmountHT) || isNaN(tvaRate)) {
                    throw new Error("Invalid total_amount_ht or tva_rate");
                }

                // Calculer le montant de TVA
                let tvaAmount = parseFloat(((totalAmountHT * tvaRate) / 100).toFixed(2));
                console.log("Montant de TVA calculé:", tvaAmount);

                if (immo) {
                    // Cas immobilisation : ajouter le montant de TVA au compte de TVA immobilisation
                    let tvaImmoAccount = tvaAccounts.value.find(account => account[`tva_immo_${tvaRate}`] !== undefined);
                    if (tvaImmoAccount) {
                        tvaImmoAccount[`tva_immo_${tvaRate}`] = parseFloat((tvaImmoAccount[`tva_immo_${tvaRate}`] + tvaAmount).toFixed(2));
                        console.log(`Le taux existe déjà, TVA sur immobilisation mise à jour pour le taux ${tvaRate}:`, tvaImmoAccount);
                    } else {
                        tvaAccounts.value.push({
                            [`tva_immo_${tvaRate}`]: tvaAmount,
                            account1: `44562100${tvaRate}`,
                            editable: true
                        });
                        console.log(`Le taux n'existe pas, TVA sur immobilisation ajoutée pour le taux ${tvaRate}:`, tvaAmount);
                    }
                    let tvaDeductibleAccount = tvaAccounts.value.find(account => account[`tva_${tvaRate}`] !== undefined);
                    if (tvaDeductibleAccount) {
                        tvaDeductibleAccount[`tva_${tvaRate}`] = parseFloat((tvaDeductibleAccount[`tva_${tvaRate}`] - tvaAmount).toFixed(2));
                        console.log(`TVA déductible mise à jour pour le taux ${tvaRate}:`, tvaDeductibleAccount);
                    }
                } else {
                    // Cas non immobilisation : soustraire le montant de TVA du compte de TVA immobilisation
                    let tvaImmoAccount = tvaAccounts.value.find(account => account[`tva_immo_${tvaRate}`] !== undefined);
                    if (tvaImmoAccount) {
                        tvaImmoAccount[`tva_immo_${tvaRate}`] = parseFloat((tvaImmoAccount[`tva_immo_${tvaRate}`] - tvaAmount).toFixed(2));
                        console.log(`TVA sur immobilisation soustraite pour le taux ${tvaRate}:`, tvaImmoAccount);
                        // Si le montant devient zéro ou négatif, supprimer l'entrée
                        if (tvaImmoAccount[`tva_immo_${tvaRate}`] <= 0) {
                            tvaAccounts.value = tvaAccounts.value.filter(account => account !== tvaImmoAccount);
                            console.log(`TVA sur immobilisation supprimée pour le taux ${tvaRate}:`, tvaImmoAccount);
                        }
                    }
                    // Ajouter le montant à la TVA déductible normale
                    let tvaDeductibleAccount = tvaAccounts.value.find(account => account[`tva_${tvaRate}`] !== undefined);
                    if (tvaDeductibleAccount) {
                        tvaDeductibleAccount[`tva_${tvaRate}`] = parseFloat((tvaDeductibleAccount[`tva_${tvaRate}`] + tvaAmount).toFixed(2));
                        console.log(`TVA déductible ajoutée pour le taux ${tvaRate}:`, tvaDeductibleAccount);
                    }
                }

                console.log("État final des comptes TVA:", tvaAccounts.value);

            } catch (error) {
                console.log("Erreur dans handleOneImmo:", error);
            }
        };

       


        //Gestion des extraFees
        const extraFees = ref([]);
        const otherTable = ref();
        const getExtraFees2 = async () => {
            try {
                extraFees.value = [];
                // Récupération de l'id de la facture
                const invoiceId = invoiceDatas.value.id;
                // Récupération du texte océrisé
                let url = `${process.env.VUE_APP_BASE_URL}/otherTable/${invoiceId}`;
                let response = await axios.get(url);
                console.log("tables de la facture", response.data)
                otherTable.value = response.data[0].other_table;

                // Conversion du texte de la facture en minuscule pour la comparaison
                const fullTextLowerCase = otherTable.value.fullText.toLowerCase();
                // Recherche de la marketplace dans le texte de la facture
                //const marketplaceFound = marketplaces.find(marketplace => fullTextLowerCase.includes(marketplace.toLowerCase()));

                // Envoi de l'ocr à l'IA
                let url2 = `${process.env.VUE_APP_BASE_URL}/extractInvoiceDetails`;
                let postBody = {
                    'otherTable': otherTable.value
                };
                let response2 = await axios.post(url2, postBody);
                let fees = response2.data;
                console.log(fees)
                // Vérification des frais supplémentaires
                if (fees.extraFees.billingFees !== null && fees.extraFees.billingFees !== 'null') {
                    extraFees.value = [...extraFees.value, ...fees.extraFees.billingFees]
                }
                if (fees.extraFees.shippingFees != null && fees.extraFees.shippingFees !== 'null') {
                    extraFees.value = [...extraFees.value, ...fees.extraFees.shippingFees]
                }
                if (fees.extraFees.depositFees != null && fees.extraFees.depositFees != 'null') {
                    extraFees.value = [...extraFees.value, ...fees.extraFees.depositFees]
                }
                // Assignation du mode de paiement et du type de fournisseur
                invoicePaymentMethod.value = fees.paymentMethod;
                console.log(fees)
                var marketplaceFound;
                if (Array.isArray(fees.marketplace)) {
                    marketplaceFound = fees.marketplace[0];
                } else if (typeof fees.marketplace === 'object' && fees.marketplace !== null) {
                    marketplaceFound = fees.marketplace;
                }

                if (marketplaceFound.isMarketPlace) {
                    supplierType.value = "MarketPlace";
                    marketPlaceName.value = marketplaceFound.marketPlaceName;
                } else {
                    supplierType.value = "Classique";
                    marketPlaceName.value = null;
                }
                const filteredExtraFees = extraFees.value.filter(extraFee => extraFee.total_amount_ht !== 0);

                filteredExtraFees.forEach(extraFee => {
                    extraFee.registration = registration(extraFee.total_amount_ht, extraFee.account);
                });

                extraFees.value = filteredExtraFees;
                updateExtraFeesAccount();

            } catch (error) {
                console.error('Error in getExtraFees2:', error);
            }
        }

        const updateExtraFeesAccount = () => {
            extraFees.value.forEach(fee => {
                if (!fee.account) {
                    return;
                }

                let newAccountSuffix;
                if (fee.tva_rate === 20) {
                    newAccountSuffix = '20';
                } else if (fee.tva_rate === 13) {
                    newAccountSuffix = '13';
                } else if (fee.tva_rate === 10) {
                    newAccountSuffix = '10';
                } else if (fee.tva_rate === 5.5) {
                    newAccountSuffix = '55';
                } else if (fee.tva_rate === 2.1) {
                    newAccountSuffix = '21';
                } else if (fee.tva_rate === 0) {
                    newAccountSuffix = '00';
                }

                if (newAccountSuffix) {
                    fee.account = fee.account.slice(0, -2) + newAccountSuffix;
                }
            })
        }

        const addExtraFees = () => {
            extraFees.value.push({
                designation: "",
                pack_unity: "",
                pack_number: 0,
                stock_name: "",
                quantity: 1,
                tva_rate: 20,
                account: "60110000020",
                accountBis: "60110000000",
                total_amount_ht: 0,
                total_amount_ttc: 0,
                registration: ""
            })
        }

        const removeExtraFees = (extraFee) => {
            var indexToDelete = -1;
            for (var i = 0; i < extraFees.value.length; i++) {
                if (extraFees.value[i].designation === extraFee.designation) {
                    indexToDelete = i;
                    break;
                }
            }
            if (indexToDelete !== -1) {
                extraFees.value.splice(indexToDelete, 1);
            }
        }

        //Validation des données
        const approveData = async () => {
            try {
                if(!verifyEntireInvoice().isBalanced){
                    confirmationMessage.value = "Les débits ne sont pas égaux aux crédits. La facture ne peut être validée.";
                    messageColor.value = 'red';
                    showMessage.value = true;
                    setTimeout(() => {
                        showMessage.value = false;
                    }, 5000);
                    return;
                }
                //Si le document est une facture définitive
                if (!isTemp.value) {
                    updateData();
                    return;
                }
                //Création du FEC
                var entries = accountingEntriesList.value;
                entries = entries.flat();
                let fec = entries.map(formatFecEntry).join('\n');
                //let fec2 = decaissementEntries.value.map(formatFecEntry).join('\n');
                //fec = fec + '\n' + fec2;
                //Ajout des données supplémentaire de la facture
                invoiceDatas.value.supplier_type = supplierType.value;
                invoiceDatas.value.marketplace_name = marketPlaceName.value;
                invoiceDatas.value.payment_method = invoicePaymentMethod.value;
                //Envoi des données à l'API
                var url = `${process.env.VUE_APP_BASE_URL}/invoiceValidation`;
                var response = await axios.post(url, {
                    invoiceDatas: invoiceDatas.value,
                    productsDatas: products.value,
                    tvaAccountsDatas: tvaAccounts.value,
                    extraFeesDatas: extraFees.value,
                    supplierName: stakeholderInfos.value.name,
                })

                //Envoi du FEC à l'API
                var url2 = `${process.env.VUE_APP_BASE_URL}/linkInvoiceToFec`;
                await axios.post(url2, {
                    fec: fec,
                    invoiceId: response.data.invoiceId
                })
                console.log("last_ha", lastHA.value)
                //Mise à jour du last_ha
                var url3 = `${process.env.VUE_APP_BASE_URL}/accounting_exercice/${companySelected.value.id}/${invoiceDatas.value.invoice_date}`;
                await axios.put(url3, {
                    last_ha: lastHA.value || "HA00000"
                })

                //Envoi de la facture à Zoho
                var zohoBody = {
                    stakeholderId: stakeholderInfos.value.id,
                    companyCode: companySelected.value.company_code,
                    companyId: companySelected.value.id,
                    stakeholderAccount: invoiceDatas.value.stakeholder_account,
                    stakeholderName: stakeholderInfos.value.name,
                    invoiceDate: invoiceDatas.value.invoice_date,
                    invoiceNumber: invoiceDatas.value.invoice_number,
                    fileName: fileName.value,
                    file: await convertBlobToBase64(pdfUrl.value)

                }
                var url3 = `${process.env.VUE_APP_BASE_URL}/invoiceToZoho`;
                await axios.post(url3, zohoBody);
                //Confirmation de la validation
                confirmationMessage.value = `Le document a été validé et archivé dans ArchiGED avec succès.`;
                messageColor.value = 'green'; // Vert pour le succès
                showMessage.value = true;
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);

            } catch (error) {
                console.log(error)
                confirmationMessage.value = 'Erreur lors de la validation du document.';
                messageColor.value = 'red'; // Rouge pour l'erreur
                showMessage.value = true;
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);

            }
        }

        const sendToEvoliz = async () => {
            try {
                isLoading.value = true;
                //Si la facture est un avoir
                if(invoiceDatas.value.document_type == "avoir"){
                    var url4 = `${process.env.VUE_APP_BASE_URL}/createCreditEvoliz`;
                    var productsList = products.value.map(product => {
                        return {
                            account: product.account,
                            total: product.total_amount_ttc
                        }
                    })
                    var postBody = {
                        supplierAccount: invoiceDatas.value.stakeholder_account,
                        supplierName: stakeholderInfos.value.name,
                        totalAmountTtc: invoiceDatas.value.total_amount_ttc,
                        creditNumber: invoiceDatas.value.invoice_number,
                        creditDate: invoiceDatas.value.invoice_date,
                        creditDeadline: invoiceDatas.value.due_date,
                        paymentMethod: invoicePaymentMethod.value,
                        products: productsList
                    }
                    var response4 = await axios.post(url4, postBody);
                }else{
                    // Convertir le PDF en base64
                const pdfBase64 = await convertBlobToBase64(pdfUrl.value);
                var url3 = `${process.env.VUE_APP_BASE_URL}/invoiceToEvoliz`;
                var response3 = await axios.post(url3, {
                    invoiceDatas: invoiceDatas.value,
                    productsDatas: products.value,
                    tvaAccountsDatas: tvaAccounts.value,
                    extraFeesDatas: extraFees.value,
                    supplierName: stakeholderInfos.value.name,
                    companySelected: companySelected.value,
                        pdfUrl: pdfBase64
                    })

                }
                
                //Confirmation de la validation
                confirmationMessage.value = `Le document a été envoyé dans ArchiPilot avec succès.`;
                messageColor.value = 'green'; // Vert pour le succès
                showMessage.value = true;
                isLoading.value = false
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);
            } catch (error) {
                console.log(error);
                confirmationMessage.value = 'Erreur lors de l\'envoi du document dans ArchiPilot.';
                messageColor.value = 'red'; // Rouge pour l'erreur
                showMessage.value = true;
                isLoading.value = false
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);
            }
        }

        //Mise a jout des données de la facture dévinitive
        const updateData = async () => {
            try {
                console.log("Début de la mise à jour des données...");

                // Ajout des données supplémentaires de la facture
                console.log("Ajout des données supplémentaires à la facture...");
                invoiceDatas.value.supplier_type = supplierType.value;
                invoiceDatas.value.marketplace_name = marketPlaceName.value;
                invoiceDatas.value.payment_method = invoicePaymentMethod.value;

                // Génération du FEC
                console.log("Génération des écritures comptables pour le FEC...");
                var entries = accountingEntriesList.value;
                entries = entries.flat();
                let fec = entries.map(formatFecEntry).join('\n');

                // Modification de la facture
                var url = `${process.env.VUE_APP_BASE_URL}/invoice`;
                console.log("Envoi des données de la facture à l'API:", url);
                var response = await axios.put(url, {
                    invoiceDatas: invoiceDatas.value,
                    productsDatas: products.value,
                    tvaAccountsDatas: tvaAccounts.value,
                    fecEntries: fec
                });
                console.log("Réponse de l'API pour la modification de la facture:", response.data);


                // Confirmation de la modification
                confirmationMessage.value = `La facture a bien été modifiée. N'oubliez pas de mettre à jour la facture dans Archipilot.`;
                messageColor.value = 'green'; // Vert pour le succès
                showMessage.value = true;
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);

            } catch (error) {
                console.log("Erreur lors de la mise à jour des données:", error);
                confirmationMessage.value = 'Erreur lors de la modification du document.';
                messageColor.value = 'red'; // Rouge pour l'erreur
                showMessage.value = true;
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);
            }
        }


        async function convertBlobToBase64(blobUrl) {
            const response = await fetch(blobUrl);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extraire les données base64
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }

        const deleteInvoiceTemp = async () => {
            try {
                const invoiceId = invoiceDatas.value.id;
                console.log(`Suppression de la facture temporaire avec l'ID : ${invoiceId}`);

                isLoading.value = true; // Activer l'indicateur de chargement

                const response = await axios.delete(`${process.env.VUE_APP_BASE_URL}/invoiceTemp/${invoiceId}`);
                console.log("Réponse de l'API pour la suppression de la facture temporaire :", response.data);

                // Mise à jour de l'interface utilisateur
                confirmationMessage.value = 'Le document a été supprimé avec succès.';
                messageColor.value = 'green';
                showMessage.value = true;

                // Réinitialiser les données de la facture
                invoiceDatas.value = {};
                products.value = [];
                extraFees.value = [];
                tvaAccounts.value = [];
                pdfUrl.value = null;


            } catch (error) {
                console.error("Erreur lors de la suppression du document.", error);

                // Afficher un message d'erreur détaillé
                confirmationMessage.value = `Erreur lors de la suppression de la facture : ${error.response?.data?.message || error.message}`;
                messageColor.value = 'red';
                showMessage.value = true;
            } finally {
                isLoading.value = false; // Désactiver l'indicateur de chargement

                // Cacher le message après un certain délai
                setTimeout(() => {
                    showMessage.value = false;
                }, 5000);
            }
        }

        const confirmDelete = () => {
            if (isTemp.value) {
                if (confirm('Êtes-vous sûr de vouloir supprimer cette facture temporaire ?')) {
                    deleteInvoiceTemp();
                }
            } else {
                if (confirm('Êtes-vous sûr de vouloir supprimer cette facture définitive ?')) {
                    deleteInvoice();
                }
            }
        }

        const deleteInvoice = async () => {
            try {
                const invoiceId = invoiceDatas.value.id;
                console.log(`Suppression de la facture définitive avec l'ID : ${invoiceId}`);

                const response = await axios.delete(`${process.env.VUE_APP_BASE_URL}/invoice/${invoiceId}`);
                console.log("Réponse de l'API pour la suppression de la facture définitive :", response.data);
                confirmationMessage.value = 'La facture définitive a été supprimée avec succès.';
                messageColor.value = 'green';
                showMessage.value = true;
                setTimeout(() => {
                    showMessage.value = false;
                    confirmationMessage.value = '';
                }, 5000);
            } catch (error) {
                console.error("Erreur lors de la suppression de la facture définitive:", error);

                confirmationMessage.value = `Erreur lors de la suppression de la facture : ${error.response?.data?.message || error.message}`;
                messageColor.value = 'red';
                showMessage.value = true;
                setTimeout(() => {
                    showMessage.value = false;
                    confirmationMessage.value = '';
                }, 5000);
            }
        }

        const getTvaAccountPlaceholder = (tvaRate) => {
            //Transformer le taux en tva_taux avec les cas particulier
            if (tvaRate.toString().includes(".") || tvaRate.toString().includes(",")) {
                //Transformer le . ou , en _
                tvaRate = tvaRate.toString().replace(".", "_");
                tvaRate = tvaRate.toString().replace(",", "_");
            }
            var tvaRateString = `tva_${tvaRate}`;
            if (tvaRateString == "tva_2_1") tvaRateString = "tva_2_10";
            var result = ""
            tvaAccounts.value.map(account => {
                var keys = Object.keys(account);
                if (keys.includes(tvaRateString)) {
                    result = account.account1
                }
            })

            return result;
        };

        async function downloadFec() {
            try {
                //Récupération des factures validées
                let url = `${process.env.VUE_APP_BASE_URL}/validatedInvoices/${companySelected.value.id}`;
                let response = await axios.get(url);
                let invoices = response.data;
                console.log(invoices);

                //Récupération de la dernière session (session la plus élevée)
                let lastInvoices = invoices.filter(invoice => invoice.session_number === Math.max(...invoices.map(invoice => invoice.session_number)));
                console.log(lastInvoices);

                // Ligne d'en-tête du FEC
                const fecHeader = "JournalCode;JournalLib;EcritureNum;EcritureDate;CompteNum;CompteLib;CompAuxNum;CompAuxLib;PieceRef;PieceDate;EcritureLib;Debit;Credit;EcritureLet;DateLet;ValidDate;Montantdevise;Idevise\n";

                //Pour chaque facture de la dernière session, on récupère les fec
                var fec = fecHeader;
                for (let invoice of lastInvoices) {
                    let url2 = `${process.env.VUE_APP_BASE_URL}/invoiceFec/${invoice.id}`;
                    let response2 = await axios.get(url2);
                    console.log(response2.data);
                    //Ajout au fec avec un retour à la ligne sauf pour la première facture et pour la dernière
                    if (response2.data.length > 0) {
                        fec += response2.data[0].fec + "\n";
                    }
                }

                //Création du fichier à partir du fec
                // Créer un objet Blob avec le contenu du fichier
                let blob = new Blob([fec], { type: 'text/plain' });

                // Créer un lien pour le téléchargement
                let downloadLink = document.createElement("a");
                downloadLink.href = window.URL.createObjectURL(blob);
                //Création du nom en fonction de la date
                let date = new Date();
                let name = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
                downloadLink.download = `export_${name}.txt`;

                // Simuler un clic sur le lien pour déclencher le téléchargement
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Nettoyer et supprimer le lien
                document.body.removeChild(downloadLink);

            } catch (error) {
                console.log(error);
            }
        }

        const downloadFec2 = async () => {
            try {
                const companyId = companySelected.value.id;
                const url = `${process.env.VUE_APP_BASE_URL}/fecExport/${companyId}`;

                // Définir les dates de l'exercice comptable
                const body = {
                    accounting_year: {
                        exercice_start: "2024-08-01",
                        exercice_end: "2025-12-31"
                    }
                };

                // Faire l'appel API
                const response = await axios.post(url, body, { responseType: 'blob' });

                // Extraire le nom du fichier de l'en-tête de la réponse
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'export_FEC.txt'; // Nom par défaut
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/i);
                    if (fileNameMatch.length === 2)
                        fileName = fileNameMatch[1];
                }

                // Créer un objet Blob avec la réponse
                const blob = new Blob([response.data], { type: 'text/plain' });

                // Créer un lien pour le téléchargement
                const downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = fileName;

                // Simuler un clic pour déclencher le téléchargement
                document.body.appendChild(downloadLink);
                downloadLink.click();

                // Nettoyer
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadLink.href);

                console.log("Téléchargement du FEC réussi");
            } catch (error) {
                console.error("Erreur lors du téléchargement du FEC:", error);
            }
        };

        //Gestion de l'animation
        const isScrolledPast = ref(false);
        const initialPosition = ref(null);

        const handleScroll = () => {
            if (!initialPosition.value) {
                const element = document.querySelector('.verificationResult');
                if (element)
                    initialPosition.value = element.getBoundingClientRect().top + window.scrollY;
            }

            const scrollPosition = window.scrollY;
            isScrolledPast.value = scrollPosition > initialPosition.value;
        };

        //Gestion de l'ajout d'un nouveau compte d'achat
        const isAddingNewAccount = ref(false);
        const newPurchaseAccount = ref({
            account: '',
            designation: '',
            description: '',
            tvaAccount: ''
        });
        const toggleNewAccountForm = (product) => {
            isAddingNewAccount.value = !isAddingNewAccount.value;
            if (isAddingNewAccount.value && product) {
                newPurchaseAccount.value.description = product.designation;
            }
            newPurchaseAccount.value.tvaAccount = getTvaAccountPlaceholder(product.tva_rate);
        };

        const saveNewPurchaseAccount = async (product) => {
            try {
                isSaving.value = true;
                // Logique pour enregistrer le nouveau compte d'achat
                console.log('Nouveau compte ajouté:', newPurchaseAccount.value);

                //Récupération des services de l'utilisateur
                var companies = store.state.companies;
                if (companies && companies.length > 0) {
                    var company = companies.filter(company => Number(company.taac_company_id) === Number(companySelected.value.id));
                    console.log(company);
                }
                if (store.getters.currentUser.role === "super_admin" || (company && company[0].services.some(service => service.service_id === "Q6f1PnZ1tx324KeeZAwF"))) {
                    console.log("Service Archipilot trouvé");
                    // Envoie de la requête avec le companyId
                    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/create-account`, {
                        companyId: companySelected.value.id,
                        evolizCompanyId: companySelected.value.evoliz_id,
                        accountData: newPurchaseAccount.value,
                        tvaRatesSelected: tvaRatesSelected.value
                    });
                    console.log('Nouveau compte ajouté dans Evoliz et dans pcg_special:', response.data);
                    productMessage.value = 'Le compte d\'achat et sa classification ont été ajoutés avec succès.';
                    productMessageColor.value = 'green';

                } else {
                    console.log("Pas de service Archipilot");
                    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/pcg-special`, {
                        accounting_account: newPurchaseAccount.value.account,
                        label: newPurchaseAccount.value.designation,
                        classification_code: '',
                        description: newPurchaseAccount.value.description,
                        company_id: companySelected.value.id
                    });
                    console.log('Nouveau compte ajouté dans pcg_special:', response.data);
                    productMessage.value = 'Le compte d\'achat a été ajouté avec succès.';
                    productMessageColor.value = 'green';
                }
                await fetchAllAccounts();

                // Reset du formulaire
                newPurchaseAccount.value = {
                    account: '',
                    designation: '',
                    description: '',
                    tvaAccount: ''
                };
                isAddingNewAccount.value = false;
                isSaving.value = false;
                setTimeout(() => {
                    productMessage.value = '';
                    productMessageColor.value = '';
                }, 5000);

            } catch (error) {
                console.error('Erreur lors de l\'enregistrement du nouveau compte:', error);
                const errorMessage = error.response?.data?.message || error.message;
                productMessage.value = errorMessage;
                productMessageColor.value = 'red';
                isAddingNewAccount.value = false;
                isSaving.value = false;
                setTimeout(() => {
                    productMessage.value = '';
                    productMessageColor.value = '';
                }, 5000);
            }
        };


        //Gestion des acomptes
        const getIsDepositFinal = async () => {
            try {
                var url = `${process.env.VUE_APP_BASE_URL}/isDepositFinalInvoice`;
                var response = await axios.post(url, { invoiceText: otherTable.value.fullText });
                console.log("reponse isDepositFinal")
                console.log(response.data)
                invoiceDatas.value["depositsAmount"] = response.data.depositsAmount
                return response.data.isFinalInvoice
            } catch (error) {
                console.log(error);
            }
        }
        const companyStakeholder = ref([]);
        //Gestion des comptes fournisseurs
        const fetchCompanyStakeholder = async () => {
            console.log('Récupération des fournisseurs')
            try {
                var url = `${process.env.VUE_APP_BASE_URL}/company_stakeholders/${companySelected.value.id}`;
                var response = await axios.get(url);
                console.log("reponse companyStakeholder")
                console.log(response.data)
                // Trier les données par nom du fournisseur
                response.data.sort((a, b) => a.description.localeCompare(b.description));
                companyStakeholder.value = response.data
            } catch (error) {
                console.log(error);
            }
        }

        //Gestion de la conversion HT -> TTC
        // Gestion de la conversion HT -> TTC
        const convertHtToTtc = () => {
            products.value.forEach(product => {
                //Calcul du nouveau HT
                const tvaMultiplier = 1 + product.tva_rate / 100;
                const newHt = (product.total_amount_ht / tvaMultiplier).toFixed(2);
                //On place le HT dans le TTC
                product.total_amount_ttc = product.total_amount_ht
                //On place le nouveau HT dans le produit
                product.total_amount_ht = parseFloat(newHt).toFixed(2);
            });
        };

        // Gestion de la conversion TTC -> HT
        const convertTtcToHt = () => {
            products.value.forEach(product => {
                //Calcul du nouveau HT
                const tvaMultiplier = 1 + product.tva_rate / 100;
                //On place le nouveau HT dans le produit
                product.total_amount_ht = parseFloat(product.total_amount_ttc).toFixed(2);
                //On calcule le nouveau total TTC
                const newTtc = (product.total_amount_ht * tvaMultiplier).toFixed(2);
                product.total_amount_ttc = parseFloat(newTtc).toFixed(2);
            })
        };

        //Gestion des données liées à l'exercice
        const fetchAccountingExerciceData = async () => {
            try {
                var url = `${process.env.VUE_APP_BASE_URL}/accounting_exercice/${companySelected.value.id}/${invoiceDatas.value.invoice_date}`;
                var response = await axios.get(url);
                console.log("reponse accountingExerciceData")
                console.log(response.data)
                var newHA = Number(response.data.last_ha.slice(-6)) + 1;
                lastHA.value = "HA" + newHA.toString().padStart(6, '0');
            } catch (error) {
                console.log(error);
            }
        }

        const updateStakeholderAccount = async () => {
            var newStakeholder = companyStakeholder.value.filter(stakeholder => stakeholder.account === invoiceDatas.value.stakeholder_account)
            console.log("newStakeholder", newStakeholder)
            invoiceDatas.value.stakeholder_id = newStakeholder[0].stakeholder_id
            stakeholderInfos.value = await getStakeholderInfos (newStakeholder[0].stakeholder_id)
        }
        
        //Gestion des taux de TVA sur immobilisations
        const findTvaIndex = (tvaKey) => {
            console.log("tvaAccounts", tvaAccounts.value)
            // Utiliser findIndex pour trouver l'index de l'objet qui contient la clé spécifiée
            const index = tvaAccounts.value.findIndex(account => account.hasOwnProperty(tvaKey));
            console.log("Index trouvé:", index);
            return index;
        }


        onMounted(async () => {
            window.addEventListener('scroll', handleScroll);

        });

        

        // Watch pour surveiller les changements de companySelected
        watch(companySelected, async (newVal) => {
            if (newVal && newVal.evoliz_id) {
                console.log("Company selected has been updated:", newVal);
                await fetchAccounts();
            }
        }, { immediate: true });

        onBeforeUnmount(() => {
            window.removeEventListener('scroll', handleScroll);
        });

        return {
            handleSelect,
            loading,
            pdfUrl,
            isTemp,
            isImmo,
            changeIsTemp,
            accounts,
            companySelected,
            //Données d'une Facture
            invoiceDatas,
            stakeholderInfos,
            registrationValue,
            invoicePaymentMethod,
            supplierType,
            marketPlaceName,
            country,
            //Gestion des produits
            products,
            addProduct,
            removeProduct,
            duplicateAccount,
            //Gestion des écritures comptables
            accountingEntriesComputed,
            //Gestion des comptes
            filterAccounts,
            filteredAccounts,
            //Gestion de la TVA
            tvaAccounts,
            //Gestion des vérifications
            verifyEntireInvoice,
            verifyAccountingEntries,
            //Gestion des immo
            isOneImmo,
            
            handleOneImmo,
            //Gestion des frais annexes
            extraFees,
            addExtraFees,
            removeExtraFees,
            //Validation des données
            approveData,
            sendToEvoliz,
            updateData,
            isLoading,
            validatedInvoices,
            confirmationMessage,
            showMessage,
            messageColor,
            deleteInvoiceTemp,
            confirmDelete,
            downloadFec,
            //Gestion de l'animation
            isScrolledPast,
            //Gestion de l'ajout d'un nouveau compte d'achat
            isAddingNewAccount,
            newPurchaseAccount,
            toggleNewAccountForm,
            saveNewPurchaseAccount,
            units,
            getTvaAccountPlaceholder,
            showTvaWarning,
            isSaving,
            //gestion des comptes fournisseurs
            companyStakeholder,
            //Gestion de la conversion HT -> TTC
            convertHtToTtc,
            convertTtcToHt,
            //Type de paiements
            paymentMethods,
            //Liste des marketplaces
            marketplaces,
            downloadFec2,
            productMessage,
            productMessageColor,
            updateStakeholderAccount,
            openNewSupplierDialog,
            countryList,
            //Liste des taux de TVA
            tvaRates,
            tvaRatesSelected,
            //Gestion de l'ajout d'un nouveau fournisseur
            fetchCompanyStakeholder,
            //Gestion de l'état de la facture
            invoiceState,
            //Gestion des taux de TVA sur immobilisations
            findTvaIndex,
        };
    }
};
</script>

<style scoped>
.container2{
    display: flex;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.form {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.preview {
    width: 50%;
    margin-left: 1%
}


.sticky-wrapper {
    width: 100%;
}

.invoicesDatasForm {
    padding: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.supplier-account-select {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.form-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

}

.form-row label {
    flex: 0 0 200px;
    /* Largeur fixe pour les labels */
    margin-right: 10px;
}

.form-input {
    flex: 1;
    padding: 5px;
    border: 1px solid #7209B7;
    border-radius: 5px;
}

h5 {
    margin-bottom: 20px;
}

.productsForm {
    padding: 1em;
    border: 1px solid #7209B7;
    border-radius: 5px;
    margin-top: 0.25em;
}

.productsForm h5 {
    margin-bottom: 1em;
}

.productsList {
    padding: 1em;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    margin-bottom: 1em;
}

.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

label {
    width: 150px;
    font-weight: bold;
    margin-right: 1em;
}

input,
select {
    flex: 1;
    padding: 0.5em;
    border: 1px solid #7209B7;
    border-radius: 5px;
}

button {
    margin-top: 0.5em;
}

.verificationResult .icon {
    display: inline-block;
    margin-right: 0.5em;
}

input[type="text"],
input[type="number"],
.custom-select {
    flex: 1;
    padding: 5px;
    border: 1px solid #7209B7;
    border-radius: 5px;

}

.tvaForm {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}



.table-container {
    margin: 20px 0;
    overflow-x: auto;
    /* Ajout du défilement horizontal */
}

.table-container h5 {
    margin-bottom: 15px;
    font-size: 1.5em;
    color: #333;
    border: 1px solid #7209B7;
    border-radius: 5px;
    background-color: white;
}

table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

thead {
    background-color: #f4f4f4;
}

th,
td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #7209B7;
}

th {
    background-color: #f8f8f8;
    font-weight: bold;
    color: #555;
}

tr:hover {
    background-color: #f1f1f1;
}

tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

tbody td {
    color: #555;
}

.verificationResult {
    display: flex;
    align-items: center;
    margin-top: 10px;
    transition: all 0.5s ease-in-out;

}

.icon.success {
    color: #31cb00;
}

.icon.error {
    color: #ff220c;
}

.icon {
    font-size: 16px;
    margin-right: 10px;
}

.sticky-wrapper {
    position: sticky;
    top: 0;
}

.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.button-group {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.btn {
    width: 200px;
}

.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.confirmation-message {
    margin-top: 10px;
    font-size: 1.2em;
    text-align: center;
}

.validated-invoices {
    font-size: 1.1em;
    margin-top: 10px;
    font-weight: 600;
    color: #31cb00;
}

.fixed-rectangle {
    position: fixed;
    top: 5px;
    right: 10px;
    width: 300px;
    padding: 10px;
    background-color: white;
    border: 1px solid #7209B7;
    font-size: 16px !important;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.success-message {
    color: #31cb00;
}

.error-message {
    color: #ff220c;
}

.head {
    font-family: Righteous;
}
</style>