<template>
  <div class="app">
    <!-- Gérer le cas où l'utilisateur est en cours de chargement -->
    <div v-if="isLoadingUser" class="loading-screen">
      Chargement...
    </div>
    <!-- Affiche le router-view si l'utilisateur est chargé -->
    <div v-else class="w-full h-full">
      <ArchiPilotHeader v-if="user" />
      <router-view v-slot="{ Component, route }">
        <Transition name="nicee" mode="out-in">
          <div :key="route.params.id" class="w-full h-full">
            <keep-alive>
              <component :is="Component"></component>
            </keep-alive>
          </div>
        </Transition>
      </router-view>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from 'vuex';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, getDocs, collection, query, where } from "firebase/firestore"; // Firestore
import Menu from "@/components/Menu.vue";
import ArchiPilotHeader from "@/archiPilot/components/ArchiPilotHeader.vue";

// Gestion de l'état du menu
const menu = ref(false);
const store = useStore();

// Obtenir les données utilisateur depuis Vuex
const user = computed(() => store.getters.currentUser);
const isLoadingUser = ref(true);



// Récupérer les infos de l'utilisateur via Firebase Auth et Firestore
const auth = getAuth();
const db = getFirestore();

onMounted(() => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // Récupérer les détails utilisateur dans Firestore en utilisant l'UID
      try {
        const userRef = doc(db, "users", user.uid);  // Suppose que tes utilisateurs sont dans une collection 'users'
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          // Si les détails de l'utilisateur existent, les sauvegarder dans Vuex
          const userData = { ...user, ...userSnap.data() };
          store.dispatch('setUser', userData);
        } else {
          console.error("Aucun détail utilisateur trouvé dans Firestore");
        }
        //Récupérer les informations sur les companies liées à l'utilisateur
        const q = query(collection(db, "companies"), where("user_id", "==", user.uid));

        const querySnapshot = await getDocs(q);
        var companiesData = []
        querySnapshot.forEach((doc) => {
          companiesData.push(doc.data())
        });

        if (companiesData.length > 0) {
          store.dispatch('setCompanies', companiesData);
        } else {
          console.log("Aucune entreprise trouvée pour cet utilisateur");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des détails utilisateur :", error);
      }
    } else {
      // Si l'utilisateur est déconnecté, réinitialiser l'utilisateur dans Vuex
      store.dispatch('setUser', null);
    }
    isLoadingUser.value = false; // Fin du chargement
  });
});
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

body {
  font-family: Righteous !important;
  background-color: #4ccaf01c;
}

h3 {
  color: #595CCE;
}

.app {

  width: 100%;
  height: 100%;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #595CCE;
}

.nicee-enter-active {
  animation: opacityFromZero 0.4s ease-out;
}

.nicee-leave-active {
  animation: opacityFromZero 0.4s ease-out reverse;
}

@keyframes opacityFromZero {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
