<template>
    <table class="data-table">
        <tr v-for="(value, key) in data" :key="key" class="data-row">
            <td class="data-key">{{ key }}</td>
            <td v-if="typeof value === 'object' && value !== null" class="data-value">
                <recursive-display :data="value" />
            </td>
            <td v-else class="data-value">
                {{ value }}
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    name: 'RecursiveDisplay',
    props: {
        data: {
            type: [Object, Array],
            required: true,
        },
    },
};
</script>

<style scoped>
.data-table {
    width: 100%;
    border-collapse: collapse;
}

.data-row:nth-child(even) {
    background-color: #f2f2f2;
}

.data-key, .data-value {
    border: 1px solid #ddd;
    padding: 8px;
}

.data-key {
    font-weight: bold;
}
</style>