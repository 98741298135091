<template lang="">
    <div class="w-full h-full p-4 text-lg ">
        <h2 class="font-bold text-[#3A0CA3]"> Création Compte Client </h2>
        <br>
        <div class="w-[60%] m-auto border-solid border-4 rounded border-[#7209B7] p-2 bg-[#FFFFFF] text-[#3A0CA3] text-[0.9rem] font-sans">
            <p class="font-bold mb-2"> <q-icon name="info"/> INFORMATIONS</p>
            <p> Bienvenu dans le module dédié à la gestion de vos données clients ! Créez automatiquement vos comptes clients en conformité avec la réforme de la facturation électronique et importez les données de vos anciens logiciels en un clic.</p>
        <br>
            <p> Vous pourrez modifier les données saisies dans cette interface directement depuis votre <a class="font-bold text-[#F72585]" href="https://www.evoliz.com/login.php">Logiciel de Gestion Commerciale</a> en vous rendant à l'onglet "Client" et en cliquant sur le client concerné.</p>
        </div>
        <br><br><br>
        <div class='flex justify-around'>
            <button @click="clientFormModal =!clientFormModal" type="button" class="inline-flex items-center justify-center w-[30%] text-[#7209B7] hover:text-white border border-[#7209B7] hover:bg-[#7209B7] focus:ring-4 focus:outline-none focus:ring-[#E1D4F4] font-medium rounded-lg text-base px-5 py-2.5 text-center me-2 mb-2"><q-icon name="note_add"/>Créer un nouveau Compte Client</button>
            <button @click="addressFormModal =!addressFormModal" type="button" class="inline-flex items-center justify-center w-[30%] text-[#7209B7] hover:text-white border border-[#7209B7] hover:bg-[#7209B7] focus:ring-4 focus:outline-none focus:ring-[#E1D4F4] font-medium rounded-lg text-base px-5 py-2.5 text-center me-2 mb-2"><q-icon name="upload_file"/>Ajouter une nouvelle adresse</button>
        </div>
        <div>
            <ArchiPilotClientForm v-if="clientFormModal" @closeModal="clientFormModal = false"/>
            <ArchiPilotAddressForm v-if="addressFormModal" @closeModal="addressFormModal = false"/>
        </div>
    </div>
</template>
<script>
import ArchiPilotClientForm from './ArchiPilotClientForm.vue';
import ArchiPilotAddressForm from './ArchiPilotAddressForm.vue';
import {ref} from "vue";
export default {
    components: {ArchiPilotClientForm, ArchiPilotAddressForm},
    setup(){
       var clientFormModal=ref(false) 
       var addressFormModal=ref(false)
        return{
            clientFormModal,
            addressFormModal
         }
}
    
}
</script>
<style lang="">
    
</style>