<template>
  <div class="folder">
    <h3 style="text-align:center; font-size:25px"><b>GÉSTION ÉLÉCTRONIQUE DE DOCUMENT</b></h3>

        <Breadcrumbs />
<span style="font-size: 1.5em">{{ currentFolderName}}</span>
    <br />
    <br />
    <div style="display:flex;">
    <q-btn-dropdown
      icon="file_upload"
      class="q-mr-sm"
      color="orange-14"
      label="Importer"
    >
      <q-list>
        <q-item clickable v-close-popup @click="onItemClick">
          <q-item-section>
            <input
              class="uploadFileButton"
              name="uploadFile"
              style="max-width: 300px"
              label="Fichier"
              @input="sendFile"
              ref="file"
              type="file"
              multiple
            />
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup @click="onItemClick">
          <q-item-section>
            <q-item-label>Dossiers</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    
      <q-btn style="margin-right: 10px" color="orange-14" @click="showModal"><q-icon name="add" />Créer</q-btn>
      <q-btn color="orange-14" v-if="isSupplier" @click="AddExercice" ><q-icon name="add" />Ajouter un exercice</q-btn>
    </div>
   

    <div class="foldersList">
      <div
        class="emptyFolder"
        v-if="folders && folders.length === 0 && files && files.length === 0"
      >
        <hr />
        <p>Ce dossier est vide</p>
        <hr />
      </div>

      <div v-for="folder in folders" :key="folder.id">
        <div class="folderItem">
          <q-item
            class="item"
            clickable
            v-ripple
            @click="changeFolder(folder.id, folder.name, folder.id_parent)"
          >
            <q-item-section avatar>
              <q-icon name="folder" class="text-blue-grey-6" size="xl"></q-icon>
            </q-item-section>
            <q-item-section> {{ folder.name }}</q-item-section>
          </q-item>
          <div>
            <q-btn color="orange-1" class="text-black" label="OPTIONS">
              <q-menu>
                <q-list style="min-width: 100px">
                  <q-item clickable v-close-popup @click="renameFolder(folder)">
                    <q-item-section>Renommer</q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="deleteFolder(folder)">
                    <q-item-section>Supprimer</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
        <q-separator />
      </div>
    </div>
    <div class="filesList">
      <div v-for="file in files" :key="file.id">
        <div class="fileItem">
          <q-item
            class="item"
            dense
            justify-start
            clickable
            v-ripple
            @click="preview(file.name)"
          >
            <q-item-section avatar>
              <q-icon
                name="insert_drive_file"
                class="text-teal"
                size="xl"
              ></q-icon>
            </q-item-section>
            <q-item-section> {{ file.name }}</q-item-section>
           <!-- <q-item-section side v-if="file && file.status.cashIn" style="color: teal"
              ><q-icon name="circle" style="color: teal"></q-icon
              >Encaissée</q-item-section
            >
            <q-item-section side  v-if="file && file.status.litigation" style="color: red"
              ><q-icon name="circle" style="color: red"></q-icon
              >Contentieux</q-item-section
            >
            <q-item-section side  v-if="file && file.status.compt" style="color: orange"
              ><q-icon name="circle" style="color: orange"></q-icon
              >Comptabilisée</q-item-section
            >-->
          </q-item>
          <div class="fileOptions">
            <q-btn color="orange-1" class="text-black" label="OPTIONS">
              <q-menu>
                <q-list style="min-width: 100px">
                  <q-item clickable v-close-popup @click="renameFile(file)">
                    <q-item-section>Renommer</q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="deleteFile(file)">
                    <q-item-section>Supprimer</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
           <!-- <q-btn-dropdown color="primary" label="Statut">
              <q-list>
                <q-checkbox
                  v-model="file.status.cashIn"
                  label="Encaissée"
                  color="teal"
                  @update:model-value="handleStatus(file)"
                />
                <q-checkbox
                  v-model="file.status.compt"
                  label="Comptabilisée"
                  color="orange"
                  @update:model-value="handleStatus(file)"
                />
                <q-checkbox
                  v-model="file.status.litigation"
                  label="Contentieux"
                  color="red"
                  @update:model-value="handleStatus(file)"
                />
              </q-list>
            </q-btn-dropdown> -->
          </div>
        </div>

        <q-separator />
      </div>
    </div>
    <Modal
      v-if="modal"
      @close="modal = false"
      :id="id"
      @folderAdded="folderAdded"
    />
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Renommer</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input
            dense
            v-model="rename"
            autofocus
            @keyup.enter="prompt = false"
          />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Annuler" v-close-popup />
          <q-btn flat label="Renommer" v-close-popup @click="sendRename" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { ref, watch, computed } from "vue";
import axios from "axios";
import router from "@/router";
import Modal from "@/components/CreateFolderModal";
import { useStore } from "vuex";
import { getCurrentInstance } from "vue";
import Breadcrumbs from "@/components/Breadcrumbs"

export default {
  components: {
    Modal,
    Breadcrumbs
  },
  setup() {
    const internalInstance = getCurrentInstance();
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const store = useStore();
    const route = useRoute();
    let modal = ref(false);
    let folders = ref([]);
    let id = ref(null);
    let files = ref([]);
    let showing = ref(false);
    let prompt = ref(false);
    let rename = ref(null);
    let renameId = ref(null);
    let oldFileName = ref(null);
    let isFile = ref(false);
    let supplierFolderId = ref();
    let isSupplier = computed(()=>{
      if(supplierFolderId.value == currentParent.value){
        return true;
      }else{
        return false;
      }
    })
    let currentFolderName = computed(()=>{
      return store.state.currentFolderName;
    })
    let currentRootFolderName = computed(()=>{
      return store.state.currentRootFolderName;
    })
    let currentParent = computed(()=>{
      return store.state.currentFolderParent;
    })

    emitter.on("clientChanged", () => {
      getResources();
    });
    emitter.on("returnHomeFile", ()=>{
      getResources();
      router.push({ path: "/ged" });

    })
    const changeFolder = (navId, name, id_parent) => {
      router.push({ path: "/folder/" + navId });
      store.commit("setCurrentFolder", navId);
      emitter.emit("currentFolder", { name: name, id: navId });
      store.commit("setCurrentParent", id_parent)

      //store.commit("setCurrentFolderName", name)
      
      getResources();
    };
    const getSubFolder = async () => {
      try {
        let response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/folder/` +
          id.value +
          "/" +
          store.state.currentClient, {timeout: 5000}
      );
      folders.value = response.data;
      } catch (error) {
        console.log(error); 
      }
      

    };
    const renameFolder = (folder) => {
      renameId.value = folder.id;
      rename.value = folder.name;
      prompt.value = true;
    };
    const deleteFolder = async (folder) => {
      try {
        if (window.confirm("Etes-vous sur de vouloir supprimer cet élément?")) {
          let url = `${process.env.VUE_APP_BASE_URL}/folder/${folder.id}`;
          let response = await axios.delete(url);
          if (response.status == 200) getSubFolder();
        }
      } catch (error) {
        console.log(error);
      }
    };
    const renameFile = (file) => {
      isFile.value = true;
      oldFileName.value = file.name;
      renameId.value = file.id;
      rename.value = file.name;
      prompt.value = true;
    };
    const deleteFile = async (file) => {
      try {
        if (window.confirm("Etes-vous sur de vouloir supprimer cet élément?")) {
          let url = `${process.env.VUE_APP_BASE_URL}/file/${file.id}`;
          let response = await axios.delete(url);
          if (response.status == 200) getFiles();
        }
      } catch (error) {
        console.log(error);
      }
    };
    const handleStatus = async (file) => {
      let status = {
        "cashIn": file.status.cashIn,
        "litigation": file.status.litigation,
        "compt": file.status.compt,
      };
      let url = `${process.env.VUE_APP_BASE_URL}/filestatus/${file.id}`;
      let response = await axios.put(url, status);
      if(response.status == 200)
        getFiles();
    };

    const AddExercice = async () =>{
      try {
        let postBody = {
          name: currentFolderName.value,
          idClient: store.state.currentClient,
          date: new Date().getFullYear()
        }
        let url = `${process.env.VUE_APP_BASE_URL}/exercice`;
        await axios.post(url, postBody);

      } catch (error) {
        console.log(error);
      }
    }

    const sendRename = async () => {
      try {
        if (isFile.value == true) {
          isFile.value = false;
          let url = `${process.env.VUE_APP_BASE_URL}/file/${renameId.value}`;
          let response = await axios.put(url, {
            newName: rename.value,
            oldName: oldFileName.value,
          });
          if (response.status == 200) {
            getFiles();
          }
        } else {
          let url = `${process.env.VUE_APP_BASE_URL}/folder/${renameId.value}`;
          let response = await axios.put(url, { newName: rename.value });
          if (response.status == 200) {
            getSubFolder();
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getFiles = async () => {
      try {
          let response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/files/` +
          id.value +
          "/" +
          store.state.currentClient, {timeout: 5000}
      );
      files.value = response.data;
      } catch (error) {
        console.log(error)
      }
  
    };
    const showModal = async () => {
      modal.value = true;
    };
    const folderAdded = async () => {
      emitter.emit("folderAdded");
      modal.value = false;
      getSubFolder();
    };
    const fileAdded = async () => {
      getFiles();
    };
    const getSupplierFolderId = async ()=>{
      try {
        let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/supplierFolderName/${store.state.currentClient}`  )
        supplierFolderId.value = response.data[0].id;
          
       } catch (error) {
        console.log(error)
      }
    }
    const getResources = async () => {
      id.value = null;
      files.value = null;
      folders.value = null;
      id.value = route.params.id;
      getSubFolder();
      getFiles();
      getSupplierFolderId();
      

    };
    const sendFile = async (e) => {
      var files = e.target.files;
      try {
        for(const file of files){
          let name = file.name;
          let url = `${process.env.VUE_APP_BASE_URL}/fileData`;
          let response = await axios.post(url, {
            method: "post",
            data: {
              name: name,
              id_folder: id.value,
              id_client: store.state.currentClient,
            },
          });
          let formData = new FormData();
          formData.append("file", file);
          let url2 = `${process.env.VUE_APP_BASE_URL}/file`;
          let response2 = await axios.post(url2, formData);
        }
        fileAdded();
      } catch (err) {
        console.log(err);
      }
    };
    const preview = (fileName) => {
      router.push({ path: "/preview/" + fileName });
    };
    onMounted(getResources);
    watch(route, () => {
      getResources();
    });
    return {
      folders,
      modal,
      id,
      showModal,
      folderAdded,
      changeFolder,
      getFiles,
      sendFile,
      files,
      showing,
      renameFolder,
      prompt,
      rename,
      renameId,
      sendRename,
      deleteFolder,
      preview,
      renameFile,
      deleteFile,
      handleStatus,
      currentFolderName,
      currentRootFolderName,
      AddExercice,
      currentParent,
      supplierFolderId,
      getSupplierFolderId,
      isSupplier
    };
  },
};
</script>

<style>
.folderItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.fileItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.item {
  flex-basis: 80%;
}

.foldersList {
  min-height: 100px;
}

.emptyFolder {
  margin-top: 2%;
  text-align: center;
  font-size: 1.2em;
  color: gray;
}

.fileOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>