<template>
  <div class="container_login">
    <div class="form-container">
      <div>
        <h2 class="title">Connexion à votre Compte TaaC</h2>
      </div>

      <form @submit.prevent="signInWithEmail" class="form">
        <div class="form-group">
          <label for="email" class="text-[#7209b7]">Adresse Mail</label>
          <input id="email" name="email" type="email" autocomplete="email" v-model="email" required class="input">
        </div>
        <div class="form-group">
          <label for="password" class="text-[#7209b7]">Mot de Passe</label>
          <input id="password" name="password" type="password" autocomplete="current-password" v-model="password"
            required class="input">
        </div>

        <div v-if="authError" class="error-message">
          {{ authError }}
        </div>

        <div class="form-footer">
          <a href="#" class="forgot-password mb-2">Mot de passe oublié ?</a>
        </div>

        <div>
          <button type="submit" class="btn-submit">Se connecter</button>
        </div>
      </form>

      <div class="register-link">
        <p class="text">
          Pas de compte ?
          <a href="#" class="create-account">Créer un compte</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'; // Importer le store Vuex
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, getDoc, getDocs, query, collection, where } from 'firebase/firestore'; // Pour Firestore

const email = ref('');
const password = ref('');
const authError = ref('');
const router = useRouter();
const store = useStore(); // Utiliser Vuex

// Récupérer l'instance d'authentification
const auth = getAuth();
const db = getFirestore();

const signInWithEmail = async () => {
  try {
    // Connexion avec Firebase Auth
    const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
    const user = userCredential.user;

    // Récupérer les détails de l'utilisateur dans Firestore
    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      // Stocker les informations utilisateur dans Vuex
      const userData = { ...user, ...userSnap.data() };
      store.dispatch('setUser', userData);
      // Rediriger vers la page d'accueil
      router.push('/InterPage');
    } else {
      console.error('Aucune donnée utilisateur trouvée dans Firestore');
      authError.value = 'Erreur de récupération des informations utilisateur';
    }
    //Récupérer les informations sur les companies liées à l'utilisateur
    const q = query(collection(db, "companies"), where("user_id", "==", user.uid));

    const querySnapshot = await getDocs(q);
    var companiesData = []
    querySnapshot.forEach((doc) => {
      companiesData.push(doc.data())
    });
    if (companiesData.length > 0) {
      store.dispatch('setCompanies', companiesData);
    } else {
      console.log("Aucune entreprise trouvée pour cet utilisateur");
    }
  } catch (error) {
    console.error('Erreur de connexion :', error);
    authError.value = 'E-mail ou mot de passe incorrect';
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

.container_login {
  font-family: Righteous;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* La hauteur de la fenêtre visible */
  width: 100vw;
  /* La largeur de la fenêtre visible */
  background-color: #7209b7;
  z-index: 9999;
  position: fixed;
  top: 0;
}

.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  color: #F72485;
  margin-bottom: 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1.5rem;
}

.input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ebf9fd;
  border-radius: 4px;
  background-color: #ebf9fd;
}

.input:focus {
  border-color: #007bff;
  outline: none;
}

.btn-submit {
  width: 100%;
  padding: 0.75rem;
  background-color: #f72585;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #4cc9f0;
}

.forgot-password {
  display: inline-block;
  margin-top: 0.5rem;
  color: #2d00f7;
  text-decoration: none;
  font-size: 0.875rem;
}

.forgot-password:hover {
  text-decoration: underline;
}

.register-link {
  text-align: center;
  margin-top: 2rem;
}

.create-account {
  color: #2d00f7;
  text-decoration: none;
}

.create-account:hover {
  text-decoration: underline;
}

.error-message {
  color: #ff220c;
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.text {
  font-size: 0.875rem;
  color: #555;
}

.sr-only {
  color: #7209b7;
}
</style>
