<template>
  <div class="preview">
    <span style="font-size: 1.5em">Preview</span>
    <br />
    <br /> 
    <div>
        <iframe width="1000" height="500" :src="url"></iframe>
    </div>   

  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import {ref} from "vue";
import { useRoute } from "vue-router";

export default {
    setup(){
        const route = useRoute()
        let url = ref(null);
        const preview = async ()=>{
            let name = route.params.name;
            url.value = `http://localhost:3001/filepreview/`+ name;
        }
        onMounted(preview);
        return{
            preview,
            url
        }
    }
}
</script>

<style>
.preview{
    margin-left: 5%;
}

</style>