<template lang="">
  <div>
    <div class="tree-container">
            <h3 style="text-align:center; font-size:25px"><b>ARBORESCENCE</b></h3>
            <q-btn @click="createFolderStructure" color="primary"  label="Créer l'arborescence dans Zoho" />
            <q-btn @click="addNewFolderInZoho" color="primary"  label="Ajouter un nouveau dossier" />
            <div>
                <q-banner v-if="message2" :color="messageType2 === 'success' ? 'green' : 'red'">
                    {{ message2 }}
                </q-banner>
            </div>

    </div>
    <div>
            <h3 style="text-align:center; font-size:25px"><b>GESTION DU PCG</b></h3>
            <div class="handle-pcg">
                <q-btn label="Remplissage BDD des comptes 6" @click="fillPcg" color="secondary" />
                <q-btn label="Ajouter une nouvelle classification d'achat" @click="addNewAccount" color="secondary" />
            </div>
            <div>
                <q-banner v-if="message" :color="messageType === 'success' ? 'green' : 'red'">
                    {{ message }}
                </q-banner>
            </div>
        </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";

export default {
  setup() {
    const store = useStore();
    const companySelected = computed(() => { return store.state.companySelected });
    const message = ref('');
    const messageType = ref('');
    const message2 = ref('');
    const messageType2 = ref('');
    const fillPcg = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/pcg/${companySelected.value.id}/${companySelected.value.evoliz_id}`;
        await axios.get(url);
        message.value = 'Le plan comptable a été rempli avec succès';
        messageType.value = 'success';
      } catch (error) {
        console.log(error);
        message.value = 'Une erreur est survenue lors du remplissage du plan comptable';
        messageType.value = 'error';
      }
    }

    const addNewAccount = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/pcgSpecial/${companySelected.value.id}/${companySelected.value.evoliz_id}`;
        await axios.post(url);
        message.value = 'Le compte a été ajouté avec succès';
        messageType.value = 'success';
      } catch (error) {
        console.log(error);
        message.value = 'Une erreur est survenue lors de l\'ajout du compte';
        messageType.value = 'error';
      }
    }

    const createFolderStructure = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/create-folder-structure2`;
        let response = await axios.post(url, { "companyId": companySelected.value.id});
        if (response.status === 200) {
          message2.value = 'Arborescence créée avec succès.';
          messageType2.value = 'success';
        }
      } catch (error) {
        console.log(error);
        message2.value = "Erreur lors de la création de l'arborescence.";
        messageType2.value = 'error';
      }
    }

    return {
      fillPcg,
      addNewAccount,
      companySelected,
      createFolderStructure,
      message,
      messageType,
      message2,
      messageType2
    }
  }
}
</script>
<style>
.handle-pcg {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.tree-container{
  text-align: center;
}
</style>