import { createApp } from 'vue';
import App from './App.vue';
import { Quasar } from 'quasar';
import quasarUserOptions from './quasar-user-options';
import router from './router';
import store from './store';
import mitt from 'mitt';
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import './index.css'



// Configuration Firebase
// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDVZH1sGt1WRBjA-IGRzorZMxHbXj-DF9g",
  authDomain: "taac-57a55.firebaseapp.com",
    projectId: "taac-57a55",
  storageBucket: "taac-57a55.appspot.com",
  messagingSenderId: "333067019226",
  appId: "1:333067019226:web:103023a69315eaf45616e3"

};

// Initialiser Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
// Configurer la persistance de session pour qu'elle soit maintenue entre les sous-domaines
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Persistance configurée pour le sous-domaine app.taac.ai');
  })
  .catch((error) => {
    console.error('Erreur lors de la configuration de la persistance :', error);
  });
// Instancier mitt pour l'événementiel
const emitter = mitt();


const app = createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(VueTippy)
  .provide('emitter', emitter);

app.config.globalProperties.emitter = emitter;

app.mount('#app');
export { auth };
