import { createStore } from 'vuex'

export default createStore({
  state: {
    currentFolder: null,
    currentFolderParent: null,
    currentFolderName: null,
    currentRootFolderName: null,
    currentClient: null,
    currentClientInfos: {},
    breadcrumbs: [],
    role: "",
    rights: "",
    companySelected : "",
    user: null, 
    companies: null,
    
  },
  mutations: {
    setCurrentFolder(state, id){
      state.currentFolder = id;
    },
    setCurrentFolderName(state, name){
      state.currentFolderName = name;
    },
    setCurrentParent(state, name){
      state.currentFolderParent = name;
    },
    setCurrentRootFolderName(state, name){
      state.currentRootFolderName = name;
    },
    setCurrentClient(state, id){
      state.currentClient= id;
    },
    setBreadcrumbs(state, element){
      state.breadcrumbs.push(element);
    },
    setRole(state, role){
      state.role = role;
    },
    setRights(state, rights){
      state.rights = rights;
    },
    setCurrentClientInfos(state, infos){
      state.currentClientInfos = infos;
    },
    setCompanySelected(state, company){
      state.companySelected = company;
    },
    setUser(state, user) {
      state.user = user;
    },
    setCompanies(state, companies){
      state.companies = companies;
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setCompanies({commit}, company){
      commit('setCompanies', company)
    }
  },
  getters: {
    isAuthenticated: state => !!state.user,
    currentUser: state => state.user,
  },
})
