export function createFecEntry(codeJournal, libelleJournal, numeroEcriture, dateCompta, numeroCompte, libelleCompte, numeroCompteAux, libelleCompteAux, referencePiece, datePiece, libelleEcriture, montantDebit, montantCredit, lettrage, dateLettrage, dateValidation, montantDevise, idDevise) {
    // Initialiser les montants en devise comme vides
    var original_currency = idDevise;
    var original_amount_ttc = montantDevise;

    // Traiter le montant en devise si la devise n'est pas en euro
    if (original_currency !== 'EUR') {
      if (montantDebit > 0) {
        // Si c'est un débit, le montant en devise est positif
        montantDevise = Math.abs(original_amount_ttc);
      } else if (montantCredit > 0) {
        // Si c'est un crédit, le montant en devise est négatif
        montantDevise = -Math.abs(original_amount_ttc);
      }
    }
    return {
      codeJournal: codeJournal,
      libelleJournal: libelleJournal,
      numeroEcriture: numeroEcriture,
      dateCompta: dateCompta,
      numeroCompte: numeroCompte,
      libelleCompte: libelleCompte,
      numeroCompteAux: numeroCompteAux,
      libelleCompteAux: libelleCompteAux,
      referencePiece: referencePiece,
      datePiece: datePiece,
      libelleEcriture: libelleEcriture,
      montantDebit: montantDebit,
      montantCredit: montantCredit,
      lettrage: lettrage,
      dateLettrage: dateLettrage,
      dateValidation: dateValidation,
      montantDevise: montantDevise,
      idDevise: idDevise
    };
  }