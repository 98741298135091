<template>
  <div>
    <h3 style="text-align:center; font-size:25px"><b>GESTION DES PARTENAIRES</b></h3>
    <div class="partnersMenu">
      <q-btn class="partnersItem" color="indigo-14" text-color="white" label="Fournisseurs" @click="showSupplier"/>
      <q-btn class="partnersItem" color="indigo-14" text-color="white" label="Clients" />
      <q-btn class="partnersItem"  color="indigo-14" text-color="white" label="Partenaires sociaux" />
      <q-btn class="partnersItem"  color="indigo-14" text-color="white" label="Expert comptable" />
      <q-btn class="partnersItem"  color="indigo-14" text-color="white" label="Établissements financiers" />
      <q-btn class="partnersItem"  color="indigo-14" text-color="white" label="Sous traitants" />
      <q-btn class="partnersItem"  color="indigo-14" text-color="white" label="Salariés" />
      <q-btn class="partnersItem"  color="indigo-14" text-color="white" label="Administration fiscale" />
      <q-btn class="partnersItem"  color="indigo-14" text-color="white" label="Associés" />
      <q-btn class="partnersItem"  color="indigo-14" text-color="white" label="Divers" />
    </div>
    <div>
      <br>
      <SuppliersList v-if="supplier"/>
    </div>
  </div>
</template>

<script>
import SuppliersList from "../components/SuppliersList.vue";
import {ref} from "vue";
export default {
  components:{
    SuppliersList
  },
  setup(){
    let supplier = ref(false);
    const showSupplier = () =>{
      supplier.value = true;
    }
    return{
      supplier,
      showSupplier
    }
  }

}
</script>

<style>
.partnersMenu{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.partnersItem{
  margin: 10px;
}

</style>