<template lang="">
    <div class="w-[85%] h-full">
        <router-view>

        </router-view>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>