<template>
    <div>
        <p class="mt-10 text-center text-[#f72585] m-[1%] text-lg font-semibold">FILTRES & TRIS</p>

        <div class="flex flex-wrap items-center w-[98%] gap-2 m-2.5">
            <select v-model="filters.stakeholder_id"
                class="flex-1 max-w-[90%] p-2 bg-white border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5 font-sans" @change="applyFiltersAndSort">
                <option value="">Ensemble des fournisseurs</option>
                <option disabled value="">Sélectionner un fournisseur</option>
                <option v-for="stakeholder in companyStakeholder" :key="stakeholder.id"
                        :value="stakeholder.stakeholder_id">
                        {{ stakeholder.account }} - {{ stakeholder.description }}
                    </option>
                
            </select>
            <input
                class="flex-1 max-w-[90%] p-2 bg-white border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full m-2.5 font-sans"
                v-model="filters.invoice_date"
                type="date"
                @input="applyFiltersAndSort"
            />
            <select
                class="flex-1 max-w-[90%] p-2 bg-white border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full m-2.5 font-sans"
                v-model="sort.column"
                @change="applyFiltersAndSort"
            >
                <option disabled value="">Selectionner un critère de tri</option>
                <option v-for="option in sortOptions" :key="option.value" :value="option.value">
                    {{ option.label }}
                </option>
            </select>
            <select
                class="flex-1 max-w-[90%] p-2 bg-white border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full m-2.5 font-sans"
                v-model="sort.order"
                @change="applyFiltersAndSort"
            >
                <option disabled value="">Selectionner un type de tri</option>
                <option value="asc">Tri croissant</option>
                <option value="desc">Tri décroissant</option>
            </select>
            <button
                class="text-white inline-flex m-auto items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                @click="applyFiltersAndSort"
            >
                Appliquer
            </button>
        </div>
        <br>
        <p class="mt-10 text-center text-[#f72585] m-[1%] text-lg font-semibold">TRAITEMENT DES FACTURES ET AVOIRS DE VOS FOURNISSEURS</p>
        <div class="flex flex-wrap items-center w-[98%] gap-2 m-2.5">
            <select
                class="flex-1 max-w-[46%] p-2 bg-white border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5 font-sans"
                v-model="selected"
                @change="emitInvoiceSelectEvent"
            >
                <option value="">Sélectionner le document</option>
                <option class="text-black" v-for="option in filteredOptions" :key="option.id" :value="option">
                    {{ option.name }}
                </option>
            </select>
            <div>
                <label class="flex items-center text-[#3A0CA3]">
                    <input
                        type="checkbox"
                        v-model="isTemp"
                        @change="handleTemp"
                        class="ml-16 mr-4"
                    />
                    Décocher cette case pour retrouver vos documents déjà traités et leur apporter des modifications !
                </label>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useQuasar } from 'quasar';
import { useStore } from 'vuex';
import axios from 'axios';
export default {
    name: 'FilterSortComponent',
    emits: ['invoiceSelected', 'isTemp'],
    setup(props, ctx) {
        const store = useStore();
        const quasar = useQuasar();
        const isTemp = ref(true);
        const filters = ref({
            stakeholder_id: "",
            invoice_date: '',
        });
        const sort = ref({
            column: '',
            order: 'asc',
        });
        const sortOptions = ref([
            
            { label: 'Nom du fournisseur', value: 'supplier_name' },
            { label: 'Date de facture', value: 'invoice_date' },
            { label: 'Numéro de facture', value: 'invoice_number' },
            { label: 'Total TTC', value: 'total_ttc' },
        ]);
        const selected = ref("");
        const filteredOptions = ref([]);
        const companySelected = computed(() => store.state.companySelected);
        const options = ref([])
        const handleTemp = () => {
            ctx.emit('isTemp', isTemp.value)
        };

        const companyStakeholder = ref();

        const applyFiltersAndSort = () => {
            let filtered = [...options.value];

            if (filters.value.stakeholder_id ) {
                filtered = filtered.filter(option =>
                    option.stakeholder_id === filters.value.stakeholder_id
                );
            }

            if (filters.value.invoice_date) {
                filtered = filtered.filter(option => {
                    // Convertir les deux dates au même format (YYYY-MM-DD)
                    const filterDate = new Date(filters.value.invoice_date);
                    const optionDate = new Date(option.invoice_date);
                    
                    // Comparer les dates en ignorant l'heure
                    return filterDate.toDateString() === optionDate.toDateString();
                });
            }

            filtered.sort((a, b) => {
                let modifier = 1;
                if (sort.value.order === 'desc') modifier = -1;

                const column = sort.value.column;
                const aValue = a[column];
                const bValue = b[column];


                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    const result = aValue.localeCompare(bValue) * modifier;
                    return result;
                } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                    const result = (aValue - bValue) * modifier;
                    return result;
                } else if (new Date(aValue) instanceof Date && !isNaN(new Date(aValue)) && new Date(bValue) instanceof Date && !isNaN(new Date(bValue))) {
                    const result = (new Date(aValue).getTime() - new Date(bValue).getTime()) * modifier;
                    return result;
                } else {
                    if (aValue < bValue) {
                        return -1 * modifier;
                    }
                    if (aValue > bValue) {
                        return 1 * modifier;
                    }
                    return 0;
                }
            });

            filteredOptions.value = filtered;
        };

        // récupérer les données en fonction de la société sélectionnée
        const getData = async () => {
            try {
                if (isTemp.value) {
                    let url = `${process.env.VUE_APP_BASE_URL}/invoicestemp/${companySelected.value.id}`;
                    let response = await axios.get(url);
                    options.value = response.data;
                    applyFiltersAndSort();
                } else {
                    let url = `${process.env.VUE_APP_BASE_URL}/invoices/${companySelected.value.id}`;
                    let response = await axios.get(url);
                    options.value = response.data;
                    applyFiltersAndSort();

                }
            } catch (error) {
                console.error(error);
            }
        };

        const emitInvoiceSelectEvent = () => {
            ctx.emit('invoiceSelected', selected.value);

        }

        const fetchCompanyStakeholder = async () => {
            try {
                var url = `${process.env.VUE_APP_BASE_URL}/company_stakeholders/${companySelected.value.id}`;
                var response = await axios.get(url);
                console.log("reponse companyStakeholder")
                console.log(response.data)
                // trier les données par nom du fournisseur
                response.data.sort((a, b) => a.description.localeCompare(b.description));
                companyStakeholder.value = response.data
            } catch (error) {
                console.log(error);
            }
        }


        watch(() => companySelected.value, async (newVal, oldVal) => {
            if (newVal !== oldVal) {
                await getData();
                await fetchCompanyStakeholder();
            }
        }, { immediate: true });

        watch(() => isTemp.value, async () => {
            try {
                if (isTemp.value) {
                    let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/invoicestemp/${companySelected.value.id}`);
                    options.value = response.data;
                    applyFiltersAndSort();
                } else {
                    let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/invoices/${companySelected.value.id}`);
                    options.value = response.data;
                    applyFiltersAndSort();
                }
            } catch (error) {
                console.log(error);
            }
        })

        return {
            isTemp,
            filters,
            sort,
            selected,
            filteredOptions,
            handleTemp,
            applyFiltersAndSort,
            options,
            sortOptions,
            companySelected,
            emitInvoiceSelectEvent,
            companyStakeholder,
        };
    }
};
</script>

<style scoped>
.rows {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 98%;
    gap: 8px;
    margin: 10px;
}

.q-col {
    flex: 1 1 0;
    max-width: 90%;
}

.q-col-auto {
    flex: 0 1 auto;
}

.filters{
    margin: 1%;
    color: #F72485;
}

.q-field__native, .q-field__prefix, .q-field__suffix, .q-field__input {
    color: red !important;
}
</style>