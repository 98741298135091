<template lang="">
    <div class="h-full w-full">
        <div class="w-full h-full flex">
            <ArchiPilotSideBar />
            <ArchiPilotMain />
        </div>
    </div>
</template>

<script>
import ArchiPilotHeader from '../components/ArchiPilotHeader.vue';
import ArchiPilotSideBar from '../components/ArchiPilotSideBar.vue';
import ArchiPilotMain from '../components/ArchiPilotMain.vue';
export default {
    components: { ArchiPilotHeader, ArchiPilotSideBar, ArchiPilotMain },
    setup() {
        return {

        }
    }

}
</script>

<style scoped>

</style>