<template lang="">
    <div class="h-full w-[15%] bg-white text-[#7209b7]">
        <ul>
            <li class="h-4">
            </li>
            <li>
                <img class="h-auto w-[200px] ml-3" src="../assets/TaaC-ArchiPilot_Large.png"/>
            </li>
            <br>
            <li class="flex hover:bg-[#f72585] hover:text-white h-8 items-center mb-2" @click="navigateToEvoliz">
                <span class="p-2">Gestion Commerciale </span>
            </li>
            <li class="flex hover:bg-[#f72585] hover:text-white h-8 items-center mb-2">
                <router-link to="/archiPilot/ArchiPilotClient" class="p-2">Création Compte Client </router-link>
            </li>
            <li class="flex hover:bg-[#f72585] hover:text-white h-8 items-center mb-2">
                <span class="p-2">Résultat de l'Exercice </span>
            </li>
            <li class="flex hover:bg-[#f72585] hover:text-white h-8 items-center mb-2">
                <span class="p-2">Gestion des Immobilisations </span>
            </li>
            <li class="flex hover:bg-[#f72585] hover:text-white h-8 items-center mb-2">
                <span class="p-2">Gestion des Subventions </span>
            </li>
            <li v-if="user.role == 'super_admin'" class="flex hover:bg-[#f72585] hover:text-white h-8 items-center mb-2">
                <router-link to="/archiPilot/addCompany" class="p-2">Création Nouvelle Entreprise </router-link>
            </li>
            <li v-if="user.role == 'super_admin'" class="flex hover:bg-[#f72585] hover:text-white h-8 items-center mb-2">
                <router-link to="/archiPilot/param" class="p-2">Paramétrage ArchiPilot</router-link>
            </li>
        </ul>
    </div>
</template>
<script>
import {useStore} from "vuex"
export default {
    
    setup(){
        const store = useStore()
        const user = store.state.user
        const navigateToEvoliz = () => {
            window.open("https://www.evoliz.com/login.php", "_blank")
        }
        return{
            navigateToEvoliz,
            user
        }
    }
}
</script>
<style lang="">
    
</style>