<template>
  <div>
    <p>Client : {{ companySelected }}</p>
    <div>
      <q-toggle v-model="isTemp" label="Temporaire" @update:model-value="handleTemp" />
    </div>
    <span>Filtres & Tris</span>

    <div class="row q-col-gutter-md q-mb-md q-col-12 q-col-md-4 q-col-lg-3">
      <q-input class="q-col" filled v-model="filters.supplier_name" label="Nom du fournisseur"
        @input="applyFiltersAndSort" dense />
      <q-input class="q-col" filled v-model="filters.invoice_date" label="Date de la facture" type="date"
        @input="applyFiltersAndSort" dense />
      <q-select class="q-col" filled v-model="sort.column" :options="sortOptions" label="Trier par"
        @input="applyFiltersAndSort" dense />
      <q-select class="q-col" filled v-model="sort.order" :options="['asc', 'desc']" label="Ordre de tri"
        @input="applyFiltersAndSort" dense />
      <q-btn class="q-col-auto q-pa-xs custom-btn" color="primary" label="Appliquer" @click="applyFiltersAndSort"
        dense />
    </div>
    <br>
    <q-select style="width:25%" filled v-model="selected" :options="filteredOptions" label="Sélectionner le fichier"
      option-label="name" emit-value map-options @update:model-value="handleSelect" />
    <q-spinner v-if="loading" color="primary" size="3em" :thickness="2" style="margin:auto" />

    <div class="container" v-if="!loading">
      <div class="preview">
        <div class="sticky-wrapper">
          <embed v-if="pdfUrl" :src="pdfUrl" width="600px" height="600px">
          <br />
        </div>
      </div>

      <div class="form" v-if="invoiceDatas && !loading">
        <div>
          <q-toggle v-model="isImmo" label="Immobilisation" @update:model-value="handleToggle" />
        </div>
        <div class="invoicesDatasForm">
          <h5>Elements généraux</h5>
          <div>
            <span>Facture :</span> <input v-model="invoiceDatas.document_type" />
          </div>
          <div>
            <span>Date :</span> <input v-model="invoiceDatas.invoice_date" />
          </div>
          <div>
            <span>Date d'échéance :</span> <input v-model="invoiceDatas.deadline">
          </div>
          <div>
            <span>Numéro de facture :</span> <input v-model="invoiceDatas.invoice_number" />
          </div>
          <div>
            <span>Nom du fournisseur (Sur facture) :</span> <input v-model="invoiceDatas.supplier_name" />
          </div>
          <div>
            <span>Nom du fournisseur (Siret) :</span> <input v-model="stakeholderInfos.name" />
          </div>
          <div>
            <span>Compte du fournisseur :</span> <input v-model="invoiceDatas.stakeholder_account" />
          </div>
          <div>
            <span>Montant total TTC :</span> <input v-model.number="invoiceDatas.total_ttc">
          </div>
          <div>
            <span>Enregistrement : </span><input v-model="registrationValue['total_ttc']">
          </div>
          <div>
            <span>Option de débit TVA :</span> <input v-model="invoiceDatas.tva_debit_option">
          </div>
          <div>
            <span>Autoliquidation :</span> <input v-model="invoiceDatas.invoice_mention">
          </div>
          <div>
            <span>Moyen de paiement :</span> <input v-model="invoicePaymentMethod">
          </div>
          <div>
            <span>Type de fournisseur :</span> <input v-model="supplierType">
          </div>
          <div>
            <span>Nom de la MarketPlace:</span> <input v-model="marketPlaceName">
          </div>
          <div>
            <span>Pays :</span> <input v-model="country">
          </div>

          <div class="verificationResult">
            <div v-if="verifyEntireInvoice().isBalanced" class="icon success"><q-icon color="green"
                name="check_circle" />
              La facture est équilibrée.
              <ul>
                <li>Total débit : {{ verifyEntireInvoice().totalDebit }}</li>
                <li>Total crédit : {{ verifyEntireInvoice().totalCredit }}</li>
                <li>Montant total de la facture : {{ verifyEntireInvoice().invoiceTotal }}</li>
                <li>Écart constaté : {{ verifyEntireInvoice().discrepancy }}</li>
              </ul>
            </div>

            <div v-else class="icon error">
              <q-icon color="red" name="error" /> Il y a une incohérence dans les écritures comptables.
              <ul>
                <li>Total débit : {{ verifyEntireInvoice().totalDebit }}</li>
                <li>Total crédit : {{ verifyEntireInvoice().totalCredit }}</li>
                <li>Montant total de la facture : {{ verifyEntireInvoice().invoiceTotal }}</li>
                <li>Écart constaté : {{ verifyEntireInvoice().discrepancy }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="productsForm">
          <h5>Frais accessoires</h5>
          <div v-if="extraFees.length == 0">
            <span>Il n'y a aucun frais accessoire trouvé sur cette facture</span>
            <div><q-btn flat color="secondary" icon="add_circle_outline" @click="addExtraFees()" /> </div>
          </div>
          <div class="productsList" v-for="(fees, index) in extraFees" :key="index">
            <div><span>Designation :</span> <input style="width: 100%;" v-model="fees.designation" /></div>
            <div><span>Unité :</span> <input v-model="fees.pack_unity" /></div>
            <div><span>Nb dans pack :</span> <input v-model="fees.pack_number" /></div>
            <div><span>Nom stock :</span> <input v-model="fees.stock_name" /></div>
            <div><span>Quantité :</span> <input v-model.number="fees.quantity" /></div>
            <div><span>Total HT :</span> <input v-model.number="fees.total_amount_ht" /></div>
            <div><span>Taux de TVA :</span> <input v-model.number="fees.tva_rate" /></div>
            <div><span>Total TTC :</span> <input v-model.number="fees.total_amount_ttc" /></div>
            <div>
              <span>Compte:</span>
              <q-select v-model="fees.account" :options="filteredAccounts" use-input input-debounce="0"
                option-label="label" option-value="key" @filter="filterAccounts" label="Choisir un compte" outlined
                dense emit-value />
            </div>
            <div>
              <span style="width: 100%; text-align: center;">
                {{
                  accounts.find(account => account.key && fees.account && account.key.substring(0, 8) ==
                    fees.account.substring(0, 8))?.value
                }}
              </span>
            </div>
            <div>
              <span>Enregistrement :</span> <input v-model="fees.registration">
            </div>

            <!-- Vérification des écritures comptables avec icônes -->
            <div class="verificationResult">
              <span v-if="verifyAccountingEntries(fees)" class="icon success"><q-icon color="green"
                  name="check_circle" /></span>
              <!-- Icône verte pour succès -->
              <span v-else class="icon error"><q-icon color="red" name="error" /></span>
              <!-- Icône rouge pour erreur -->
              <span>{{ verifyAccountingEntries(fees) ? 'Écritures équilibrées' : `Incohérence dans
                les
                écritures` }}</span>
            </div>
            <div><q-btn flat style="color: #FF0080" icon="add_circle_outline" @click="addExtraFees" /><q-btn flat
                style="color: #FF0080" icon="remove_circle_outline" @click="removeExtraFees(fees)" /></div>

            <br>
          </div>
        </div>
        <div v-if="tvaAccounts">
          <h5>TVA</h5>
          <div class="tvaForm">
            <div>
              <div>
                <div><span>TVA 20% :</span> <input v-model="tvaAccounts[0]['tva_20']" /></div>
                <div><span>Compte 1</span> <input v-model="tvaAccounts[0]['account1']" /></div>
                <div><span>Compte 2</span> <input v-model="tvaAccounts[0]['account2']" /></div>
              </div>
              <div>
                <div><span>Enregistrement compte 1</span> <input v-model="tvaAccounts[0]['registration1']" /></div>
                <div><span>Enregistrement compte 2</span> <input v-model="tvaAccounts[0]['registration2']" /></div>
              </div>
            </div>
            <hr>
            <div>
              <div>
                <div><span>TVA 13% : </span><input v-model="tvaAccounts[1]['tva_13']" /></div>
                <div><span>Compte 1</span> <input v-model="tvaAccounts[1]['account1']" /></div>
                <div><span>Compte 2</span> <input v-model="tvaAccounts[1]['account2']" /></div>
              </div>
              <div>
                <div><span>Enregistrement compte 1</span> <input v-model="tvaAccounts[1]['registration1']" /></div>
                <div><span>Enregistrement compte 2</span> <input v-model="tvaAccounts[1]['registration2']" /></div>
              </div>
            </div>
            <hr>
            <div>
              <div>
                <div><span>TVA 10% : </span><input v-model="tvaAccounts[2]['tva_10']" /></div>
                <div><span>Compte 1</span> <input v-model="tvaAccounts[2]['account1']" /></div>
                <div><span>Compte 2</span> <input v-model="tvaAccounts[2]['account2']" /></div>
              </div>
              <div>
                <div>
                  <div><span>Enregistrement compte 1</span> <input v-model="tvaAccounts[2]['registration1']" /></div>
                  <div><span>Enregistrement compte 2</span> <input v-model="tvaAccounts[2]['registration2']" /></div>
                </div>
              </div>
            </div>
            <hr>
            <div>
              <div>
                <div><span>TVA 5.5% : </span><input v-model="tvaAccounts[3]['tva_5_5']" /></div>
                <div><span>Compte 1</span> <input v-model="tvaAccounts[3]['account1']" /></div>
                <div><span>Compte 2</span> <input v-model="tvaAccounts[3]['account2']" /></div>
              </div>
              <div>
                <div>
                  <div><span>Enregistrement compte 1</span> <input v-model="tvaAccounts[4]['registration1']" /></div>
                  <div><span>Enregistrement compte 2</span> <input v-model="tvaAccounts[4]['registration2']" /></div>
                </div>
              </div>

            </div>
            <hr>
            <div>
              <div>
                <div><span>TVA 2.1% : </span><input v-model="tvaAccounts[4]['tva_2_10']" /></div>
                <div><span>Compte 1</span> <input v-model="tvaAccounts[4]['account1']" /></div>
                <div><span>Compte 2</span> <input v-model="tvaAccounts[4]['account2']" /></div>
              </div>
              <div>
                <div>
                  <div><span>Enregistrement compte 1</span> <input v-model="tvaAccounts[5]['registration1']" /></div>
                  <div><span>Enregistrement compte 2</span> <input v-model="tvaAccounts[5]['registration2']" /></div>
                </div>
              </div>
            </div>
            <hr>
            <div>
              <div>
                <div><span>TVA 0% : </span><input v-model="tvaAccounts[5]['tva_0']" /></div>
              </div>
              <div>
                <div>
                  <div><span>Enregistrement compte 1</span> <input v-model="tvaAccounts[6]['registration1']" /></div>
                  <div><span>Enregistrement compte 2</span> <input v-model="tvaAccounts[6]['registration2']" /></div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div class="productsForm">
          <h5>Produits</h5>
          <div v-if="products.length == 0">
            <span>Il n'y a aucun produit trouvé sur cette facture</span>
            <div><q-btn flat color="secondary" icon="add_circle_outline" @click="addProduct()" /> </div>
          </div>
          <div class="productsList" v-for="(product, index) in products" :key="index">
            <div>
              <q-toggle v-model="isOneImmo[index]" label="Immobilisation"
                @update:model-value="handleOneImmo(product, isOneImmo[index])" />
            </div>
            <div><span>Designation :</span> <input style="width: 100%;" v-model="product.designation" /></div>
            <div><span>Unité :</span> <input v-model="product.pack_unity" /></div>
            <div><span>Nb dans pack :</span> <input v-model.number="product.pack_number" /></div>
            <div>
              <label>Nom stock :</label>
              <q-select v-model="product.stock_name" :options="filteredStockNameValues" emit-value map-options filled
                use-input input-debounce="0" @filter="filterFn" @new-value="addNewStock" label="Ajouter un nom de stock"
                dense borderless />
            </div>
            <div><span>Quantité :</span> <input v-model.number="product.quantity" /></div>
            <div><span>Total HT :</span> <input v-model.number="product.total_amount_ht" /></div>
            <div><span>Taux de TVA :</span> <input v-model.number="product.tva_rate" /></div>
            <div><span>Total TTC :</span> <input v-model.number="product.total_amount_ttc" /></div>
            <div>
              <span>Compte:</span>
              <q-select v-model="product.account" :options="filteredAccounts" use-input input-debounce="0"
                option-label="label" option-value="key" @filter="filterAccounts" label="Choisir un compte" outlined
                dense emit-value />

              <q-btn flat color="primary" icon="playlist_add" @click="duplicateAccount(product)" />
            </div>
            <div v-if="isImmo">
              <span>Compte d'immobilisation :</span>
              <q-input v-model="product.account" />
            </div>
            <div>
              <span style="width: 100%; text-align: center;">
                {{
                  accounts.find(account => account.key && product.account && account.key.substring(0, 8) ==
                    product.account.substring(0, 8))?.value
                }}
              </span>
            </div>
            <div><span>Enregistrement :</span> <input v-model="product.registration"></div>

            <!-- Vérification des écritures comptables avec icônes -->
            <div class="verificationResult">
              <span v-if="verifyAccountingEntries(product)" class="icon success"><q-icon color="green"
                  name="check_circle" /></span>
              <!-- Icône verte pour succès -->
              <span v-else class="icon error"><q-icon color="red" name="error" /></span>
              <!-- Icône rouge pour erreur -->
              <span>{{ verifyAccountingEntries(product) ? 'Écritures équilibrées' : `Incohérence
                dans
                les écritures` }}</span>
            </div>
            <div>
              <q-btn flat color="secondary" icon="add_circle_outline" @click="addProduct()" />
              <q-btn flat style="color: #FF0080" icon="remove_circle_outline" @click="removeProduct(product)" />
            </div>
            <hr style="color: black; height: 2px; background-color: black; width: 100%;">
          </div>
        </div>
        <div class="tableContainer" style="display: inline;" :key="updateKey">
          <h6>Écritures d'Enregistrement</h6>
          <table>
            <thead>
              <tr>
                <th>Code Journal</th>
                <th>Libellé Journal</th>
                <th>Numéro Écriture</th>
                <th>Date Comptabilisation</th>
                <th>Numéro Compte</th>
                <th>Libellé Compte</th>
                <th>Numéro Compte Auxiliaire</th>
                <th>Libellé Compte Auxiliaire</th>
                <th>Référence Pièce</th>
                <th>Date Pièce</th>
                <th>Libellé Écriture</th>
                <th>Montant Débit</th>
                <th>Montant Crédit</th>
                <th>Lettrage</th>
                <th>Date Lettrage</th>
                <th>Date Validation</th>
                <th>Montant en Devise</th>
                <th>Identifiant Devise</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in accountingEntries" :key="entry.numeroEcriture">
                <td>{{ entry.codeJournal }}</td>
                <td>{{ entry.libelleJournal }}</td>
                <td>{{ entry.numeroEcriture }}</td>
                <td>{{ entry.dateCompta }}</td>
                <td>{{ entry.numeroCompte }}</td>
                <td>{{ entry.libelleCompte }}</td>
                <td>{{ entry.numeroCompteAux }}</td>
                <td>{{ entry.libelleCompteAux }}</td>
                <td>{{ entry.referencePiece }}</td>
                <td>{{ entry.datePiece }}</td>
                <td>{{ entry.libelleEcriture }}</td>
                <td>{{ entry.montantDebit }}</td>
                <td>{{ entry.montantCredit }}</td>
                <td>{{ entry.lettrage }}</td>
                <td>{{ entry.dateLettrage }}</td>
                <td>{{ entry.dateValidation }}</td>
                <td>{{ entry.montantDevise }}</td>
                <td>{{ entry.idDevise }}</td>
              </tr>
            </tbody>
          </table>

        </div>
        <br>
        <br>
        <div class="tableContainer" style="display: inline;" :key="updateKey">
          <h6>Écritures de paiement</h6>
          <q-btn label="Supprimer l'écriture de paiment" color="red" @click="deleteDecaissementEntries" />
          <br>
          <table>
            <thead>
              <tr>
                <th>Code Journal</th>
                <th>Libellé Journal</th>
                <th>Numéro Écriture</th>
                <th>Date Comptabilisation</th>
                <th>Numéro Compte</th>
                <th>Libellé Compte</th>
                <th>Numéro Compte Auxiliaire</th>
                <th>Libellé Compte Auxiliaire</th>
                <th>Référence Pièce</th>
                <th>Date Pièce</th>
                <th>Libellé Écriture</th>
                <th>Montant Débit</th>
                <th>Montant Crédit</th>
                <th>Lettrage</th>
                <th>Date Lettrage</th>
                <th>Date Validation</th>
                <th>Montant en Devise</th>
                <th>Identifiant Devise</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in decaissementEntries" :key="entry.numeroEcriture">
                <td>{{ entry.codeJournal }}</td>
                <td>{{ entry.libelleJournal }}</td>
                <td>{{ entry.numeroEcriture }}</td>
                <td>{{ entry.dateCompta }}</td>
                <td>{{ entry.numeroCompte }}</td>
                <td>{{ entry.libelleCompte }}</td>
                <td>{{ entry.numeroCompteAux }}</td>
                <td>{{ entry.libelleCompteAux }}</td>
                <td>{{ entry.referencePiece }}</td>
                <td>{{ entry.datePiece }}</td>
                <td>{{ entry.libelleEcriture }}</td>
                <td>{{ entry.montantDebit }}</td>
                <td>{{ entry.montantCredit }}</td>
                <td>{{ entry.lettrage }}</td>
                <td>{{ entry.dateLettrage }}</td>
                <td>{{ entry.dateValidation }}</td>
                <td>{{ entry.montantDevise }}</td>
                <td>{{ entry.idDevise }}</td>
              </tr>
            </tbody>
          </table>

        </div>
        <br>
        <br>
        <q-btn color="secondary" @click="approveData">Valider les données</q-btn>
        <br>
        <q-btn color="amber" @click="sendToEvoliz">Envoyer dans Evoliz</q-btn>
        <div v-if="isLoading">
          <q-spinner-box color="primary" size="2em" />
          <q-tooltip :offset="[0, 8]">QSpinnerBox</q-tooltip>
        </div>
        <p v-if="showMessage" :style="{ color: messageColor }">{{ confirmationMessage }}</p>
        <br>
        <span>Vous avez validé {{ validatedInvoices.length }} factures aujourd'hui </span>
        <q-btn color="black" @click="downloadFecFile">Télécharger le FEC</q-btn>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect, computed, watch } from "vue";
import axios from "axios";
import accountsData from '../assets/pcg_2024_JSON.json';
import accountsData2 from '../assets/pcg_compte_2.json';
import tvaAccountsData from '../assets/pcg_tva_2024_JSON.json';
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const imageRef = ref(null);
    const scale = ref(1);
    let imageUrl = ref();
    let options = ref();
    let selected = ref();
    let invoiceDatas = ref();
    let companies = ref();
    const companySelected = computed(() => store.state.companySelected);
    let pdfUrl = ref();
    let loading = ref(false);
    let products = ref([])
    let country = ref();
    let registrationValue = ref({});
    let stakeholderInfos = ref();
    let extraFees = ref([]);
    let tvaAccounts = ref();
    let updateKey = ref(0);
    let invoicePaymentMethod = ref();
    let supplierType = ref();
    let marketPlaceName = ref();
    let accountingEntries = ref([]);
    let decaissementEntries = ref();
    let validatedInvoices = ref(0);
    let isTemp = ref(true);
    const confirmationMessage = ref('');
    const messageColor = ref('');
    const showMessage = ref(false);
    const isImmo = ref(false);
    const selectedAccount = ref('');
    const otherTable = ref();
    const search = ref('');
    const stocknameValues = ref([]);
    const isLoading = ref(false);
    const filteredOptions = ref([]); // Options après application des filtres et tris
    const isOneImmo = ref([]);
    const filters = ref({
      supplier_name: '',
      invoice_date: '',
    });
    const sort = ref({
      column: 'invoice_date',
      order: 'asc',
    });
    const sortOptions = ref([
      { label: 'Id de la facture', value: 'id' },
      { label: 'Date de la facture', value: 'invoice_date' },
      { label: 'Nom du fournisseur', value: 'supplier_name' },
      { label: 'Numéro de facture', value: 'invoice_number' },
      { label: 'Total TTC', value: 'total_ttc' },
    ]);
    // Transformation des données
    // Transformation des données pour combiner clé et valeur
    const accounts = ref(
      Object.entries(accountsData[0]).map(([key, value]) => ({ label: `${key} - ${value}`, key: key, value: value }))
    );
    const filteredAccounts = computed(() => {
      if (search.value) {
        return accounts.value.filter((account) =>
          Object.values(account).some((value) =>
            value.toLowerCase().includes(search.value.toLowerCase())
          )
        );
      } else {
        return accounts.value;
      }
    });

    const filterAccounts = (val, update) => {
      update(() => {
        search.value = val;
      });
    };
    const getFilesFromAws = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/filenameInAws`;
        let response = await axios.get(url);
        let datas = response.data;

        options.value = datas;
      } catch (error) {
        console.log(error);
      }
    };
    const onImageLoad = (event) => {
      imageRef.value = event.target;
      updateScale();
    };

    const getCompanies = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/companies`;
        let response = await axios.get(url);
        companies.value = response.data;

      } catch (error) {
        console.log(error);
      }
    }

    const getStakeholderAccount = async (stakeholderId) => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/company_stakeholder/${companySelected.value.id}/${stakeholderId}`;
        let response = await axios.get(url);
        invoiceDatas.value.stakeholder_account = response.data[0].account;
      } catch (error) {
        console.log(error);
      }
    }

    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}${month}${day}`;
    }

    const getStakeholderInfos = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/stakeholder/${invoiceDatas.value.stakeholder_id}`;
        let response = await axios.get(url);
        stakeholderInfos.value = response.data[0];
      } catch (error) {
        console.log(error);
      }
    }



    function getAllTvaAccountsAndAmounts() {
      let tvaDetails = [];

      // Parcourir les comptes TVA
      for (let account of tvaAccounts.value) {
        // Vérifier et ajouter chaque montant de TVA non nul avec son compte correspondant
        if (account.tva_20) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_20, tvaRate: "20%" });
        if (account.tva_13) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_13, tvaRate: "13%" });
        if (account.tva_10) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_10, tvaRate: "10%" });
        if (account.tva_5_5) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_5_5, tvaRate: "5.5%" });
        if (account.tva_2_10) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_2_10, tvaRate: "2.1%" });
        if (account.tva_0) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_0, tvaRate: "0%" });
      }

      return tvaDetails;
    }

    function replaceLastTwoCharsWithZero(str) {
      if (str.length >= 2) {
        return str.slice(0, -2) + '00';
      } else {
        // Gérer le cas où la chaîne est trop courte
        return str.padEnd(2, '0');
      }
    }
    const getAccountingEntry2 = async () => {
      let date = new Date();
      const formattedDate = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`;
      try {
        if (stakeholderInfos.value && stakeholderInfos.value.tva_regime !== "franchise") {
          if (invoiceDatas.value.document_type === "facture") {
            products.value.map(product => {
              // Écriture pour le débit du compte de charge (compte 6...)
              product.accountBis = replaceLastTwoCharsWithZero(product.account);
              if (product.registration == "débit") {
                accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis] || accountsData2[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount_ht, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
              } else {
                accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis] || accountsData2[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount_ht, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
              }
            })

            extraFees.value.map(product => {
              // Écriture pour le débit du compte de charge (compte 6...)
              product.accountBis = replaceLastTwoCharsWithZero(product.account);
              if (product.registration == "débit") {
                accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount_ttc, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
              } else {
                accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount_ttc, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
              }
            })

            var tvaAccountAmounts = getAllTvaAccountsAndAmounts();
            tvaAccountAmounts.map(tvaAccountAmount => {
              if (tvaAccountAmount.tvaAmount != 0) {
                // Écriture pour le débit du compte de TVA
                accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                if (tvaAccountAmount.account_2) {
                  accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                }
              }
            })
            // Écriture pour le crédit du compte fournisseur            
            accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.total_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));
          } else if (invoiceDatas.value.document_type === "avoir") {
            // Traiter les autres types de documents si nécessaire
            products.value.map(product => {
              // Écriture pour le débit du compte de charge (compte 6...)
              product.accountBis = replaceLastTwoCharsWithZero(product.account);
              if (product.registration == "débit") {
                accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis] || accountsData2[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount_ht, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
              } else {
                accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis] || accountsData2[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount_ht, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
              }
            })

            extraFees.value.map(product => {
              // Écriture pour le débit du compte de charge (compte 6...)
              product.accountBis = replaceLastTwoCharsWithZero(product.account);
              if (product.registration == "débit") {
                accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount_ttc, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
              } else {
                accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount_ttc, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
              }
            })

            var tvaAccountAmounts = getAllTvaAccountsAndAmounts();
            tvaAccountAmounts.map(tvaAccountAmount => {
              if (tvaAccountAmount.tvaAmount != 0) {
                // Écriture pour le débit du compte de TVA
                accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                if (tvaAccountAmount.account_2) {
                  accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                }
              }
            })
            // Écriture pour le crédit du compte fournisseur            
            accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

          } else if (invoiceDatas.value.document_type === "acompte") {
            console.log("c'est un acompte")
            //Si c'est une facture finale
            let isDepositFinal = await getIsDepositFinal();
            if (isDepositFinal) {
              console.log("L'acompte est final")
              //Ecriture au debit des produits
              products.value.map(product => {
                // Écriture pour le débit du compte de charge (compte 6...)
                product.accountBis = replaceLastTwoCharsWithZero(product.account);
                if (product.registration == "débit") {
                  accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis] || accountsData2[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount_ht, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                } else {
                  accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis] || accountsData2[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount_ht, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                }
              })
              extraFees.value.map(product => {
                // Écriture pour le débit du compte de charge (compte 6...)
                product.accountBis = replaceLastTwoCharsWithZero(product.account);
                if (product.registration == "débit") {
                  accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount_ttc, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                } else {
                  accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount_ttc, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                }
              })
              //Ecriture au débit de la tva de la facture
              var tvaAccountAmounts = getAllTvaAccountsAndAmounts();
              tvaAccountAmounts.map(tvaAccountAmount => {
                if (tvaAccountAmount.tvaAmount != 0) {
                  // Écriture pour le débit du compte de TVA
                  accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                  if (tvaAccountAmount.account_2) {
                    accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                  }
                }
              })
              //Ecriture au credit du compte de décaissement
              accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", invoiceDatas.value, "Fournisseurs débiteurs - Avances et acomptes versés sur commandes", '4091000000', 0, invoiceDatas.value.depositsAmount, stakeholderInfos.value.name + " - " + invoiceDatas.value.invoice_number, invoiceDatas.value.data.oldCurrencyData.original_currency, invoiceDatas.value.data.oldCurrencyData.original_amount));

              //Ecriture au crédit du compte fournisseur
              accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

              //Ecriture de la tva
            }
            //Sinon c'est une facture normale
            else {
              console.log("L'acompte n'est pas final")
              var tvaAccountAmounts = getAllTvaAccountsAndAmounts();

              products.value.map(product => {
                //Détermination quel tva est utilisé
                var tvaEnd;
                tvaAccountAmounts.map(tvaAccount => {
                  if (tvaAccount.tvaRate == "20%") tvaEnd = "20";
                  if (tvaAccount.tvaRate == "13%") tvaEnd = "13";
                  if (tvaAccount.tvaRate == "10%") tvaEnd = "10";
                  if (tvaAccount.tvaRate == "5.5%") tvaEnd = "55";
                  if (tvaAccount.tvaRate == "2.1%") tvaEnd = "21";
                  if (tvaAccount.tvaRate == "0%") tvaEnd = "00";
                })
                // Écriture pour le débit du compte d'attente
                product.accountBis = replaceLastTwoCharsWithZero(product.account);
                if (product.registration == "débit") {
                  accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, "40910000" + tvaEnd, "Fournisseurs débiteurs - Avances et acomptes versés sur commandes", "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, invoiceDatas.value.supplier_name + " - " + invoiceDatas.value.invoice_number, Math.abs(product.total_amount_ht), 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount_ht, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                } else {
                  accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, "40910000" + tvaEnd, "Fournisseurs débiteurs - Avances et acomptes versés sur commandes", "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, invoiceDatas.value.supplier_name + " - " + invoiceDatas.value.invoice_number, 0, Math.abs(product.total_amount_ht), "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ht : invoiceDatas.value.data.oldCurrencyData.original_amount_ht, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
                }
              })

              //Ecriture au débit du compte de TVA
              tvaAccountAmounts.map(tvaAccountAmount => {
                if (tvaAccountAmount.tvaAmount != 0) {
                  // Écriture pour le débit du compte de TVA
                  accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, tvaAccountAmount.account_1, tvaAccountsData[0][tvaAccountAmount.account_1], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_1], Math.abs(tvaAccountAmount.tvaAmount), 0, "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                  if (tvaAccountAmount.account_2) {
                    accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, tvaAccountAmount.account_2, tvaAccountsData[0][tvaAccountAmount.account_2], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, tvaAccountsData[0][tvaAccountAmount.account_2], 0, Math.abs(tvaAccountAmount.tvaAmount), "", "", "", tvaAccountAmount.tvaAmount, invoiceDatas.value.data.oldCurrencyData.original_currency))
                  }
                }
              })
              // Écriture pour le crédit du compte fournisseur            
              accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.total_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));

            }
          }
        } else {
          // Traitement spécifique pour les entreprises en franchise de TVA
          products.value.forEach(product => {
            // Écriture pour le débit du compte de charge (compte 6...) sans TVA
            product.accountBis = replaceLastTwoCharsWithZero(product.account);
            accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, product.total_amount_ht, 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount_ttc, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
          });

          extraFees.value.forEach(product => {
            // Écriture pour le débit du compte de charge (compte 6...) pour les frais supplémentaires sans TVA
            product.accountBis = replaceLastTwoCharsWithZero(product.account);
            accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, product.account, accountsData[0][product.accountBis], "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, product.designation, product.total_amount_ht, 0, "", "", "", product.data ? product.data.oldCurrencyData.original_amount_ttc : invoiceDatas.value.data.oldCurrencyData.original_amount_ttc, product.data ? product.data.oldCurrencyData.original_currency : invoiceDatas.value.data.oldCurrencyData.original_currency));
          });

          // Écriture pour le crédit du compte fournisseur sans TVA
          accountingEntries.value.push(createFecEntry("AC", "ACHATS", "", formattedDate, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency));
        }


        accountingEntries.value = accountingEntry;
      } catch (error) {
        console.log("Erreur lors de la génération des écritures comptables:", error);
      }

    };

    const paymentAccounts = {
      "chèque": "4670002000",
      "virement": "4670005000",
      "carte bancaire": "4670006000",
      "prélèvement": "4670004000",
      "lcr": "4670003000",
      "paypal": "4670007000",
      "carte cadeau": "4670008000",
      "carte avoir": "4670009000",
      "ticket restaurant": "4670010000",
      "chèque vacances": "4670011000",
      "CESU": "4670012000",
      "contre remboursement": "4670013000",
      "autres": "4670019000"
    };
    const getDecaissementEntry = () => {
      var accountingEntries = [];
      decaissementEntries.value = [];
      let date = new Date();
      const formattedDate = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`;
      try {
        if (invoiceDatas.value.document_type === "facture" || invoiceDatas.value.document_type === "acompte") {
          //Si le reglement est en espece
          if (invoicePaymentMethod.value == "espèces") {
            //Ecriture pour le debit du fournisseur
            accountingEntries.push(createFecEntry("CA", "CAISSE", "", invoiceDatas.value.deadline, invoiceDatas.value.stakeholder_account, invoiceDatas.value.supplier_name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, invoiceDatas.value.supplier_name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency))
            //Ecriture pour le crédit du reglement
            accountingEntries.push(createFecEntry("CA", "CAISSE", "", invoiceDatas.value.deadline, "5311000001", invoiceDatas.value.supplier_name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, invoiceDatas.value.supplier_name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency))

          }
          //Sinon il faut déterminer le type de fournisseur : classique ou marketPlace
          else if (supplierType.value == "Classique") {
            const accountNumber = paymentAccounts[invoicePaymentMethod.value.toLowerCase()] || paymentAccounts["autres"];
            const accountName = invoicePaymentMethod.value == "autres" ? "Autres moyens de paiement" : invoicePaymentMethod.value;
            //Ecriture pour le debit du fournisseur
            accountingEntries.push(createFecEntry("DT", "DECAISSEMENTS A VENIR", "", invoiceDatas.value.deadline, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency))
            //Ecriture pour le crédit du reglement
            accountingEntries.push(createFecEntry("DT", "DECAISSEMENTS A VENIR", "", invoiceDatas.value.deadline, accountNumber, accountName, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency))

          } else if (supplierType.value == "MarketPlace") {
            const marketplaceAccount = getAccountNumberForMarketplace(marketPlaceName.value);

            //Ecriture pour le debit du fournisseur
            accountingEntries.push(createFecEntry("DT", "DECAISSEMENTS A VENIR", "", invoiceDatas.value.deadline, invoiceDatas.value.stakeholder_account, stakeholderInfos.value.name, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, stakeholderInfos.value.name, invoiceDatas.value.total_ttc, 0, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency))

            //Ecriture pour le crédit du reglement
            accountingEntries.push(createFecEntry("DT", "DECAISSEMENTS A VENIR", "", invoiceDatas.value.deadline, marketplaceAccount, marketPlaceName.value, "", "", invoiceDatas.value.invoice_number, invoiceDatas.value.invoice_date, marketPlaceName.value, 0, invoiceDatas.value.total_ttc, "", "", "", invoiceDatas.value.total_amount_ttc, invoiceDatas.value.data.oldCurrencyData.original_currency))

          }
          decaissementEntries.value = accountingEntries
        } else if (invoiceDatas.value.document_type === "avoir") {

        }

      } catch (error) {
        console.log("Erreur lors de la génération des décaissements:", error);
      }

    }

    const handleOneImmo = async (product, immo) => {
      try {
        accounts.value = accounts.value.concat(
          Object.entries(accountsData2[0]).map(([key, value]) => ({ label: `${key} - ${value}`, key: key, value: value }))
        );

        // Récupérer le montant HT et le taux de TVA du produit
        let totalAmountHT = parseFloat(product.total_amount_ht.toFixed(2));
        let tvaRate = parseFloat(product.tva_rate.toFixed(2));

        if (isNaN(totalAmountHT) || isNaN(tvaRate)) {
          throw new Error("Invalid total_amount_ht or tva_rate");
        }

        // Calculer le montant de TVA
        let tvaAmount = parseFloat(((totalAmountHT * tvaRate) / 100).toFixed(2));

        if (immo) {
          // Cas immobilisation : ajouter un nouvel objet TVA sur immobilisation
          let tvaImmoAccount = {
            [`tva_immo`]: tvaAmount,
            account9: `44566600${tvaRate}`,
            account10: `44566700${tvaRate}`,
            editable: true
          };
          tvaAccounts.value.push(tvaImmoAccount);
          console.log("TVA sur immobilisation ajoutée", tvaImmoAccount);
        } else {
          // Cas non immobilisation : retirer le montant de TVA sur immobilisation
          let index = tvaAccounts.value.findIndex(account => account[`tva_${tvaRate}`] === tvaAmount && account.account1.startsWith('445666') && account.account2.startsWith('445667'));
          if (index !== -1) {
            tvaAccounts.value.splice(index, 1);
            console.log("TVA sur immobilisation supprimée", tvaRate);
          }
        }

      } catch (error) {
        console.log("Erreur dans handleOneImmo:", error);
      }
    };


    const updateTvaAccounts = async (tvaRate, tvaAmount) => {
      tvaAccounts.value = tvaAccounts.value.map(account => {
        if ((tvaRate === 20 && account.tva_20 !== undefined) ||
          (tvaRate === 13 && account.tva_13 !== undefined) ||
          (tvaRate === 10 && account.tva_10 !== undefined) ||
          (tvaRate === 5.5 && account.tva_5_5 !== undefined) ||
          (tvaRate === 2.1 && account.tva_2_10 !== undefined) ||
          (tvaRate === 0 && account.tva_0 !== undefined)) {

          // Soustraire le montant de TVA sur immobilisation
          if (tvaRate === 20) {
            account.tva_20 = account.tva_20 !== null ? (account.tva_20 - tvaAmount).toFixed(2) : null;
          } else if (tvaRate === 13) {
            account.tva_13 = account.tva_13 !== null ? (account.tva_13 - tvaAmount).toFixed(2) : null;
          } else if (tvaRate === 10) {
            account.tva_10 = account.tva_10 !== null ? (account.tva_10 - tvaAmount).toFixed(2) : null;
          } else if (tvaRate === 5.5) {
            account.tva_5_5 = account.tva_5_5 !== null ? (account.tva_5_5 - tvaAmount).toFixed(2) : null;
          } else if (tvaRate === 2.1) {
            account.tva_2_10 = account.tva_2_10 !== null ? (account.tva_2_10 - tvaAmount).toFixed(2) : null;
          } else if (tvaRate === 0) {
            account.tva_0 = account.tva_0 !== null ? (account.tva_0 - tvaAmount).toFixed(2) : null;
          }
        }
        return account;
      });

      console.log("Updated tvaAccounts:", tvaAccounts.value);
    };



    function getAccountNumberForMarketplace(marketplaceName) {
      const marketplaceAccounts = {
        "Amazon": "4670100001",
        "Ali Express": "4670100002",
        "Airbnb": "4670100003",
        "Alibaba": "4670100004",
        "Allegro": "4670100005",
        "Auchan": "4670100006",
        "Booking": "4670200001",
        "But": "4670200002",
        "BackMarket": "4670200003",
        "BHV": "4670200004",
        "Boulanger": "4670200005",
        "Bricoprive": "4670200006",
        "Bol": "4670200007",
        "C Discount": "4670300001",
        "Conforama": "4670300002",
        "Conrad": "4670300003",
        "Carrefour": "4670300004",
        "Camif": "4670300005",
        "Darty": "4670400001",
        "Delamaison": "4670400002",
        "Ebay": "4670500001",
        "Emag": "4670500003",
        "Eprice": "4670500004",
        "Fnac": "4670600001",
        "Flipkart": "4670600002",
        "Groupon": "4670700001",
        "GoSport": "4670700002",
        "Intermarche": "4670900001",
        "Leroy Merlin": "4671200001",
        "Leclerc": "4671200002",
        "LDLC": "4671200003",
        "ManoMano": "4671300001",
        "MercadoLibre": "4671300002",
        "MaisonDuMonde": "4671300003",
        "MacWay": "4671300004",
        "Oui Sncf": "4671500001",
        "Ozon": "4671500002",
        "Pinduoduo": "4671600001",
        "La Poste": "4671600002",
        "ThePhoneHouse": "4671600003",
        "LaRedoute": "4671800001",
        "RueDuCommerce": "4671800002",
        "Rakuten": "4671800003",
        "Priceminister": "4671800003",
        "Retif": "4671800004",
        "Refurbed": "4671800005",
        "ShowroomPrive": "4671900001",
        "Shopee": "4671900002",
        "Sprinter": "4671900003",
        "Taobao": "4672000001",
        "Tmall": "4672000002",
        "Temu": "4672000003",
        "U balbi": "4672100001",
        "Vinted": "4672200001",
        "Veepee": "4672200001",
        "Wish": "4672300001",
        "Walmart": "4672300002",
        "WildBerries": "4672300003"
      };

      return marketplaceAccounts[marketplaceName] || "Compte inconnu";
    }

    function createFecEntry(codeJournal, libelleJournal, numeroEcriture, dateCompta, numeroCompte, libelleCompte, numeroCompteAux, libelleCompteAux, referencePiece, datePiece, libelleEcriture, montantDebit, montantCredit, lettrage, dateLettrage, dateValidation, montantDevise, idDevise) {
      // Initialiser les montants en devise comme vides
      var original_currency = idDevise;
      var original_amount_ttc = montantDevise;

      // Traiter le montant en devise si la devise n'est pas en euro
      if (original_currency !== 'EUR') {
        if (montantDebit > 0) {
          // Si c'est un débit, le montant en devise est positif
          montantDevise = Math.abs(original_amount_ttc);
        } else if (montantCredit > 0) {
          // Si c'est un crédit, le montant en devise est négatif
          montantDevise = -Math.abs(original_amount_ttc);
        }
      }
      return {
        codeJournal: codeJournal,
        libelleJournal: libelleJournal,
        numeroEcriture: numeroEcriture,
        dateCompta: dateCompta,
        numeroCompte: numeroCompte,
        libelleCompte: libelleCompte,
        numeroCompteAux: numeroCompteAux,
        libelleCompteAux: libelleCompteAux,
        referencePiece: referencePiece,
        datePiece: datePiece,
        libelleEcriture: libelleEcriture,
        montantDebit: montantDebit,
        montantCredit: montantCredit,
        lettrage: lettrage,
        dateLettrage: dateLettrage,
        dateValidation: dateValidation,
        montantDevise: montantDevise,
        idDevise: idDevise
      };
    }


    const updateProductAccount = () => {
      if (isImmo.value) {
        return;
      }
      products.value.forEach(product => {
        if (!product.account) {
          return;
        }

        let newAccountSuffix;
        if (product.tva_rate === 20) {
          newAccountSuffix = '20';
        } else if (product.tva_rate === 13) {
          newAccountSuffix = '13';
        } else if (product.tva_rate === 10) {
          newAccountSuffix = '10';
        } else if (product.tva_rate === 5.5) {
          newAccountSuffix = '55';
        } else if (product.tva_rate === 2.1) {
          newAccountSuffix = '21';
        } else if (product.tva_rate === 0) {
          newAccountSuffix = '00';
        }

        if (newAccountSuffix) {
          product.account = product.account.slice(0, -2) + newAccountSuffix;
        }
      })
    }

    const recalculateAndRefresh = () => {
      updateKey.value++;
    }

    const updateExtraFeesAccount = () => {
      extraFees.value.forEach(fee => {
        if (!fee.account) {
          return;
        }

        let newAccountSuffix;
        if (fee.tva_rate === 20) {
          newAccountSuffix = '20';
        } else if (fee.tva_rate === 13) {
          newAccountSuffix = '13';
        } else if (fee.tva_rate === 10) {
          newAccountSuffix = '10';
        } else if (fee.tva_rate === 5.5) {
          newAccountSuffix = '55';
        } else if (fee.tva_rate === 2.1) {
          newAccountSuffix = '21';
        } else if (fee.tva_rate === 0) {
          newAccountSuffix = '00';
        }

        if (newAccountSuffix) {
          fee.account = fee.account.slice(0, -2) + newAccountSuffix;
        }
      })
    }

    const registration = (key, account) => {
      const accountString = JSON.stringify(account).slice(1, -1);

      if (invoiceDatas.value.document_type === "facture" && accountString.startsWith("401")) {
        return "crédit";
      }

      if (invoiceDatas.value.document_type === "avoir" && accountString.startsWith("401")) {
        return "débit";
      }

      if (invoiceDatas.value.document_type === "facture" && accountString.startsWith("6") && key > 0) {
        return "débit";
      }

      if (invoiceDatas.value.document_type === "facture" && accountString.startsWith("6") && key < 0) {
        return "crédit";
      }

      if (invoiceDatas.value.document_type === "avoir" && accountString.startsWith("6")) {
        return "crédit";
      }

      if (account.startsWith("409") && key > 0) {
        return "débit";
      }

      if (account.startsWith("409") && key < 0) {
        return "crédit";
      }



      if (invoiceDatas.value.document_type === "facture" && accountString.startsWith("4456")) {
        return "débit";
      }

      if (invoiceDatas.value.document_type === "avoir" && accountString.startsWith("4456")) {
        return "crédit";
      }

      if (invoiceDatas.value.document_type === "facture" && (accountString.startsWith("4457") || accountString.startsWith("4452"))) {
        return "crédit";
      }

      if (invoiceDatas.value.document_type === "avoir" && (accountString.startsWith("4457") || accountString.startsWith("4452"))) {
        return "débit";
      }
    };


    const verifyAccountingEntries = (product) => {
      if (!product) {
        console.error('Product is undefined');
        return false;
      }

      const totalAmountHT = Number(product.total_amount_ht) || 0;
      const tvaRate = Number(product.tva_rate) || 0;
      const totalTTC = Number(product.total_amount_ttc) || 0;

      const tvaAmount = (totalAmountHT * (tvaRate / 100));

      if (totalTTC.toFixed(2) == (totalAmountHT + tvaAmount).toFixed(2)) {
        return true;
      } else {
        return false;
      }
    };

    const verifyEntireInvoice = () => {
      let globalTotalDebit = 0;
      let globalTotalCredit = 0;

      accountingEntries.value.forEach(entry => {
        globalTotalDebit += parseFloat(entry.montantDebit);
        globalTotalCredit += parseFloat(entry.montantCredit);
      });

      const totalInvoiceAmount = invoiceDatas.value.total_ttc.toFixed(2);
      const isBalanced = Math.abs(globalTotalDebit.toFixed(2) - globalTotalCredit.toFixed(2)) < Number.EPSILON;

      return {
        isBalanced,
        totalDebit: globalTotalDebit.toFixed(2),
        totalCredit: globalTotalCredit.toFixed(2),
        invoiceTotal: totalInvoiceAmount,
        discrepancy: isBalanced ? 0 : Math.abs(globalTotalDebit - globalTotalCredit).toFixed(2)
      };
    };



    const marketplaces = [
      "Amazon", "Ali Express", "Airbnb", "Alibaba", "Allegro", "Auchan",
      "Booking", "But", "BackMarket", "BHV", "Boulanger", "Bricoprive", "Bol",
      "C Discount", "Conforama", "Conrad", "Carrefour", "Camif", "Darty",
      "Delamaison", "Ebay", "Etsy", "Emag", "Eprice", "Fnac", "Flipkart",
      "Groupon", "GoSport", "Intermarche", "Leroy Merlin", "Leclerc", "LDLC",
      "ManoMano", "MercadoLibre", "MaisonDuMonde", "MacWay", "Oui Sncf", "Ozon",
      "Pinduoduo", "La Poste", "ThePhoneHouse", "La Redoute", "RueDuCommerce",
      "Rakuten", "Retif", "Refurbed", "ShowroomPrive", "Shopee", "Sprinter",
      "Taobao", "Tmall", "Temu", "U balbi", "Vinted", "Veepee", "Wish",
      "Walmart", "WildBerries"
    ];

    const getExtraFees2 = async () => {
      try {
        extraFees.value = [];
        // Récupération de l'id de la facture
        const invoiceId = invoiceDatas.value.id;
        // Récupération du texte océrisé
        let url = `${process.env.VUE_APP_BASE_URL}/otherTable/${invoiceId}`;
        let response = await axios.get(url);
        otherTable.value = response.data[0].other_table;

        // Conversion du texte de la facture en minuscule pour la comparaison
        const fullTextLowerCase = otherTable.value.fullText.toLowerCase();
        // Recherche de la marketplace dans le texte de la facture
        //const marketplaceFound = marketplaces.find(marketplace => fullTextLowerCase.includes(marketplace.toLowerCase()));

        // Envoi de l'ocr à l'IA
        let url2 = `${process.env.VUE_APP_BASE_URL}/extractInvoiceDetails`;
        let postBody = {
          'otherTable': otherTable.value
        };
        let response2 = await axios.post(url2, postBody);
        let fees = response2.data;
        // Vérification des frais supplémentaires
        if (fees.extraFees.billingFees !== null && fees.extraFees.billingFees !== 'null') {
          extraFees.value = [...extraFees.value, ...fees.extraFees.billingFees]
        }
        if (fees.extraFees.shippingFees != null && fees.extraFees.shippingFees !== 'null') {
          extraFees.value = [...extraFees.value, ...fees.extraFees.shippingFees]
        }
        if (fees.extraFees.depositFees != null && fees.extraFees.depositFees != 'null') {
          extraFees.value = [...extraFees.value, ...fees.extraFees.depositFees]
        }
        // Assignation du mode de paiement et du type de fournisseur
        invoicePaymentMethod.value = fees.paymentMethod;
        var marketplaceFound;
        if (Array.isArray(fees.marketplace)) {
          marketplaceFound = fees.marketplace[0];
        } else if (typeof fees.marketplace === 'object' && fees.marketplace !== null) {
          marketplaceFound = fees.marketplace;
        }

        if (marketplaceFound.isMarketPlace) {
          supplierType.value = "MarketPlace";
          marketPlaceName.value = marketplaceFound.marketPlaceName;
        } else {
          supplierType.value = "Classique";
          marketPlaceName.value = null;
        }
        for (const extraFee of extraFees.value) {
          //Si le montant est a 0, on enlève le frais du tableau
          if (extraFee.total_amount_ht == 0) {
            extraFees.value = extraFees.value.filter(fee => fee != extraFee);
          }
          extraFee.registration = registration(extraFee.total_amount_ht, extraFee.account)
        }
        updateExtraFeesAccount();

      } catch (error) {
        console.error('Error in getExtraFees2:', error);
      }
    }




    const getRegistrationValues = async () => {
      registrationValue.value['total_ttc'] = registration(invoiceDatas.value.total_ttc, invoiceDatas.value.stakeholder_account)

    }

    const getProductsV3 = async () => {
      try {
        products.value = [];
        if (isTemp.value) {
          let url = `${process.env.VUE_APP_BASE_URL}/producttemp/` + selected.value.invoice_id;
          let response = await axios.get(url);
          products.value = response.data;
          products.value.map((product, index) => {
            product.accountBis = product.account;
            if (product.account.startsWith('2')) isOneImmo.value[index] = true;
            else isOneImmo.value[index] = false;
          })
        } else {
          let url = `${process.env.VUE_APP_BASE_URL}/product/` + selected.value.id;
          let response = await axios.get(url);
          products.value = response.data;
          products.value.map((product, index) => {
            product.accountBis = product.account;
            if (product.account.startsWith('2')) isOneImmo.value[index] = true;
            else isOneImmo.value[index] = false;
          })
        }


      } catch (error) {
        console.log(error);
      }
    }

    const addRegistrationToProducts = () => {
      products.value.forEach(product => {
        if (stakeholderInfos.value.tva_regime == 'franchise') {
          product.registration = registration(product.total_amount_ttc, product.account)
        } else {
          product.registration = registration(product.total_amount_ht, product.account)
        }
      });
    }

    const addRegistrationToTva = () => {
      tvaAccounts.value.forEach(account => {
        // Vérifier et traiter chaque taux de TVA
        if (account.tva_20) {
          account.registration1 = registration(account.tva_20, account.account1);
          account.registration2 = account.account2 ? registration(account.tva_20, account.account2) : undefined;
        }
        if (account.tva_13) {
          account.registration1 = registration(account.tva_13, account.account1);
          account.registration2 = account.account2 ? registration(account.tva_13, account.account2) : undefined;
        }
        if (account.tva_10) {
          account.registration1 = registration(account.tva_10, account.account1);
          account.registration2 = account.account2 ? registration(account.tva_10, account.account2) : undefined;
        }
        if (account.tva_5_5) {
          account.registration1 = registration(account.tva_5_5, account.account1);
          account.registration2 = account.account2 ? registration(account.tva_5_5, account.account2) : undefined;
        }
        if (account.tva_2_10) {
          account.registration1 = registration(account.tva_2_10, account.account1);
          account.registration2 = account.account2 ? registration(account.tva_2_10, account.account2) : undefined;
        }
        if (account.tva_0) {
          account.registration = registration(account.tva_0, account.account1);
        }
      });
    };

    const handleSelect = async () => {
      try {
        loading.value = true;
        let url = `${process.env.VUE_APP_BASE_URL}/imageFromScaleway/${selected.value.name}`;
        let response = await axios.get(url, { responseType: 'blob' });
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        pdfUrl.value = URL.createObjectURL(pdfBlob);
        await getInvoiceDatasV3();
        await getProductsV3();
        await getStakeholderAccount(invoiceDatas.value.stakeholder_id);
        updateProductAccount();
        await getCountry();
        await getStakeholderInfos();
        getRegistrationValues();
        addRegistrationToProducts();
        if (isTemp.value) {
          await getExtraFees2();
          await getIsDeposit();
        }
        tvaAccounts.value = getTvaAccount(country.value, invoiceDatas.value, products.value);
        addRegistrationToTva();
        getAccountingEntry2();
        getDecaissementEntry();
        await getValidatedInvoices();
        await getStocknameValue();
        //Test si il y a une immo
        products.value.map(product=>{
          if(product.data.isImmo){
            handleOneImmo()
          }
        })
        loading.value = false;
      } catch (error) {
        console.log(error);
      }
    };

    // récupérer les données en fonction de la société sélectionnée
    const getData = async () => {
      try {
        if (isTemp.value) {
          let url = `${process.env.VUE_APP_BASE_URL}/invoicestemp/${companySelected.value.id}`;
          let response = await axios.get(url);
          options.value = response.data;
          applyFiltersAndSort();
        } else {
          let url = `${process.env.VUE_APP_BASE_URL}/invoices/${companySelected.value.id}`;
          let response = await axios.get(url);
          options.value = response.data;
          applyFiltersAndSort();

        }
      } catch (error) {
        console.error(error);
      }
    };


    const companyHandleSelect = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/invoicestemp/${companySelected.value}`;
        let response = await axios.get(url);
        options.value = response.data;
        applyFiltersAndSort();

      } catch (error) {
        console.log(error);
      }
    }

    const getCountry = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/stakeholder/${invoiceDatas.value.stakeholder_id}`;
        let response = await axios.get(url);
        country.value = response.data[0].country;
      } catch (error) {
        console.log(error);
      }
    }

    const getTvaAccount = (country, invoiceDatas, products) => {
      var tvaRate = ["tva_20", "tva_13", "tva_10", "tva_5_5", "tva_2_10", "tva_0"];
      var accounts_1 = ["4456610020", "4456610013", "4456610010", "4456610055", "4456610021", ""];
      var accounts_2 = ["4456620020", "4456620013", "4456620010", "4456620055", "4456620021", ""];
      var accounts_3 = ["4456630020", "4456630013", "4456630010", "4456630055", "4456630021", ""];
      var accounts_4 = ["4457130020", "4457130013", "4457130010", "4457130055", "4457130021", ""];
      var ueCountry = ["Germany", "Austria", "Belgium", "Bulgaria", "Cyprus", "Croatia", "Denmark", "Spain", "Estonia", "Finland", "Greece", "Hungary", "Ireland", "Italy", "Latvia", "Lithuania", "Luxembourg", "Malta", "Netherlands", "Poland", "Portugal", "Czech Republic", "Romania", "Slovakia", "Slovenia", "Sweden"];
      var accounts_5 = ["4456660020", "4456660013", "4456660010", "4456660055", "4456660021", ""];
      var accounts_6 = ["4452000020", "4452000013", "4452000010", "4452000055", "4452000021", ""];
      var domTom = ["Guadeloupe", "Martinique", "Guyane", "Réunion", "Saint-Pierre-et-Miquelon", "Mayotte", "Terres Australes et Antarctiques Françaises", "Wallis et Futuna", "Polynésie Française", "Nouvelle Calédonie"];
      var accounts_7 = ["4456670020", "4456670013", "4456670010", "4456670055", "4456670021", ""];
      var accounts_8 = ["4457170020", "4457170013", "4457170010", "4457170055", "4457170021", ""];



      //Si le pays est la France et que le client tvaDebitOption est à true
      if (country == 'France' && invoiceDatas.tva_debit_option == true) {
        var array = []
        for (var i = 0; i <= tvaRate.length; i++) {
          var obj = {};
          obj[tvaRate[i]] = invoiceDatas[tvaRate[i]];
          obj["account1"] = accounts_1[i];
          array.push(obj);
        }
        return array;
      }
      //Si le pays est la France et que le client tvaDebitOption est à false
      else if (country == 'France' && invoiceDatas.tva_debit_option == false && (invoiceDatas.invoice_mention == false || invoiceDatas.invoice_mention == "false")) {
        var service = false;
        products.map(product => {
          if (product.account.startsWith("604") || product.account.startsWith("605") || product.account.startsWith("61") || product.account.startsWith("62") || product.account.startsWith("651")) {
            service = true;
          }
        })
        if (service) {
          var array = []
          for (var i = 0; i <= tvaRate.length; i++) {
            var obj = {};
            obj[tvaRate[i]] = invoiceDatas[tvaRate[i]];
            obj["account1"] = accounts_2[i];
            array.push(obj);
          }
          return array;
        } else {
          var array = []
          for (var i = 0; i <= tvaRate.length; i++) {
            var obj = {};
            obj[tvaRate[i]] = invoiceDatas[tvaRate[i]];
            obj["account1"] = accounts_1[i];
            array.push(obj);
          }
          return array;
        }
      } else if (country == "France" && invoiceDatas.invoice_mention == true) {
        var ht20 = 0;
        var ht13 = 0;
        var ht10 = 0;
        var ht55 = 0;
        var ht21 = 0;
        products.map(product => {
          if (product.tva_rate == 20) {
            ht20 += product.total_amount_ht;
          } else if (product.tva_rate == 13) {
            ht13 += product.total_amount_ht;
          } else if (product.tva_rate == 10) {
            ht10 += product.total_amount_ht;
          } else if (product.tva_rate == 5.5) {
            ht55 += product.total_amount_ht;
          } else if (product.tva_rate == 2.1) {
            ht21 += product.total_amount_ht;
          }
        })
        var tvaArray = [
          ht20 * 0.2, ht13 * 0.13, ht10 * 0.1, ht55 * 0.055, ht21 * 0.021, 0
        ];
        var array = []
        for (var i = 0; i <= tvaRate.length; i++) {
          var obj = {};
          obj[tvaRate[i]] = tvaArray[i];
          obj["account1"] = accounts_3[i];
          obj["account2"] = accounts_4[i];
          array.push(obj);
        }
        return array;
      }
      //Si le pays n'est pas la France mais en CEE
      else if (ueCountry.includes(country)) {
        var ht20 = 0;
        var ht13 = 0;
        var ht10 = 0;
        var ht55 = 0;
        var ht21 = 0;
        products.map(product => {
          if (product.tva_rate == 20) {
            ht20 += product.total_amount_ht;
          } else if (product.tva_rate == 13) {
            ht13 += product.total_amount_ht;
          } else if (product.tva_rate == 10) {
            ht10 += product.total_amount_ht;
          } else if (product.tva_rate == 5.5) {
            ht55 += product.total_amount_ht;
          } else if (product.tva_rate == 2.1) {
            ht21 += product.total_amount_ht;
          }
        })
        var tvaArray = [
          (ht20 * 0.2).toFixed(2),
          (ht13 * 0.13).toFixed(2),
          (ht10 * 0.1).toFixed(2),
          (ht55 * 0.055).toFixed(2),
          (ht21 * 0.021).toFixed(2),
          0
        ];
        var array = []
        for (var i = 0; i <= tvaRate.length; i++) {
          var obj = {};
          obj[tvaRate[i]] = tvaArray[i];
          obj["account1"] = accounts_5[i];
          obj["account2"] = accounts_6[i];
          array.push(obj);
        }
        console.log(array);
        return array;
      }
      //Si le pays n'est pas la France et pas en CEE ou c'est un domTom
      else if ((country != "France" && !ueCountry.includes(country)) || domTom.includes(country)) {
        var ht20 = 0;
        var ht13 = 0;
        var ht10 = 0;
        var ht55 = 0;
        var ht21 = 0;
        products.map(product => {
          if (product.tva_rate == 20) {
            ht20 += product.total_amount_ht;
          } else if (product.tva_rate == 13) {
            ht13 += product.total_amount_ht;
          } else if (product.tva_rate == 10) {
            ht10 += product.total_amount_ht;
          } else if (product.tva_rate == 5.5) {
            ht55 += product.total_amount_ht;
          } else if (product.tva_rate == 2.1) {
            ht21 += product.total_amount_ht;
          }
        })
        var tvaArray = [
          ht20 * 0.2, ht13 * 0.13, ht10 * 0.1, ht55 * 0.055, ht21 * 0.021, 0
        ];
        var array = []
        for (var i = 0; i <= tvaRate.length; i++) {
          var obj = {};
          obj[tvaRate[i]] = tvaArray[i];
          obj["account1"] = accounts_7[i];
          obj["account2"] = accounts_8[i];
          array.push(obj);
        }
        return array;
      }
    }

    const removeProduct = (product) => {

      var indexToDelete = -1;
      for (var i = 0; i < products.value.length; i++) {
        if (products.value[i].designation === product.designation) {
          indexToDelete = i;
          break;
        }
      }
      if (indexToDelete !== -1) {
        products.value.splice(indexToDelete, 1);
      }
    }

    const duplicateAccount = (product) => {
      const newAccount = product.account;
      products.value.forEach(p => {
        p.account = newAccount;
      });
    }

    const addProduct = () => {
      products.value.push({
        designation: "",
        pack_unity: "",
        pack_number: 0,
        stock_name: "",
        quantity: 1,
        tva_rate: 20,
        account: "60110000020",
        accountBis: "60110000000",
        total_amount_ht: 0,
        total_amount_ttc: 0,
        registration: ""
      })
    }

    async function convertBlobToBase64(blobUrl) {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extraire les données base64
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }


    const approveData = async () => {
      try {
        //Si le document est une facture définitive
        if (!isTemp.value) {
          updateData();
          return;
        }
        //Création du FEC
        var entries = accountingEntries.value;
        entries = entries.flat();
        let fec = entries.map(formatFecEntry).join('\n');
        let fec2 = decaissementEntries.value.map(formatFecEntry).join('\n');
        fec = fec + '\n' + fec2;
        //Ajout des données supplémentaire de la facture
        invoiceDatas.value.supplier_type = supplierType.value;
        invoiceDatas.value.marketplace_name = marketPlaceName.value;
        invoiceDatas.value.payment_method = invoicePaymentMethod.value;
        //Envoi des données à l'API
        var url = `${process.env.VUE_APP_BASE_URL}/invoiceValidation`;
        var response = await axios.post(url, {
          invoiceDatas: invoiceDatas.value,
          productsDatas: products.value,
          tvaAccountsDatas: tvaAccounts.value,
          extraFeesDatas: extraFees.value,
          supplierName: stakeholderInfos.value.name,
        })

        //Envoi du FEC à l'API
        var url2 = `${process.env.VUE_APP_BASE_URL}/linkInvoiceToFec`;
        await axios.post(url2, {
          fec: fec,
          invoiceId: response.data.invoiceId
        })

        //Confirmation de la validation
        confirmationMessage.value = `La facture a bien été validée.`;
        messageColor.value = 'green'; // Vert pour le succès
        showMessage.value = true;
        setTimeout(() => {
          showMessage.value = false;
        }, 5000);

      } catch (error) {
        console.log(error)
        confirmationMessage.value = 'Erreur dans la validation de la facture.';
        messageColor.value = 'red'; // Rouge pour l'erreur
        showMessage.value = true;
        setTimeout(() => {
          showMessage.value = false;
        }, 5000);

      }
    }

    const sendToEvoliz = async () => {
      try {
        isLoading.value = true;
        // Convertir le PDF en base64
        const pdfBase64 = await convertBlobToBase64(pdfUrl.value);
        var url3 = `${process.env.VUE_APP_BASE_URL}/invoiceToEvoliz`;
        var response3 = await axios.post(url3, {
          invoiceDatas: invoiceDatas.value,
          productsDatas: products.value,
          tvaAccountsDatas: tvaAccounts.value,
          extraFeesDatas: extraFees.value,
          supplierName: stakeholderInfos.value.name,
          companySelected: companySelected.value,
          pdfUrl: pdfBase64
        })
        //Confirmation de la validation
        confirmationMessage.value = `La facture a bien été envoyée dans Evoliz.`;
        messageColor.value = 'green'; // Vert pour le succès
        showMessage.value = true;
        isLoading.value = false
        setTimeout(() => {
          showMessage.value = false;
        }, 5000);
      } catch (error) {
        console.log(error);
        confirmationMessage.value = 'Erreur dans l\'envoie dans Evoliz.';
        messageColor.value = 'red'; // Rouge pour l'erreur
        showMessage.value = true;
        isLoading.value = false
        setTimeout(() => {
          showMessage.value = false;
        }, 5000);
      }
    }

    //Mise a jout des données de la facture dévinitive
    const updateData = async () => {
      try {
        //Ajout des données supplémentaire de la facture
        invoiceDatas.value.supplier_type = supplierType.value;
        invoiceDatas.value.marketplace_name = marketPlaceName.value;
        invoiceDatas.value.payment_method = invoicePaymentMethod.value;
        //Modification de la facture
        var url = `${process.env.VUE_APP_BASE_URL}/invoice`;
        var response = await axios.put(url, {
          invoiceDatas: invoiceDatas.value,
          productsDatas: products.value,
          tvaAccountsDatas: tvaAccounts.value,
        })
        //Generation du FEC
        var entries = accountingEntries.value;
        entries = entries.flat();
        let fec = entries.map(formatFecEntry).join('\n');
        let fec2 = decaissementEntries.value.map(formatFecEntry).join('\n');
        fec = fec + '\n' + fec2;
        //Modification du fec
        var url2 = `${process.env.VUE_APP_BASE_URL}/linkInvoiceToFec`;
        var response3 = await axios.put(url2, {
          fec: fec,
          invoiceId: invoiceDatas.value.id
        })
        confirmationMessage.value = 'La facture a bien été modifiée.';


      } catch (error) {
        console.log(error)
        confirmationMessage.value = 'Erreur dans la modification de la facture.';
      }
    }

    const addExtraFees = () => {
      extraFees.value.push({
        designation: "",
        pack_unity: "",
        pack_number: 0,
        stock_name: "",
        quantity: 1,
        tva_rate: 20,
        account: "60110000020",
        accountBis: "60110000000",
        total_amount_ht: 0,
        total_amount_ttc: 0,
        registration: ""
      })
    }

    const removeExtraFees = (extraFee) => {
      var indexToDelete = -1;
      for (var i = 0; i < extraFees.value.length; i++) {
        if (extraFees.value[i].designation === extraFee.designation) {
          indexToDelete = i;
          break;
        }
      }
      if (indexToDelete !== -1) {
        extraFees.value.splice(indexToDelete, 1);
      }
    }

    function formatFecEntry(entry) {
      return [
        entry.codeJournal,
        entry.libelleJournal,
        entry.numeroEcriture,
        entry.dateCompta,
        entry.numeroCompte,
        entry.libelleCompte,
        entry.numeroCompteAux,
        entry.libelleCompteAux,
        entry.referencePiece,
        entry.datePiece,
        entry.libelleEcriture,
        entry.montantDebit,
        entry.montantCredit,
        entry.lettrage,
        entry.dateLettrage,
        entry.dateValidation,
        entry.montantDevise,
        entry.idDevise
      ].join(';');
    }


    function downloadFecFile2() {
      var entries = accountingEntries.value
      entries = entries.flat();
      // Créer le contenu du fichier FEC
      let fileContent = entries.map(formatFecEntry).join('\n');

      // Créer un objet Blob avec le contenu du fichier
      let blob = new Blob([fileContent], { type: 'text/plain' });

      // Créer un lien pour le téléchargement
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "export.fec";

      // Simuler un clic sur le lien pour déclencher le téléchargement
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Nettoyer et supprimer le lien
      document.body.removeChild(downloadLink);
    }

    async function downloadFecFile() {
      try {
        //Récupération des factures validées
        let url = `${process.env.VUE_APP_BASE_URL}/validatedInvoices/${companySelected.value.id}`;
        let response = await axios.get(url);
        let invoices = response.data;
        //Récupération de la dernière session (session la plus élevée)
        let lastInvoices = invoices.filter(invoice => invoice.session_number === Math.max(...invoices.map(invoice => invoice.session_number)));
        //Pour chaque facture de la dernière session, on récupère les fec
        var fec = "";
        for (let invoice of lastInvoices) {
          let url2 = `${process.env.VUE_APP_BASE_URL}/invoiceFec/${invoice.id}`;
          let response2 = await axios.get(url2);
          //Ajout au fec avec un retour à la ligne sauf pour la première facture et pour la dernière
          if (response2.data.length > 0) {
            fec += response2.data[0].fec + "\n";

          }
        }
        //Création du fichier à partir du fec
        // Créer un objet Blob avec le contenu du fichier
        let blob = new Blob([fec], { type: 'text/plain' });

        // Créer un lien pour le téléchargement
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        //Création du nom en fonction de la date
        let date = new Date();
        let name = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
        downloadLink.download = `export_${name}.fec`;

        // Simuler un clic sur le lien pour déclencher le téléchargement
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Nettoyer et supprimer le lien
        document.body.removeChild(downloadLink);

      } catch (error) {
        console.log(error);
      }
    }

    const getIsDeposit = async () => {
      try {
        var url = `${process.env.VUE_APP_BASE_URL}/isDepositInvoice`;
        var response = await axios.post(url, { invoiceText: otherTable.value.fullText });
        if (response.data.isDeposit) {
          invoiceDatas.value.document_type = "acompte";
        }
      } catch (error) {
        console.log(error);
      }
    }

    const getIsDepositFinal = async () => {
      try {
        var url = `${process.env.VUE_APP_BASE_URL}/isDepositFinalInvoice`;
        var response = await axios.post(url, { invoiceText: otherTable.value.fullText });
        invoiceDatas.value["depositsAmount"] = response.data.depositsAmount
        return response.data.isFinalInvoice
      } catch (error) {
        console.log(error);
      }
    }

    const handleToggle = () => {
      // Modifier le compte fournisseur de 401***** à 404*****
      if (isImmo.value) {
        invoiceDatas.value.stakeholder_account = invoiceDatas.value.stakeholder_account.replace("401", "404");
      } else {
        invoiceDatas.value.stakeholder_account = invoiceDatas.value.stakeholder_account.replace("404", "401");
      }

      // Transformer les comptes TVA ABS en TVA sur Immo
      if (isImmo.value) {
        for (let i = 0; i < tvaAccounts.value.length; i++) {
          for (let key in tvaAccounts.value[i]) {
            if (tvaAccounts.value[i][key] && typeof tvaAccounts.value[i][key] === 'string' && tvaAccounts.value[i][key].startsWith('44566')) {
              tvaAccounts.value[i][key] = '44562' + tvaAccounts.value[i][key].substring(5);
            }
          }
        }
      } else {
        for (let i = 0; i < tvaAccounts.value.length; i++) {
          for (let key in tvaAccounts.value[i]) {
            if (tvaAccounts.value[i][key] && typeof tvaAccounts.value[i][key] === 'string' && tvaAccounts.value[i][key].startsWith('44562')) {
              tvaAccounts.value[i][key] = '44566' + tvaAccounts.value[i][key].substring(5);
            }
          }
        }
      }

      // Ajouter les comptes 2 
      if (isImmo.value) {
        accounts.value = accounts.value.concat(
          Object.entries(accountsData2[0]).map(([key, value]) => ({ label: `${key} - ${value}`, key: key, value: value }))
        );
      } else {
        accounts.value = Object.entries(accountsData[0]).map(([key, value]) => ({ label: `${key} - ${value}`, key: key, value: value }));
      }
    }


    const deleteDecaissementEntries = () => {
      decaissementEntries.value = [];
    }


    watch(() => companySelected.value, async () => {
      try {

        if (isTemp.value) {
          let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/invoicestemp/${companySelected.value.id}`);
          options.value = response.data;
          applyFiltersAndSort();
        } else {
          let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/invoices/${companySelected.value.id}`);
          options.value = response.data;
          applyFiltersAndSort();
        }

      } catch (error) {
        console.log(error);
      }
    });

    watch(() => isTemp.value, async () => {
      try {
        if (isTemp.value) {
          let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/invoicestemp/${companySelected.value.id}`);
          options.value = response.data;
          applyFiltersAndSort();
        } else {
          let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/invoices/${companySelected.value.id}`);
          options.value = response.data;
          applyFiltersAndSort();
        }
      } catch (error) {
        console.log(error);
      }
    })

    const getValidatedInvoices = async () => {
      try {
        let date = new Date();
        let formattedDate = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/validatedInvoices/${companySelected.value.id}/${formattedDate}`);
        validatedInvoices.value = response.data;
      } catch (error) {
        console.log(error);
      }
    }

    const createNewAccount = (val) => {
      if (val && !filteredAccounts.value.some(option => option.key === val)) {
        const newOption = { label: val, key: val };
        filteredAccounts.value.push(newOption);
      }
    };

    const getStocknameValue = async () => {
      try {
        var url = `${process.env.VUE_APP_BASE_URL}/stockname/${companySelected.value.id}`;
        var response = await axios.get(url);
        stocknameValues.value = response.data;
      } catch (error) {
        console.log(error)
      }
    }
    const filter = ref('');

    const filteredStockNameValues = computed(() => {
      if (!filter.value) {
        // Supprimer les doublons et trier le tableau initial
        const uniqueSortedStockNames = Array.from(new Set(stocknameValues.value)).sort((a, b) => a.localeCompare(b));
        return uniqueSortedStockNames;
      }

      const needle = filter.value.toLowerCase();
      // Filtrer, supprimer les doublons et trier le tableau
      const filteredAndSortedStockNames = Array.from(new Set(stocknameValues.value
        .filter(stock => stock.toLowerCase().includes(needle))))
        .sort((a, b) => a.localeCompare(b));

      return filteredAndSortedStockNames;
    });

    const addNewStock = (newValue) => {
      if (newValue && !stocknameValues.value.includes(newValue)) {
        stocknameValues.value.push(newValue);
        products.value.stock_name = newValue;
      }
    };

    const filterFn = (val, update) => {
      filter.value = val;
      update(() => {
        const needle = val.toLowerCase();
        const filtered = stocknameValues.value.filter(stock => stock.toLowerCase().includes(needle));
        update(() => {
          filteredStockNameValues.value = filtered;
        });
      });
    };

    const applyFiltersAndSort = () => {
      let filtered = [...options.value];

      if (filters.value.supplier_name) {
        filtered = filtered.filter(option =>
          option.supplier_name.toLowerCase().includes(filters.value.supplier_name.toLowerCase())
        );
      }

      if (filters.value.invoice_date) {
        filtered = filtered.filter(option =>
          option.invoice_date === filters.value.invoice_date
        );
      }

      filtered.sort((a, b) => {
        let modifier = 1;
        if (sort.value.order === 'desc') modifier = -1;
        console.log('a:', a);
        console.log('b:', b);
        console.log('sort column:', sort.value.column.value);
        const column = sort.value.column.value;
        const aValue = a[column];
        const bValue = b[column]

        console.log(`Comparing ${aValue} and ${bValue}`);

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          const result = aValue.localeCompare(bValue) * modifier;
          console.log(`Result of string comparison: ${result}`);
          return result;
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          const result = (aValue - bValue) * modifier;
          console.log(`Result of number comparison: ${result}`);
          return result;
        } else if (new Date(aValue) instanceof Date && !isNaN(new Date(aValue)) && new Date(bValue) instanceof Date && !isNaN(new Date(bValue))) {
          const result = (new Date(aValue).getTime() - new Date(bValue).getTime()) * modifier;
          console.log(`Result of date comparison: ${result}`);
          return result;
        } else {
          if (aValue < bValue) {
            console.log(`aValue < bValue, returning ${-1 * modifier}`);
            return -1 * modifier;
          }
          if (aValue > bValue) {
            console.log(`aValue > bValue, returning ${1 * modifier}`);
            return 1 * modifier;
          }
          console.log(`aValue == bValue, returning 0`);
          return 0;
        }
      });

      console.log('Filtered options:', filtered);
      filteredOptions.value = filtered;
    };



    const start = async () => {
      await getCompanies();
      await getData();
    };
    onMounted(start);
    return {
      options,
      selected,
      imageUrl,
      invoiceDatas,
      imageRef,
      companies,
      companySelected,
      pdfUrl,
      loading,
      products,
      country,
      registrationValue,
      extraFees,
      tvaAccounts,
      updateKey,
      invoicePaymentMethod,
      supplierType,
      marketPlaceName,
      accountingEntries,
      selectedAccount,
      filteredAccounts,
      decaissementEntries,
      validatedInvoices,
      filterAccounts,
      accounts,
      start,
      getFilesFromAws,
      handleSelect,
      onImageLoad,
      companyHandleSelect,
      getCompanies,
      registration,
      removeProduct,
      duplicateAccount,
      approveData,
      addProduct,
      addExtraFees,
      removeExtraFees,
      formatDate,
      verifyAccountingEntries,
      verifyEntireInvoice,
      recalculateAndRefresh,
      getDecaissementEntry,
      downloadFecFile,
      isImmo,
      isOneImmo,
      handleToggle,
      confirmationMessage,
      stakeholderInfos,
      deleteDecaissementEntries,
      isTemp,
      handleTemp,
      createNewAccount,
      showMessage,
      messageColor,
      stocknameValues,
      addNewStock,
      filterFn,
      filteredStockNameValues,
      sendToEvoliz,
      isLoading,
      sortOptions,
      filters,
      sort,
      filteredOptions,
      applyFiltersAndSort,
      handleOneImmo

    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
}

.form {
  width: 50%;
  display: flex;
  flex-direction: column;
}



.tvaForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.tvaForm>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
}

.tvaForm>div>span {
  width: 150px;
  margin-right: 10px;
  text-align: right;
}

.tvaForm>div>input {
  flex-grow: 0.5;
}


.productsForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.productsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.productsList>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
}





.productsList>div>span {
  width: 150px;
  margin-right: 10px;
  text-align: right;
}

.productsList>div>input {
  flex-grow: 0.5;
}

.invoicesDatasForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.invoicesDatasForm>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
}

.invoicesDatasForm>div>span {
  width: 150px;
  margin-right: 10px;
  text-align: right;
}

.invoicesDatasForm>div>input {
  flex-grow: 0.5;
}

.image {
  width: 600px;
  height: 600px;
}

.preview {
  position: relative;
}

.sticky-wrapper {
  position: sticky;
  top: 0;
}

table {
  width: 100%;
  /* Prendre la moitié de l'écran pour le tableau */
  overflow-x: scroll;
  border-collapse: collapse;
  /* Pour un style de tableau plus serré */
}

th,
td {
  border: 1px solid #ddd;
  /* Bordure des cellules */
  padding: 8px;
  /* Espacement intérieur */
  text-align: left;
  /* Alignement du texte */
  max-width: 150px;
  /* Largeur maximale pour les cellules */
  overflow: hidden;
  /* Cacher le débordement de texte */
  white-space: nowrap;
  /* Garder le texte sur une seule ligne */
  text-overflow: ellipsis;
  /* Ajouter des points de suspension si le texte est trop long */
}

/* Pour les titres de section */
h5 {
  margin-top: 20px;
}

.tableContainer {
  width: 100%;
  /* Prendre la moitié de l'écran pour le conteneur */
  overflow-x: scroll;
  /* Permettre le défilement horizontal si nécessaire */
}

.verificationResult {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.icon {
  font-size: 24px;
  margin-right: 10px;
}

.icon.success {
  color: green;
}

.icon.error {
  color: red;
}

.q-mb-md {
  margin-bottom: 16px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 90%;
  gap: 8px;
}

.q-col {
  flex: 1 1 0;
  max-width: 90%;
}

.q-col-auto {
  flex: 0 1 auto;
}

.custom-btn {
  height: 40px;
  /* Ajustez cette valeur pour qu'elle corresponde à la hauteur des inputs */
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>