<template>
  <div class="supplierContainer">
    <ImportSupplier />
    <br>
    <AddSupplier />
    <q-table
      title="Fournisseurs"
      :rows="rows"
      row-key="name"
      :rows-per-page-options = "rowsOptions"
      @row-click="onRowClick"
    />
    <q-dialog v-model="supplierPopup">
      <q-card>
        <q-table
      title="Fournisseur"
      :rows="rows2"
      row-key="name"
      @row-click="onRowClick"
    />

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import ImportSupplier from "../components/ImportSupplier.vue";
import AddSupplier from "../components/AddSupplier.vue";
export default {
   components:{
    ImportSupplier,
    AddSupplier
  },
  setup() {
    const store = useStore();
    let supplierPopup = ref(false);
    let supplier = ref([]);
    const columns = [
      {
        name: "account",
        required: true,
        label: "Compte",
        align: "left",
        field: (row) => row.account,
        format: (val) => `${val}`,
        sortable: true,
      },
      {
        name: "supplier",
        align: "center",
        label: "Fournisseur",
        field: "supplier",
        sortable: true,
      },
    ];
    const rowsOptions = [10,50,100]
    const rows = ref([]);
    const rows2 = ref([]);

    let suppliers = ref([]);
    const getSuppliers = async () => {
      try {
        var companyId = store.state.currentClient;
        let url = `${process.env.VUE_APP_BASE_URL}/suppliers/${companyId}`;
        let response = await axios.get(url);
        
        suppliers.value = response.data;
        suppliers.value.map(supplier=>{
            let obj = {
                Id: supplier.id,
                Compte : supplier.account,
                Fournisseurs : supplier.name,
    
            }
            rows.value.push(obj);
        })
      } catch (error) {
        console.log(error);
      }
    };

    const getSupplier = async (id) =>{
      try {
        rows2.value = []
        let url = `${process.env.VUE_APP_BASE_URL}/supplier/${id}`;
        let response = await axios.get(url);
        supplier.value = response.data;
        supplier.value.map(sup=>{
            let obj = {
                Id: sup.id,
                Compte : sup.account,
                Fournisseur : sup.name,
                Siret: sup.siret,
                Libellé: sup.libelle,
                Adresse: sup.adress,
                Téléphone: sup.phone,
                Mail: sup.mail,
                "Forme juridique": sup.legal_form,
                "Date de création": sup.creation_date,
                Siren: sup.siren,
                "TVA intracommunautaire": sup.tva_number,
                "Numéro RCS": sup.rcs_name,
                "RCS date": sup.rcs_date,
                Capital: sup.capital,
                "Objet social": sup.corporate_object,
                "Code APE": sup.ape_code,
                "Dirigeant": sup.dirigeant,
                "Associé": sup.associate,
                "Commentaire": sup.comment

    
            }
            rows2.value.push(obj);
        })
      } catch (error) {
        console.log(error)
      }
    }

    const onRowClick = (evt, row) =>{
      try {
        console.log(row)
        supplierPopup.value = true;
        getSupplier(row.Id);
      } catch (error) {
        console.log(error)
      }
    }

    const start = ()=>{
        getSuppliers();
    }

    onMounted(start)
    return {
      suppliers,
      columns,
      rows,
      getSuppliers,
      rowsOptions,
      onRowClick,
      supplier,
      getSupplier,
      supplierPopup,
      rows2
    };
  },
};
</script>

<style>
.supplierContainer{
    width: 50%;
}
</style>