<template>
  <div style="display: flex; align-items: center;">
    <!-- Select natif avec Tailwind pour sélectionner l'entreprise -->
    <select v-model="companySelected"
      class="w-72 bg-[#f72585] text-white p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
      @change="companyHandleSelect">
      <!-- Option par défaut -->
      <option disabled value="">Sélectionner l'Entreprise</option>

      <!-- Boucle sur les entreprises et affectation de l'objet complet à la valeur -->
      <option class= "bg-white text-[#f72585]" v-for="company in companies" :key="company.id" :value="company">
        {{ company.corporate_name }}
      </option>
    </select>
    <q-tabs v-model="tab" class="text-indigo-14">
      <!--<q-tab name="contacts" icon="contacts" label="Contacts" @click="moveToPartner" />-->
      <!--<q-tab name="mails" icon="mail" label="Mails" @click="moveToMail" />-->
      <!--<q-tab name="addCompany" icon="add_business" label="Ajouter une entreprise" @click="moveToAdd" />-->
      <!-- <q-tab name="addWorkforce" icon="group" label="WorkForce" @click="moveToWorkforce" />-->
      <!-- <q-tab name="zoho" icon="pets" label="Connexion Zoho" @click="moveToZoho" />-->
      <!-- <q-tab name="GED" icon="group" label="GED" @click="moveToGed" />
      <q-tab name="file" icon="description" label="Fichiers" @click="moveToFile" />
      <q-tab name="preview" icon="support_agent" label="IA" @click="moveToIAPreview" />
      <q-tab name="stock" icon="inventory_2" label="Stock" @click="moveToStock" />
      <q-tab name="evoliz" icon="savings" label="Evoliz" @click="moveToEvoliz" />-->
    </q-tabs>
    <!-- <q-btn dense label="Ouvrir Zoho" color="purple" @click="openZoho" />
    <q-btn dense label="Ouvrir Evoliz" color="purple" @click="openEvoliz" />-->

  </div>
</template>

<script>
import router from "@/router";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";

export default {
  setup() {
    let tab = ref();
    const store = useStore();
    const companies = ref([]);
    const companySelected = ref("");
    const start = () => {
      getCompanies();
    }



    const getCompanies = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/companies`;
        const response = await axios.get(url);
        let allCompanies = response.data;
        let user = store.state.user;
        let result = [];

        if (user.role === "super_admin") {
          companies.value = allCompanies;
          result = allCompanies.map(company => company.name);
        } else {
          let userCompanies = store.state.companies;
          companies.value = allCompanies.filter(company =>
            userCompanies.some(userCompany => String(userCompany.taac_company_id) === String(company.id))
          );
          result = companies.value.map(company => company.name);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des entreprises :", error);
      }
    }


    const companyHandleSelect = () => {
      // companySelected contient désormais l'objet complet de l'entreprise sélectionnée
      store.commit('setCompanySelected', companySelected.value);
      console.log('Entreprise sélectionnée : ');
    };
    const moveToAdd = () => {
      router.push({ path: "/addCompany/" });
    };
    const moveToGed = () => {
      router.push({ path: "/ged/" });
    };
    const moveToPartner = () => {
      router.push({ path: "/partners/" });
    };
    const moveToMail = () => {
      router.push({ path: "/mail/" });
    };
    const moveToIAPreview = () => {
      router.push({ path: "/iapreview2/" });
    };
    const moveToZoho = () => {
      router.push({ path: "/zoho/" });
    };

    const moveToWorkforce = () => {
      router.push({ path: "/workforce/" });
    };

    const moveToFile = () => {
      router.push({ path: "/file/" });
    };

    const moveToEvoliz = () => {
      router.push({ path: "/evoliz/" });
    };

    const moveToStock = () => {
      router.push({ path: "/stock/" });
    }

    const openZoho = () => {
      window.open('https://workdrive.zoho.eu/home/', '_blank');
    };

    const openEvoliz = () => {
      window.open('https://www.evoliz.com', '_blank');
    };

    onMounted(start);

    return {
      companies,
      companySelected,
      moveToAdd,
      moveToGed,
      moveToPartner,
      moveToMail,
      moveToIAPreview,
      moveToZoho,
      moveToWorkforce,
      moveToFile,
      moveToEvoliz,
      companyHandleSelect,
      tab,
      openZoho,
      openEvoliz,
      moveToStock
    };
  },
};
</script>

<style scoped>
/* Pour forcer le texte dans le champ à être blanc */
.custom-select .q-field__native,
.custom-select .q-field__prefix,
.custom-select .q-field__suffix,
.custom-select .q-field__input {
  color: white !important;
  /* Forcer le texte en blanc */
}

/* Pour le label si tu en as un */
.custom-select .q-field__label {
  color: white !important;
}

/* Assurer que le fond est bien bleu */
.custom-select .q-field--filled {
  background-color: #4CC9F0 !important;
}
</style>