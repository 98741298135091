<template>
    <div>
        <div>
            <h3 style="text-align:center; font-size:25px"><b>GESTION DES SALARIÉS</b></h3>
        </div>
        <div class="select-container">
            <q-select filled v-model="companySelected" :options="companies" label="Sélectionner le client" option-value="id"
                option-label="corporate_name" emit-value map-options @update:model-value="companyHandleSelect" />
                <br>
                <q-btn @click="addEmployee" color="primary">Ajouter un salarié</q-btn>

        </div>
        <div class="employees-list">
            <q-list>
                <q-item v-for="employee in employees" :key="employee.id">
                    <q-item-section>
                        <q-item-label>{{ employee.first_name }} {{ employee.last_name }} - {{ employee.account }} - {{ employee.isActive ? "Emploi actif" : "Emploi archivé" }}</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <div class="employee-menu">
                            <q-btn @click="updateEmployee(employee.id)" color="purple">Mettre à jour</q-btn>
                            <q-btn @click="deleteEmployee(employee.id)" color="red">Supprimer</q-btn>
                        </div>
                        
                    </q-item-section>
                </q-item>
            </q-list>
        </div>


        <q-dialog v-model="showAddEmployeeModal">
            <q-card>
                <q-card-section>
                    <h3 v-if="!isUpdate">Ajouter un salarié</h3>
                    <h3 v-else>Modifier un salarié</h3>
                </q-card-section>
                <q-card-section>
                    <q-form @submit="submitAddEmployeeForm">
                        <q-input v-model="newEmployee.firstName" label="Prénom" />
                        <q-input v-model="newEmployee.lastName" label="Nom" />
                        <q-input v-model="newEmployee.account" label="Compte" />
                        <q-select v-model="newEmployee.isActive" label="Emploi actif" :options="[{label: 'Oui', value: true}, {label: 'Non', value: false}]" />
                        <br>
                        <q-btn v-if="!isUpdate" type="submit" label="Ajouter" color="primary"  />
                        <q-btn v-else type="submit" label="Modifier" color="primary"  />

                    </q-form>
                </q-card-section>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'

export default {
    setup() {
        const employees = ref([]);
        const companies = ref([]);
        const companySelected = ref(null);
        const showAddEmployeeModal = ref(false);
        const newEmployee = ref({
            firstName: '',
            lastName: ''
        });
        const isUpdate = ref(false);
        const start = async () => {
            getCompanies();
        }

        const getCompanies = async () => {
            try {
                let url = `${process.env.VUE_APP_BASE_URL}/companies`;
                let response = await axios.get(url);
                companies.value = response.data;

            } catch (error) {
                console.log(error);
            }
        }

        const companyHandleSelect = () => {
            fetchEmployees();
        }

        const fetchEmployees = async () => {
            let url = `${process.env.VUE_APP_BASE_URL}/workforce/${companySelected.value}`;
            const response = await axios.get(url)
            employees.value = response.data
        }

        const addEmployee = () => {
            clearEmployeeForm();
            isUpdate.value = false;
            showAddEmployeeModal.value = true;
        }

        const submitAddEmployeeForm = async () => {
            const newEmployeeData = {
                company_id: companySelected.value,
                first_name: newEmployee.value.firstName,
                last_name: newEmployee.value.lastName,
                account: newEmployee.value.account,
                is_active: newEmployee.value.isActive 
            };
            if(isUpdate.value){
                let url = `${process.env.VUE_APP_BASE_URL}/workforce/${companySelected.value}/${newEmployee.value.id}`;
                await axios.put(url, newEmployeeData)
            } else{
                let url = `${process.env.VUE_APP_BASE_URL}/workforce`;
                await axios.post(url, newEmployeeData)
            }
            fetchEmployees()
            showAddEmployeeModal.value = false;
            clearEmployeeForm();

        }

        const clearEmployeeForm = () =>{
            newEmployee.value.id = '';
            newEmployee.value.firstName = '';
            newEmployee.value.lastName = '';
            newEmployee.value.account = '';
            newEmployee.value.isActive = ''; 
        }


        const updateEmployee = async (id) => {
            isUpdate.value = true;
            let employeeDatas = employees.value.find(employee => employee.id === id);
            newEmployee.value.id = employeeDatas.id;
            newEmployee.value.firstName = employeeDatas.first_name;
            newEmployee.value.lastName = employeeDatas.last_name;
            newEmployee.value.account = employeeDatas.account;
            newEmployee.value.isActive = employeeDatas.isActive ? "Oui" : "Non";
            showAddEmployeeModal.value = true;
        }

        const deleteEmployee = async (id) => {
            if (confirm("Êtes-vous sûr de vouloir supprimer cet employé ?")) {
                let url = `${process.env.VUE_APP_BASE_URL}/workforce/${companySelected.value}/${id}`;
                await axios.delete(url);
                fetchEmployees();
            }
        }

       

        onMounted(start)

        return {
            employees,
            companies,
            companySelected,
            showAddEmployeeModal,
            newEmployee,
            isUpdate,
            addEmployee,
            submitAddEmployeeForm,
            updateEmployee,
            deleteEmployee,
            companyHandleSelect
        }
    }
}
</script>

<style>
.select-container {
    width: 30%;
    padding: 20px;
}

.employee-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.employees-list{
    padding: 20px;
    width: 70%;
    margin: auto;
}
</style>