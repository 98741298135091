<template>
  <div class="fixed inset-0 flex items-center justify-center bg-gray-200 bg-opacity-80">
    <div class="bg-white rounded-lg shadow-lg max-w-2xl w-full max-h-screen overflow-y-auto">
      <div class="flex items-center justify-between p-4 md:p-2 border-b rounded-t">
        <h3 class="text-xl font-semibold text-[#F72585] font-title">Créer un dossier</h3>
      </div>

      <form @submit.prevent="create" class="space-y-4 p-4 text-[#7209B7]">
        <div>
          <label for="folderName" class="block text-sm font-medium">Nom du dossier</label>
          <input id="folderName" v-model="name" type="text" required
            class="bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" />
        </div>

        <div>
          <label class="block text-sm font-medium mb-2">Choisissez le dossier parent</label>
          <div class=" p-2 max-h-60 overflow-y-auto">
            <TreeView :folderList="folderList" :selectedFolderId="selectedParentFolderId"
              @folder-selected="selectFolder" />
          </div>
        </div>

        <p v-if="selectedParentFolderName" class="text-sm font-medium text-indigo-600">
          Dossier parent sélectionné : {{ selectedParentFolderName }}
        </p>

        <div class="flex justify-end space-x-4">
          <button type="button" @click="$emit('close')"
            class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Annuler
          </button>
          <button type="submit"
            class="text-white inline-flex items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Créer
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from "vuex";
import TreeView from './TreeView.vue';

const props = defineProps({
  id: Number,
  folderSelected: String
});

const emit = defineEmits(['close', 'folderAdded']);

const store = useStore();
const name = ref('');
const folderList = ref([]);
const selectedParentFolderId = ref(null);
const selectedParentFolderName = ref('');

const getFolderList = async () => {
  try {
    const url = props.folderSelected;
    const regex = /embed\/([^?]+)/;
    const match = url.match(regex);
    const parentFolderId = match ? match[1] : null;
    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/zohoSubfolders/${parentFolderId}`);
    folderList.value = response.data.map(folder => ({
      id: folder.id,
      name: folder.attributes.name,
      children: [],
      open: false,
      childrenLoaded: false,
      hasChildren: true,
    }));
  } catch (error) {
    console.error('Erreur lors du chargement des dossiers', error);
  }
};

const selectFolder = (folder) => {
  selectedParentFolderId.value = folder.id;
  selectedParentFolderName.value = folder.name;
};

const create = async () => {
  if (!name.value) {
    alert('Veuillez saisir un nom de dossier');
    return;
  }
  try {
    const query = `${process.env.VUE_APP_BASE_URL}/folderInZoho`;
    const body = {
      folderName: name.value,
      parentId: selectedParentFolderId.value
    };
    const response = await axios.post(query, body);
    if (response.status === 201) {
      emit('folderAdded');
      emit('close');
    } else {
      throw new Error('La création du dossier a échoué');
    }
  } catch (error) {
    console.error('Erreur lors de la création du dossier:', error);
    alert('Erreur lors de la création du dossier: ' + error.message);
  }
};

onMounted(() => {
  getFolderList();
  if (props.id !== undefined) {
    selectedParentFolderId.value = props.id;
  }
});
</script>

<style scoped>
.font-title {
  font-family: 'Righteous', cursive;
}
</style>
