<template lang="">
    <div>
        <div class="absolute inset-0 bg-[#EBF9FC] opacity-80"></div>

        <!-- Main modal -->
        <div tabindex="-1" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen">
            <div class="relative p-4 w-full max-w-md max-h-full">
            <!-- Modal content -->
                <div class="relative bg-white rounded-lg shadow">
                <!-- Modal header -->
                    <div class="flex items-center justify-between p-4 md:p-2 border-b rounded-t">
                        <h3 class="text-lg font-medium text-[#F72585]">Ajouter une nouvelle Adresse</h3>
                        <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" @click="$emit('closeModal')">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                <!-- Modal body -->
                    <form class="p-4 md:p-5" @submit.prevent = "createAddress">
                        <div>
                            <div>
                                <label for="clientsList" class="block mb-2 text-base font-medium text-[#7209B7]">Liste des clients</label>
                                <select required v-model="selectedClient" class="font-sans bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" @change="handleClientChange">
                                    <option disabled value="">Selectionner un client</option>
                                    <option v-for="client in clients" :key="client.clientid" :value="client">{{client.name}}</option>
                                </select>
                            </div>
                            <br>

                            <div v-if="selectedClient && selectedClient.type=='Professionnel'">
                            
                                <div>
                                <label for="siret" class="block mb-2 text-sm font-medium text-[#7209B7]">SIRET</label>
                                <input required v-model="siret" type="text" name="siret" class="font-sans bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="SIRET">
                                </div>
                                <br>

                            </div>

                            <div v-if="selectedClient && selectedClient.type=='Particulier'">

                                <div>
                                    <label for="postCode" class="block mb-2 text-sm font-medium text-[#7209B7]">Code postal</label>
                                    <input required v-model="postCode" type="text" name="postCode" class="font-sans bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Code postal">
                                </div>
                                <br>

                                <div>
                                    <label for="town" class="block mb-2 text-sm font-medium text-[#7209B7]">VILLE</label>
                                    <input required v-model="town" type="text" name="town" class="font-sans bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="VILLE">
                                </div>
                                <br>

                                <div>
                                    <label for="address" class="block mb-2 text-sm font-medium text-[#7209B7]">Adresse</label>
                                    <input required v-model="address" type="text" name="address" class="font-sans bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Adresse">
                                </div>
                                <br>

                                <div>
                                    <label for="address2" class="block mb-2 text-sm font-medium text-[#7209B7]">Complément d'adresse</label>
                                    <input v-model="address2" type="text" name="address2" class="font-sans bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5" placeholder="Complément d'adresse">
                                </div>
                                
                                <br>
                                <div>
                                <label for="country" class="block mb-2 text-base font-medium text-[#7209B7]">Pays</label>
                                <select required v-model="country" class="font-sans bg-[#F0FAFE] border border-[#7209B7] text-[#3A0CA3] text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] block w-full p-2.5">
                                    <option disabled value="">Selectionnez un pays</option>
                                    <option v-for="(countryName, countryCode) in countries" :key="countryCode" :value="countryCode">
                                        {{ countryName }}
                                    </option>
                                </select>
                                </div>
                                <br>

                            </div>
                            
                        </div>
                        <div class="text-center w-full">
                        <button type="submit" class="text-white inline-flex items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                            <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>Ajouter l'Adresse
                        </button>
                        </div>

                        <div v-if ="isError">
                            <p class="text-[#ff220c] font-medium mt-4 text-base mb-4">
                                {{message}}
                            </p>
                        </div>
                        <div v-if ="isSuccess">
                            <p class="text-[#31cb00] font-medium mt-4 text-base mb-4">
                                {{message}}
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>   
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import isoCountries from "@/assets/iso_countries.json";
export default {
    setup() {
        var clients = ref([])
        var selectedClient = ref(null)
        var isError = ref(false)
        var isSuccess = ref(false)
        var message = ref("")
        var siret = ref("")
        var civility = ref("")
        var name = ref("")
        var postCode = ref("")
        var town = ref("")
        var address = ref("")
        var address2 = ref("")
        var store = useStore()
        var countries = ref(isoCountries)
        var country = ref("")

        onMounted(() => {
            fetchClients()
        })

        async function fetchClients() {
            try {
                let url = `${process.env.VUE_APP_BASE_URL}/clients/${store.state.companySelected.id}/${store.state.companySelected.evoliz_id}`
                var response = await axios.get(url)
                clients.value = response.data
            } catch (error) {
                console.log(error)
            }
        }

        const handleClientChange = () => {
            console.log("client selectionné")
        }

        const createAddress = async () => {
            try {
                var postData = {
                    clientId: selectedClient.value.clientid,
                    postCode: postCode.value || "",
                    town: town.value || "",
                    address: address.value || "",
                    address2: address2.value || "",
                    clientType: selectedClient.value.type,
                    siret: siret.value || "",
                    iso2: country.value || "",
                    evolizCompanyId: store.state.companySelected.evoliz_id,
                    companyId: store.state.companySelected.id
                }
                let url = `${process.env.VUE_APP_BASE_URL}/address`
                var response = await axios.post(url, postData)
                //Reset le form
                postCode.value = ""
                town.value = ""
                address.value = ""
                address2.value = ""
                country.value = ""
                //Message de succès
                isSuccess.value = true
                message.value = "Adresse ajoutée"
                setTimeout(() => {
                    isSuccess.value = false
                    message.value = ""
                }, 8000)
            } catch (error) {
                console.log(error)
                isError.value = true
                message.value = "Erreur lors de l'ajout de l'adresse"
                setTimeout(() => {
                    isError.value = false
                    message.value = ""
                }, 8000)
            }
        }

        return {
            clients,
            selectedClient,
            siret,
            isError,
            isSuccess,
            message,
            handleClientChange,
            civility,
            name,
            postCode,
            town,
            address,
            address2,
            createAddress,
            countries,
            country
        }
    }
}
</script>
<style scoped lang="">

</style>