<template>
    <br>
    <img class="h-auto w-[200px] ml-4" src="../assets/TaaC-ArchiGED_Large.png"/>
    <div class="w-full h-full p-4 bg-[#EBF9FC] flex flex-col items-center">

        <div class="w-full h-full" v-if="companySelected != '' && companyMainFoldersIframes">
            <div
                class="w-[60%] m-auto border-solid border-4 rounded border-[#7209B7] p-2 bg-[#FFFFFF] text-[#3A0CA3] text-[0.9rem] font-sans mb-16">
                <p class="font-bold mb-2"> <q-icon name="info" /> INFORMATIONS</p>
                <p> Bienvenu dans le module dédié à la Gestion Électronique de vos Documents (GED) ! Cet outil vous
                    permet d'organiser, stocker et gérer tous vos documents numériques de façon sécurisée et structurée.
                    Dans votre espace, l’arborescence de vos dossiers a été configurée par nos soins pour répondre
                    spécifiquement aux besoins de votre organisation.</p>
                <br>
                <p> L'interface à laquelle vous accédez ici offre un mode de prévisualisation de votre GED. Cela
                    signifie que certaines actions disponibles dans la version complète en ligne sont limitées dans cet
                    espace. Pour tirer pleinement parti des fonctionnalités complètes de votre GED, y compris la gestion
                    avancée et l’organisation complète de vos documents, veuillez utiliser l’option "Plus d'Actions".
                    Cette option vous redirigera vers votre GED intégrale, où toutes les fonctionnalités sont
                    disponibles.</p>
            </div>
            <!-- Sélection du dossier -->
            <div class="flex flex-row items-center justify-between">
                <div class="mb-4 w-full max-w-lg text-[#f72585]">
                    <label for="folder-select" class="block font-medium mb-2">Sélectionner un
                        dossier</label>
                    <select v-if="companyMainFoldersIframes" v-model="selectedFolderUrl" id="folder-select"
                        class="w-8/12 border border-[#7209B7] rounded-md p-2 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[#7209B7] focus:border-[#4CC9F0] transition duration-150 ease-in-out">
                        <option value="" disabled selected>Sélectionner un dossier ...</option>
                        <option class="font-sans text-[#3a0ca3]" v-for="folder in companyMainFoldersIframes"
                            :key="folder.folder_name" :value="folder.folder_url">
                            {{ folder.folder_name }}
                        </option>
                    </select>
                </div>
                <!-- Boutons d'actions -->
                <div class="flex space-x-4 mt-4 mb-4">
                    <button @click="openUploadModal"
                        class="text-white inline-flex m-auto items-center bg-[#7209B7] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none  font-normal rounded-lg text-sm px-5 py-2.5 text-center head">
                        Télécharger un Fichier
                    </button>
                    <button @click="openCreateFolderModal"
                        class="text-white inline-flex m-auto items-center bg-[#F7287B] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none  font-normal rounded-lg text-sm px-5 py-2.5 text-center head">
                        Créer un Dossier
                    </button>
                    <a href="https://workdrive.zoho.eu/home" target="_blank"
                        class="text-white inline-flex m-auto items-center bg-[#444054] hover:bg-[#4CC9F0] focus:ring-4 focus:outline-none  font-normal rounded-lg text-sm px-5 py-2.5 text-center head">
                        Plus d'Actions
                    </a>
                </div>
            </div>

            <!-- Affichage de l'iframe si un dossier est sélectionné -->
            <div v-if="selectedFolderUrl" class="w-full h-full">
                <iframe :src="`${iframeSrc}`" scrolling="no" frameborder="0" allowfullscreen="true"
                    class="w-full h-full rounded-lg shadow-lg border-none"></iframe>
            </div>
            <!-- Modals -->
            <UploadFileModal v-if="showUploadModal" :show="showUploadModal" :folderSelected="selectedFolderUrl"
                @close="closeUploadModal" />
            <CreateFolderModal v-if="showCreateFolderModal" :folderSelected="selectedFolderUrl"
                @close="closeCreateFolderModal" />
        </div>
        <div v-else>
            <p class="text-[#ff220c]">Aucune entreprise sélectionnée ou aucun dossier disponible.</p>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import ZohoJson from '@/assets/zoho.json';
import UploadFileModal from '@/components/UploadFileModal.vue';
import CreateFolderModal from '@/components/CreateFolderModal.vue';

export default {
    components: {
        UploadFileModal,
        CreateFolderModal
    },
    setup() {
        const store = useStore();
        const selectedFolderUrl = ref(null);  // Stocke l'URL du dossier sélectionné
        const showUploadModal = ref(false);
        const showCreateFolderModal = ref(false);
        const companyMainFoldersIframes = ref(null);

        // Récupère l'entreprise sélectionnée depuis le store
        const companySelected = computed(() => store.state.companySelected);

        const fetchCompanyMainFoldersIframes = async () => {
            if (companySelected.value) {
                const mainFolders = await getMainFolders();
                console.log(mainFolders);
                if (mainFolders) {
                    const iframes = mainFolders.map(folder => ({
                        folder_name: folder.attributes.name,
                        folder_url: `<iframe src=\"https://workdrive.zohopublic.eu/embed/${folder.id}?toolbar=true&layout=grid&appearance=light&themecolor=green\" scrolling=\"no\" frameborder=\"0\" allowfullscreen=true width=\"800\" height=\"520\" title=\"Code incorporé\" ></iframe>`
                    }));
                    console.log(iframes);
                    companyMainFoldersIframes.value = iframes;
                } else {
                    companyMainFoldersIframes.value = null;
                }
            } else {
                companyMainFoldersIframes.value = null;
            }
        };

        // Génère l'URL de l'iframe (extrait l'URL de l'iframe depuis le folder_url)
        const iframeSrc = computed(() => {
            if (selectedFolderUrl.value) {
                return selectedFolderUrl.value.split('src="')[1].split('"')[0];
            }
            return null;
        });

        const openUploadModal = () => {
            showUploadModal.value = true;
        };

        const closeUploadModal = () => {
            showUploadModal.value = false;
        };

        const openCreateFolderModal = () => {
            showCreateFolderModal.value = true;
        };

        const closeCreateFolderModal = () => {
            showCreateFolderModal.value = false;
        };

        const getMainFolders = async () => {
            try {
                // Récupération du dossier principal de l'entreprise
                const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/companyFolder/${companySelected.value.company_code}`);
                // Récupération des dossiers principaux
                const mainFolders = await axios.get(`${process.env.VUE_APP_BASE_URL}/zohoSubFolders/${response.data.companyFolderId}`);
                return mainFolders.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des dossiers principaux:', error);
            }
        };


        // Surveiller les changements de companySelected
        watch(companySelected, () => {
            fetchCompanyMainFoldersIframes();
        }, { immediate: true });

        return {
            companyMainFoldersIframes,
            companySelected,
            selectedFolderUrl,
            iframeSrc,
            showUploadModal,
            showCreateFolderModal,
            openUploadModal,
            openCreateFolderModal,
            closeUploadModal,
            closeCreateFolderModal
        };
    },
};
</script>
