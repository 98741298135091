<template>
    <ul class="pl-4 font-sans text-gray-900">
      <li v-for="folder in folderList" :key="folder.id" class="relative">
        <div 
          class="cursor-pointer flex items-center justify-between py-2 px-2" 
          :class="{ 'bg-blue-100': folder.id === selectedFolderId }" 
          @click="toggleFolder(folder)">
          
          <!-- Icônes + et - pour indiquer l'ouverture/fermeture du dossier -->
          <div class="flex items-center">
            <span v-if="folder.hasChildren && !folder.loading" class="mr-2">
              {{ folder.open ? '-' : '+' }}
            </span>
            <span class="text-base">{{ folder.name }}</span> <!-- Taille de police ajustée à text-base -->
          </div>
        </div>
  
        <!-- Récursion pour afficher les sous-dossiers avec une animation de transition -->
        <transition name="slide-fade">
          <TreeView 
            v-if="folder.open && folder.children.length > 0" 
            :folderList="folder.children" 
            :selectedFolderId="selectedFolderId" 
            @folder-selected="$emit('folder-selected', $event)" />
        </transition>
  
        <!-- Message de chargement des sous-dossiers -->
        <p v-if="folder.loading" class="text-gray-500 ml-6">Chargement des sous-dossiers...</p>
      </li>
    </ul>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import axios from 'axios';
  
  const props = defineProps({
    folderList: Array,
    selectedFolderId: String, // L'ID du dossier sélectionné
  });
  
  const emit = defineEmits(['folder-selected']);
  
  // Fonction pour ouvrir/fermer un dossier et charger les sous-dossiers
  const toggleFolder = async (folder) => {
    if (folder.open) {
      folder.open = false; // Fermer le dossier
    } else {
      folder.open = true; // Ouvrir le dossier
      
      // Si les sous-dossiers ne sont pas encore chargés, les charger via l'API
      if (!folder.childrenLoaded) {
        folder.loading = true; // Indiquer que le chargement est en cours
        try {
          // Appel API pour obtenir les sous-dossiers du dossier parent
          const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/zohoSubfolders/${folder.id}`);
          
          folder.children = response.data.map(subfolder => ({
            id: subfolder.id,
            name: subfolder.attributes.name,  // Assurez-vous que le nom des sous-dossiers est bien structuré
            children: [],  // Les enfants seront chargés dynamiquement
            open: false,   // Par défaut, le dossier est fermé
            childrenLoaded: false,  // Initialement, les sous-dossiers ne sont pas chargés
            hasChildren: true, // On suppose que le dossier peut contenir des sous-dossiers
          }));
  
          folder.childrenLoaded = true;  // Marquer que les enfants ont été chargés
        } catch (error) {
          console.error('Erreur lors du chargement des sous-dossiers', error);
        } finally {
          folder.loading = false; // Fin du chargement
        }
      }
    }
  
    // Émettre l'événement de sélection de dossier
    emit('folder-selected', folder);
  };
  </script>
  
  <style scoped>
  .bg-blue-100 {
    background-color: #ebf8ff; /* Couleur de surlignement pour le dossier sélectionné */
  }
  
  /* Animation pour l'ouverture des sous-dossiers */
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to /* .leave-active in <2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }
  
  /* Hiérarchie visuelle avec du padding */
  ul {
    list-style-type: none;
    padding-left: 1rem; /* Créer une hiérarchie visuelle avec un padding */
  }
  
  .text-base {
    font-size: 1rem; /* Taille de la police ajustée pour plus de lisibilité */
  }
  
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  </style>
  